import * as React from "react";
import { useEffect, useState } from "react";
import type { GetProp, UploadFile, UploadProps } from 'antd';
import {
  Button,
  Form,
  Input,
  notification,
  Layout,
  Row,
  Select,
  Upload,
  message,
  Modal,
  Card,
  Descriptions,
  Typography,
} from "antd";
import BoardGroup from "src/consts/BoardGroup";
import HardwareYear from "src/consts/HardwareYear";
import { UploadOutlined } from '@ant-design/icons';
import AdminApiService from "src/api/AdminApiService";
import SoftwareLookupDTO from "src/models/SoftwareLookupDTO";
import { useQueryClient } from '@tanstack/react-query';
import { ApiError } from "src/api/ApiResultHandler";
import { useGetAllSoftwareLookupsQuery } from "src/queries/useGetAllSoftwareLookupsQuery";
import dayjs from "dayjs";

export const AddSoftwareLookup = () =>{
  const queryClient = useQueryClient();
  const getAllSoftwareLookupsQuery = useGetAllSoftwareLookupsQuery({enabled: true, refetchOnWindowFocus: false});
  const allSoftwareLookups = getAllSoftwareLookupsQuery.data;
  const [form] = Form.useForm<SoftwareLookupDTO>();

  const [selectedSoftwareLookup, setSelectedSoftwareLookup] = useState<SoftwareLookupDTO>();
  const [addNewSoftwareLookupModal, setAddNewSoftwareLookupModal] = useState<boolean>(false);
  
  useEffect(() => {
    if(getAllSoftwareLookupsQuery.isError){
        let error = getAllSoftwareLookupsQuery.error as ApiError;
        notification.error({
            message: "Could not fetch software lookups",
            description: error?.errorDetails?.detail ?? "Contact support"
        });
    }
  }, [getAllSoftwareLookupsQuery.error]);

  const getBoardGroupString = (which: BoardGroup) => {
    switch(which){
      case BoardGroup.Hub:
        return "HubBoards"
      case BoardGroup.Fans:
        return "FanBoards"
      case BoardGroup.StackBoards:
        return "StackBoards"
      case BoardGroup.SensingBoards:
        return "SensorHubBoards"
      case BoardGroup.RHTBoards:
        return "RHT Boards"
      case BoardGroup.OPIBoards:
        return "OPI Boards"
      default:
        return "N/A"
    }
  }

    const handleSelectSoftwareLookup = (values: string) => {
        let software: SoftwareLookupDTO = allSoftwareLookups.find(b => b.name === values)!
        setSelectedSoftwareLookup(software);
    }
    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

    const onFinish = async (values: SoftwareLookupDTO) => {
      try {
        
        // Extract values from the form
        const submit = values;
        var formData = new FormData();
        values.appVersion = parseInt(`${values.appVersion}`, 16);
        values.customId = parseInt(`${values.customId}`, 16)
      //   for ( let key in values ) {
      //     formData.append(key, values[key]);
      // }

        const blob = new Blob([JSON.stringify(values)], { type: 'application/json' });

        console.log("fileList content", fileList);
        //debugger;
        formData.append("file", fileList[0] as FileType, "file");
        formData.append("softwareLookup", blob, "softwareLookup");
        // Call the uploadSoftwareLookup method with the file and softwareLookup objects
        const result = await AdminApiService.createSoftwareLookup(formData);

        // If successful, reset the form
        form.resetFields();
        setAddNewSoftwareLookupModal(false); // Close the modal or perform any other action
        message.success('File uploaded successfully');

        queryClient.invalidateQueries({queryKey: ["getAllSoftwareLookupsQuery"]})
        setFileList([]);
      } catch (err) {
        console.log("error procssing software lookup upload", err);
        notification.error({
          message: err?.errorDetails?.detail ?? "Validation error. Check all your inputs",
          description: err?.detail,
        });
      }
    };

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    
    const props: UploadProps = {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList([file]);
        const nameWithoutStem = file.name.substring(0, file.name.lastIndexOf('.'));

        form.setFieldValue("name", nameWithoutStem);
        form.setFieldValue("description", "New Version Format");
        const parts = nameWithoutStem.split("0x");

        const hardwreYearString = parts[1].slice(2, 4);
        let hardwareYear = undefined;
        if (hardwreYearString === "00") {
          hardwareYear = 2021
        }
        if (hardwreYearString === "01") {
          hardwareYear = 2022
        }
        if (hardwreYearString === "02") {
          hardwareYear = 2023
        }
        form.setFieldValue("hardwareYear", hardwareYear);


        const boardGrouprString = parts[1].slice(0, 2);
        let boardGroup = undefined;
        if (boardGrouprString === "01") {
          boardGroup = BoardGroup.Hub;
        }
        if (boardGrouprString === "02") {
          boardGroup = BoardGroup.Fans;
        }
        if (boardGrouprString === "03") {
          boardGroup = BoardGroup.StackBoards;
        }
        if (boardGrouprString === "04") {
          boardGroup = BoardGroup.SensingBoards;
        }
        if (boardGrouprString === "05") {
          boardGroup = BoardGroup.RHTBoards;
        }
        if (boardGrouprString === "06") {
          boardGroup = BoardGroup.OPIBoards;
        }
        form.setFieldValue("boardGroupId", boardGroup);
        form.setFieldValue("appVersion", parts[2].slice(0,4));
        form.setFieldValue("customId", parts[3].slice(0,4));
  
        return false;
      },
      fileList,
    };

    const hardwareyearOptions = Object.values(HardwareYear).flatMap((year => {
      if (year === 0 || typeof year != 'number') {
        return []
      }
      return [{label: year, value: year}];
    }));

    const integerToHexStr = (someInt: number) => {
      const hexString = someInt.toString(16).toUpperCase();
      return `0x${hexString}`;
    }

    return (
        <Layout.Content className="addSoftwareLookup" style={{width:"80%"}}>
            <Row>
                <legend>
                <b>Add Software Lookup</b> -
                <i>
                    <small>
                      Enter a new software lookup for PCB lookups to pull from
                    </small>
                </i>
                </legend>
            </Row>
            <Row style={{paddingTop:"10px", paddingBottom:"10px" }}>
                <Select
                // style={{ width: "64ch", overflow: "auto", }}
                placeholder="Select software lookup"
                showSearch
                optionFilterProp="label"
                options={
                  allSoftwareLookups.map((soft) => ({
                    label: soft.name,
                    value: soft.name
                    }))}
                onSelect={handleSelectSoftwareLookup}
                />
            </Row>

            { selectedSoftwareLookup && 
              <Card title={selectedSoftwareLookup?.name}>
                <Descriptions
                bordered={true}
                // column={{ xxl: 2, xl: 1, lg: 1, md: 2, sm: 1, xs: 1 }}
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                size="small"
                >
                  <Descriptions.Item label="Date Entered">
                    <Typography.Text>{dayjs(selectedSoftwareLookup.dateEntered).format("YYYY-MM-DD HH:mm:ss Z")}</Typography.Text>
                  </Descriptions.Item>

                  <Descriptions.Item label="Board Group">
                    <Typography.Text>{getBoardGroupString(selectedSoftwareLookup.boardGroupId)}</Typography.Text>
                  </Descriptions.Item>

                  <Descriptions.Item label="Hardware Year">
                    <Typography.Text>{selectedSoftwareLookup.hardwareYear}</Typography.Text>
                  </Descriptions.Item>

                  <Descriptions.Item label="App Version">
                    <Typography.Text>{integerToHexStr(selectedSoftwareLookup.appVersion)}</Typography.Text>
                  </Descriptions.Item>

                  <Descriptions.Item label="Custom App ID">
                    <Typography.Text>{integerToHexStr(selectedSoftwareLookup.customId)}</Typography.Text>
                  </Descriptions.Item>

                  <Descriptions.Item label="Description">
                    <Typography.Text>{selectedSoftwareLookup.description}</Typography.Text>
                  </Descriptions.Item>

                </Descriptions>
              </Card>
            }

            <Row style={{paddingTop:"10px"}}>
              <Button onClick={() => setAddNewSoftwareLookupModal(true)}>Add new software lookup</Button>
            </Row>

            <Modal
            open={addNewSoftwareLookupModal}
            centered
            okButtonProps={{style: {display: 'none'}}}
            onCancel={() => setAddNewSoftwareLookupModal(false)}
            // onOk={() => setAddNewSoftwareLookupModal(false)}
            width={"70%"}
            title={"Add New Software Lookup"}
            >
              <Form
                scrollToFirstError={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >

                <Form.Item
                name="name"
                label="Name"
                key="name"
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                  label="Board Group"
                  name={"boardGroupId"}
                  key={"boardGroupId"}
                  rules={[{ required: true, message: "Enter the board group ID" }]}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Select options={[
                    { label: 'Hub', value: BoardGroup.Hub },
                    { label: 'Fans', value: BoardGroup.Fans },
                    { label: 'StackBoards', value: BoardGroup.StackBoards },
                    { label: 'SensingBoards', value: BoardGroup.SensingBoards },
                    { label: 'RHTBoards', value:BoardGroup.RHTBoards },
                    { label: 'OPIBoards', value: BoardGroup.OPIBoards }
                  ]}
                  showSearch
                  optionFilterProp="label"
                  />
                </Form.Item>

                <Form.Item
                  label="Hardware Year"
                  name={"hardwareYear"}
                  rules={[{ required: true, message: "Enter the Hardware year" }]}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Select options={hardwareyearOptions}
                  showSearch
                  optionFilterProp="label"
                  /> 
                </Form.Item>

                <Form.Item name="appVersion" label="App Version" extra="Enter in hex" rules={[{required: true, pattern: new RegExp("^[0-9a-fA-F]{1,4}$")}]}>
                  <Input />
                </Form.Item>

                <Form.Item name="customId" label="CustomId" extra="Enter in hex" rules={[{required: true, pattern: new RegExp("^[0-9a-fA-F]{1,4}$")}]}>
                  <Input />
                </Form.Item>

                <Form.Item
                  name="file"
                  label="Upload File"
                  rules={[{ required: true, message: 'Please upload CYACD file' }]}
                >
                  <Upload
                   accept=".txt, .csv, .cyacd"
                   maxCount={1}
                   showUploadList={true}
                   {...props}
                  //  beforeUpload={file => {
                  //      const reader = new FileReader();
              
                  //      reader.readAsText(file);
              
                  //  }}
                   >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true, message: 'Please enter a description' }]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item>
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>

        </Layout.Content>
    );
}