import * as React from 'react';
import SettingsTabs from './SettingsTabs';
import { RedirectAs404 } from 'src/utils/RouteErrors';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from '../shared/GlobalBreadcrumb';
import Routes from 'src/consts/Routes';

const Settings = () => (
    <>
        <BreadcrumbsItem name="settings" to={Routes.generate(Routes.SETTINGS)} iconName="document">Settings</BreadcrumbsItem>
        <Switch>
            <Route path={Routes.SETTINGS + '/1'} render={(props) => <SettingsTabs tab={1}/>} />
            <Route path={Routes.SETTINGS + '/2'} render={(props) => <SettingsTabs tab={2}/>} />
            <Route path="/settings" component={SettingsTabs} />

            {/* This needs to be the last item */}
            <Route component={RedirectAs404} />
        </Switch>
    </>
);

export default Settings;
