import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Divider, Skeleton, Modal, Button, message, notification, Card, Space, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import BinApiService from 'src/api/BinApiService';
import CustomRoutineNamesDTO from 'src/models/CustomRoutineNamesDTO';
import { binDBKeys } from '../binOverview/BinCommander';
import { useQuery, QueryKey } from '@tanstack/react-query';
import { queryClient } from 'src';
import { th } from '@faker-js/faker';
import { UploadRoutine } from './UploadFile';

interface RoutineListProps {
  binId: number;
  url: string;
}

export const RoutineList = (props: RoutineListProps) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [stopRoutineModal, setStopRoutineModal] = useState<boolean>(false);

  const showModal = (item: string) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };
  
  const handleCloseStopRoutineModal = () => {
    setStopRoutineModal(false);
  }

  const showStopRoutineModal = () =>{
    setStopRoutineModal(true);
  }
  
  const onYes = async (item: string) => {

    try{
      //todo logic to send request to run custom routine
      await BinApiService.runCustomRoutineByName(props.binId, item);
      queryClient.invalidateQueries([...binDBKeys.bin(props.binId), "customRoutineList" ]);
      handleCloseModal();
    }catch(err){
      handleCloseModal();
      console.error("custom routine failure:", err);
      notification.error(
        {
            message: err?.title,
            description: err?.description
        }
      );
      throw err;
    }
  }

  const onNo = () => {
    handleCloseModal();
  }

  const onDeleteRoutine = async(item:string) => {
    try{
      await BinApiService.deleteCustomRoutine(props.binId, item);
      queryClient.invalidateQueries([...binDBKeys.bin(props.binId), "customRoutineList" ]);
      handleCloseModal();
    }catch(err){
      handleCloseModal();
      console.error("custom routine failure:", err);
      notification.error(
        {
            message: err?.title,
            description: err?.detail ?? err?.message,
        });
      throw err;
      // throw err;
    }
  }

  const onStopRoutine = async() => {
    try{
      await BinApiService.stopCustomRoutine(props.binId);
      queryClient.invalidateQueries([...binDBKeys.bin(props.binId), "customRoutineList" ]);
      handleCloseStopRoutineModal();
    }catch(err){
      handleCloseStopRoutineModal();
      console.error("custom routine failure:", err);
      notification.error(
        {
            message: err?.title,
            description: err?.detail ?? err?.message,
        });
      throw err;
      // throw err;
    }
  }

  const routineListQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "customRoutineList" ],
    queryFn: async(q) =>{
      try{
        // return await BinApiService.getCustomRoutinesOnDisk(props.binId, q.signal);
        return await BinApiService.getCustomRoutinesOnDisk(props.binId);
      }catch(err){
        console.error("error fetching routine list", err);
        notification.error(
          {
              message: err?.title,
              description: err?.detail ?? err?.message,
          });
        throw err;
      }
    }, 
  })

  return (
    <>
      <Card title="Custom Routine List" >
        <div
          id="scrollableDiv"
          style={{
            height: 400,
            overflow: 'auto',
            padding: '0 16px',
            border: '1px solid rgba(140, 140, 140, 0.35)',
          }}
        >
          <Space direction='horizontal' size="middle" style={{paddingBottom: 10, paddingTop: 10, paddingLeft:10}} >
            <UploadRoutine binId={props.binId}/>
            <Button onClick={showStopRoutineModal}><CloseOutlined />Stop current routine</Button>
          </Space>

          <InfiniteScroll
            dataLength={(routineListQuery.data?.customRoutineNames?.length ?? 0)}
            next={() => { }}
            hasMore={(routineListQuery.data?.customRoutineNames?.length ?? 0) < 50}
            // loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            loader={() => { }}
            endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={routineListQuery.data?.customRoutineNames}
              renderItem={(item) => {
                const itemIsSelected = item === routineListQuery.data?.activeRoutineFileName;
                return (
                  <List.Item
                    key={item}
                    onClick={() => showModal(item)} // Call showModal with the item when clicked
                    // style={{ cursor: 'pointer', backgroundColor: item === }}
                    style={{ cursor: 'pointer', backgroundColor: itemIsSelected ? '#002366' : undefined, borderRadius:10 }}
                  >
                    <List.Item.Meta
                      title={<Typography.Text style={{ color: itemIsSelected ? "white" : undefined, paddingLeft:10 }}>{item}</Typography.Text>}
                    />
                  </List.Item>
                );
              }}
            />
          </InfiniteScroll>


          <Modal
            open={selectedItem !== null}
            onCancel={handleCloseModal}
            footer={null}
          >
            {/* Content of the modal */}
            {selectedItem && (
              <>
                <h2>What would you like to do with the following custom routine?</h2>
                <h3>{selectedItem}</h3>
                <Space direction="horizontal" wrap>
                  <Button onClick={() => onYes(selectedItem)} style={{paddingLeft:10, paddingRight:10}}>Run Routine</Button>
                  <Button onClick={onNo} style={{paddingLeft:10, paddingRight:10}}>Nevermind</Button>
                  <Button onClick={() => onDeleteRoutine(selectedItem)} style={{paddingLeft:10, paddingRight:10}}>Delete this routine</Button>
                </Space>
              </>
            )}
          </Modal>

          <Modal
            open={stopRoutineModal ===true}
            onCancel={() => setStopRoutineModal(false)}
            footer={null}
          >
            {/* Content of the modal */}
            {stopRoutineModal && (
              <>
                <h2>Are you sure you want to stop the currently running routine?</h2>
                <Space direction="horizontal" wrap>
                  <Button onClick={onStopRoutine} style={{paddingLeft:10, paddingRight:10}}>Yes</Button>
                  <Button onClick={handleCloseStopRoutineModal} style={{paddingLeft:10, paddingRight:10}}>Nevermind</Button>
                </Space>

              </>
            )}
          </Modal>
        </div>
      </Card>
    </>
  );
};
