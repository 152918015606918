import * as React from 'react';
import FullscreenLayout from 'src/layouts/FullscreenLayout';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';

interface RoutePropsEx extends RouteProps {
    layout?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

class RouteWithLayout extends React.Component<RoutePropsEx, {}> {
    constructor(props: RoutePropsEx) {
        super(props);
    }

    getComponent(routerContext: RouteComponentProps<{}>) {
        let Layout = this.props.layout;
        let Component = this.props.component;

        if (!Component) {
            throw new Error('component prop must be set!');
        }
        if (!Layout) {
            Layout = FullscreenLayout; // MainLayout;
        }
        return (
            <Layout {...routerContext} >
                <Component {...routerContext} />
            </Layout>);
    }

    render() {
        let { component, ...props } = this.props;
        return <Route {...props} render={(routerContext) => this.getComponent(routerContext)} />;
    }
}

export default RouteWithLayout;
