import { Card, Col, Popconfirm, Row, Tag } from "antd";
import React from "react";
import FanDTO from "src/models/FanDTO";
import { DISABLED_TAG_COLOR, fanDurationFormatted } from "./BinVisualThree";

export const formatFanAmps = (fanAmps: number | null | undefined) => {
    if (fanAmps == null) {
        return '';
    }
    return (fanAmps / 1000)?.toFixed(2);
}

interface FanCardProps {
    fan: FanDTO;
    inManualMode: boolean;
    fanRuntimeSeconds: number | undefined;
    fanSetpointMCPercent: number | undefined;
    turnOnFan: (fanId: string, fanIndex: number) => void;
    turnOffFan: (fanId: string, fanIndex: number) => void;
    deviceId: string | null;
}

export const FanCard = (props: FanCardProps) => {
    const fan = props.fan;
    const inManualMode = props.inManualMode;

    return <>
                                                    <Card bodyStyle={{ padding: 8 }}>
                                                    <Row justify="space-between">
                                                        <Col key={`${fan.id}`} xs={4} sm={4} md={2} lg={8} xl={16}>
                                                            {`Fan ${fan.id} `}
                                                        </Col>

                                                        <Col key={`${fan.id}2`} xs={3} sm={3} md={2} lg={6} xl={6}>
                                                            {(
                                                                fan.isOn ?
                                                                    <Popconfirm
                                                                        title="Turn off this fan?"
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                        disabled={!inManualMode}
                                                                        onConfirm={() => props?.turnOffFan(fan.id!, fan.number - 1)}>
                                                                        <Tag style={{ textAlign: 'center' }} color={inManualMode ? "success": DISABLED_TAG_COLOR}>On</Tag>
                                                                    </Popconfirm>
                                                                    : <Popconfirm
                                                                        title="Turn on this fan?"
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                        disabled={!inManualMode}
                                                                        onConfirm={() => props?.turnOnFan(fan.id!, fan.number - 1)}>
                                                                        <Tag color={inManualMode ? "error": DISABLED_TAG_COLOR}>Off</Tag>
                                                                    </Popconfirm>)
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                    <Col >
                                                            <span>
                                                                Current:&nbsp;
                                                                <b>{`${formatFanAmps(fan.ampReading)}`}</b>
                                                                 &nbsp; Amps
                                                            </span>
                                                            <br />
                                                            <span>EMC Set Point: <b>{props.fanSetpointMCPercent?.toFixed(1) ?? '--'}</b> %</span>
                                                            <br />
                                                            <span>Runtime: {fanDurationFormatted(props.fanRuntimeSeconds)}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {props.deviceId !== "Horra" && <Col>Plenum Pressure: <b>{fan?.plenumPressure}</b> inH₂O</Col>}
                                                    </Row>
                                                </Card>
    </>
};