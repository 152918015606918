import * as React from 'react';
import HistoryUtil from 'src/utils/HistoryUtil';

interface UnexpectedErrorState {
    error: Error | null;
    errorInfo: {componentStack: string} | null;
}

class UnexpectedError extends React.Component<{}, UnexpectedErrorState> {
    constructor(props: {}) {
        super(props);
        let defaultState = { error: null, errorInfo: null };
        this.state = defaultState;
        HistoryUtil.listen((location, action) => {
            if (this.state.error) {
              this.setState(defaultState);
            }
        });
    }
    componentDidCatch(error: Error | null, errorInfo: {componentStack: string}) {
        // Catch errors in any components below and re-render with error message
        this.setState({
          error: error,
          errorInfo: errorInfo
        });

        // You can also log error messages to an error reporting service here
      }
      render() {
        if (this.state.errorInfo) {
          // Error path
          console.error(this.state.error);
          return (
            <div style={{padding: '24px'}}>
              <h2>An unexpected error has occured.</h2>
              {DEBUG ?
              (<div style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo.componentStack}
              </div>) : null}
            </div>
          );
        }
        // Normally, just render children
        return this.props.children;
      }
    }

export default UnexpectedError;
