// Constants TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
enum HeaterMode {
    EnergyEff = 'EnergyEff',
    Ambient = 'Ambient',
    Speed5 = 'Speed5',
    Speed10 = 'Speed10',
    On = 'On',
    Off = 'Off',
    Auto = 'Auto',
}
export default HeaterMode;
