import * as React from 'react';
import UserApiService from 'src/api/UserApiService';

import { Layout, notification, Button, Row, Col, Form, Input, Select, InputNumber, Table, FormInstance, Tabs, TabsProps, Card, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import UserDTO from 'src/models/UserDTO';
import {
    EditOutlined,
    UserAddOutlined
} from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { RequiredRule } from 'src/consts/FormConstants';
import PhoneInput from '../shared/PhoneInput';
import RoleUtil from 'src/utils/RoleUtil';
import Role from 'src/consts/Role';
import RoleApiService from 'src/api/RoleApiService';
import RoleDTO from 'src/models/RoleDTO';
import StatePicker from '../shared/StatePicker';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import GrowerDTO from 'src/models/GrowerDTO';

import Routes, { UserSource } from 'src/consts/Routes';
import { NavLink } from 'react-router-dom';
import UserCreationDTO from 'src/models/UserCreationDTO';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RuleObject } from 'antd/es/form/index';
import FormItem from 'antd/es/form/FormItem/index';
import { StringParam, useQueryParam } from 'use-query-params';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import Users from './Users';
import { PremierUserList } from './PremierUserList';
const { Option } = Select;
const { Content } = Layout;

export const DEBOUNCE_TIME_MS = 250;

// interface State {
//     loading: boolean;
//     users: UserDTO[];
//     roles: RoleDTO[];
//     growers: [number, string][];
//     visible: boolean;
//     state: string | null;
// }
interface DataTable {
    key: number;
    userName: string;
    firstName: string;
    lastName: string;
    id: number;
    userDetails: number;
}

// const filterData = (data: any) => (formatter: any) => data.map( (item: any) => ({
//     text: formatter(item),
//     value: formatter(item)
// }));

const generateUserColumns = (userRows: DataTable[]): ColumnsType<DataTable> => {

    const columns: ColumnsType<DataTable> = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
            sorter: (a, b) => a.firstName?.localeCompare(b.firstName),
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.lastName?.localeCompare(b.lastName),
            // filters: filterData(userRows)((row: DataTable) => row.lastName),
            // filterSearch: true,
            // onFilter: (value: string, record) => record.lastName.toLocaleLowerCase(navigator.language).includes(value.toLocaleLowerCase(navigator.language)),
        },
        {
            title: "User Name",
            dataIndex: "userName",
            key: "userName",
            sorter: (a, b) => a.userName?.localeCompare(b.userName),
        },
        {
            title: "User Details",
            dataIndex: "userDetails",
            key: "userDetails",
            render: (id) => (
                <NavLink to={Routes.generate(Routes.USERS_DETAIL, { id: id ?? '' })}>View / Edit <EditOutlined /></NavLink>
            )
        }
    ];
    return columns;
}

export const InternalUsersList = (props: { openModal: boolean, onModalOpenChange: (open: boolean) => void }) => {

    // const [dataTable, setDataTable] = useState<DataTable[] | undefined>();
    const formRef = useRef<FormInstance | null>(null);

    const [users, setUsers] = useState<UserDTO[]>([]);
    const [roles, setRoles] = useState<RoleDTO[]>([]);
    const [growers, setGrowers] = useState<[number, string][]>([]);
    const [loading, setLoading] = useState(true);

    const [USState, setUSState] = useState<string | null>(null);

    const [nameSearch, setNameSearch] = useState("");
    const nameSearchDelayedValue = useDebouncedCallback((value) => {
        setNameSearch(value);
    }, DEBOUNCE_TIME_MS);

    const fetchData = useCallback(() => {
        setLoading(true);

        Promise.all([
            UserApiService.getUsers(),
            RoleApiService.getRoles(),
            EnterpriseApiService.getGrowerIDs()
        ]).then(([users, roles, growerIDs]) => {
            let GrowerNameIDPairs: [number, string][] = growerIDs.map((grower) => [grower.growerID, grower.growerName ? grower.growerName : 'error: no name']);
            // filter out external users (username not set)
            setUsers(users.filter(u => u.username != null));
            setRoles(roles);
            setGrowers(GrowerNameIDPairs);
            setLoading(false);

        }).catch(error => {
            setLoading(false);
            notification.error({
                message: error.message,
                description: error.description
            });
        });
    }, [setLoading, setUsers, setGrowers, setRoles]);

    useEffect(() => {
        fetchData();
    }, []);

    const checkFormAndSubmit = useCallback(() => {
        formRef.current!.validateFields().then(values => {
            let growers = values.growers.map((growerID: number) => { return { growerID: growerID } as GrowerDTO; });
            let userToAdd = {
                username: values.username,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                city: values.city,
                state: values.state,
                zip: values.zip,
                isActive: true,
                growers: growers
            } as UserCreationDTO;
            UserApiService.addUser(userToAdd, values.role).then((res) => {
                props.onModalOpenChange(false);
                fetchData();
                notification.success({
                    message: 'New User Added Succesfully'
                });

            }).catch(error => {
                // this.setState({ visible: false });
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        });
    }, [fetchData, props.openModal, formRef]);

    const fillTable = () => {
        const newDataTable: DataTable[] = [];
        users.forEach((user, i) => {
            var u = {
                key: i,
                userName: user.username || '',
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                id: user.id!,
                userDetails: user.id!
            };
            newDataTable.push(u);
        });
        return newDataTable;
        // setDataTable(newDataTable);
    };



    const isAdmin = RoleUtil.currentUserIsAdmin();
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const userDataRows: DataTable[] = useMemo(() => fillTable(), [users]);
    const columns = useMemo(() => generateUserColumns(userDataRows), [userDataRows]);

    const updateFilters = () => {
        if (nameSearch === "") {
            return userDataRows;
        }

        const filteredTable = userDataRows
            .filter((record) => record.lastName.toUpperCase().includes(nameSearch.toUpperCase())
            || record.firstName.toUpperCase().includes(nameSearch.toUpperCase()
        )
        )
        return filteredTable;
    };

    const finalTable = useMemo(() => updateFilters(), [nameSearch, userDataRows]);

    return <>
        <Modal
            key="modal"
            title="Create a User"
            okText="submit"
            destroyOnClose={true}
            onCancel={() => {
                props.onModalOpenChange(false);
            }}
            onOk={checkFormAndSubmit}
            open={props.openModal}
        >
            <Row>
                <Col span={24}>
                    <Form ref={formRef} key="addUser Form" style={{ width: '100%' }}>
                        <FormItem
                            {...layout}
                            label="First Name"
                            name="firstName"
                            rules={[RequiredRule]}>
                            <Input />
                        </FormItem>
                        <FormItem
                            {...layout}
                            label="Last Name"
                            name="lastName"
                            rules={[RequiredRule]}>
                            <Input />
                        </FormItem>
                        <FormItem
                            {...layout}
                            label="Email"
                            name="email"
                            rules={[RequiredRule]}>
                            <Input />
                        </FormItem>
                        <FormItem
                            {...layout}
                            label="Phone Number"
                            name="phone"
                            rules={[RequiredRule]}>
                            <PhoneInput style={{}} onChange={(val) => {
                                formRef.current?.setFieldsValue({ phone: val });
                            }} />
                        </FormItem>
                        <FormItem
                            {...layout}
                            label="Username"
                            name="username"
                            rules={[RequiredRule]}>
                            <Input />
                        </FormItem>

                        <Form.Item
                            {...layout}
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            hasFeedback={true}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            {...layout}
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['password']}
                            hasFeedback={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_: RuleObject, value: string) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Passwords do not match'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item label="Organization(s)" name="growers" rules={[RequiredRule]} {...layout}>
                            <Select
                                optionFilterProp="children" // This is important for search to work
                                showSearch
                                mode="multiple"
                                style={{ maxWidth: '200px' }}
                            >
                                {
                                    growers?.map((grower, index) => (
                                        <Option key={index} value={grower[0]}>
                                            {grower[1]}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Role" name="role" rules={[RequiredRule]} {...layout}>
                            <Select mode="multiple" style={{ maxWidth: '200px' }}>
                                {
                                    roles?.map((role, index) => (
                                        <Option key={index} value={role.id}>
                                            {role.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <FormItem
                            {...layout}
                            label="Address Line 1"
                            name="addressLine1"
                            rules={[RequiredRule]}>
                            <Input />
                        </FormItem>
                        <FormItem
                            {...layout}
                            label="Address Line 2"
                            name="addressLine2">
                            <Input />
                        </FormItem>
                        <FormItem
                            {...layout}
                            label="City"
                            name="city"
                            rules={[RequiredRule]}>
                            <Input />
                        </FormItem>
                        <FormItem
                            {...layout}
                            label="State"
                            name="state"
                            rules={[RequiredRule]}>
                            <StatePicker initialValue={USState} onChange={(value) => setUSState(value)} />
                        </FormItem>
                        <FormItem
                            {...layout}
                            label="Zipcode"
                            name="zip"
                            rules={[RequiredRule]}>
                            <InputNumber />
                        </FormItem>
                    </Form>
                </Col>
            </Row>
        </Modal>

        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Space wrap direction="horizontal" size="middle">
                    <Input style={{ width: 240 }} onChange={(evt) => nameSearchDelayedValue(evt.target.value)} placeholder='Search by Name'></Input>
                </Space>
            </Col>
            <Col xs={24}>
                <Table loading={loading} key="userListTable" dataSource={finalTable} columns={columns} bordered={true} />
            </Col>
        </Row>
    </>;

}

const UserList = () => {
    const isAdmin = RoleUtil.currentUserIsAdmin();
    const canViewUserList = RoleUtil.CanViewBinFleet();
    const [showAddUserModal, setShowAddUserModal] = useState(false);

    const [userSource, setUserSource] = useQueryParam('userSource', { ...StringParam, default: UserSource.MyShivvers });

    const items: TabsProps['items'] = [
        {
            key: UserSource.MyShivvers,
            label: "Shivvers",
            children: <PremierUserList />
        }];
        if (isAdmin) {
            items.push({
                key: UserSource.Internal,
                label: 'STIG',
                children: <InternalUsersList openModal={showAddUserModal} onModalOpenChange={(open) => setShowAddUserModal(open)} />,
            });
        }

    return (
        <Content className="content-background" key="content-background">
            {canViewUserList ? (<>
                <Card
                    title="User List"
                    key="adminPager"
                    tabProps={{
                        activeKey: userSource!
                    }}
                    tabList={items}
                    onTabChange={(key) => {
                        console.log("card tab value:", key);
                        setUserSource(key);
                    }}
                    defaultActiveTabKey="1"
                    extra={[<>
                        <Button style={{visibility: userSource !== UserSource.Internal ? "hidden": undefined}}
                             key="addUser" icon={<UserAddOutlined />} onClick={() => {
                            setShowAddUserModal(true);
                        }}>Add User</Button>
                    </>]}>


                </Card>
            </>
            ) : (<PageHeader key="nonAdminPager" title="User List" />)}

        </Content>
    );


}

export default UserList;
