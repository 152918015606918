import { useIsFetching } from "@tanstack/react-query";
import { BaseChartProps } from "./TemperatureCableChart";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { useCallback, useState } from "react";
import { RangeValue } from "./ChartContainer";
import dayjs from "dayjs";
import { Button, Select, SelectProps, Space } from "antd";
import { ChartDatePicker } from "./Co2Chart";
import React from "react";
import { AmbientPlenumChart, LayerDewPointChart } from "./AmbientPlenumChart";
import { RedoOutlined } from "@ant-design/icons";
import { LayerMCHistoryChart, useModeChangeHistory } from "./LayerMCHistoryChart";
import { LayerTemperatureHistoryChart } from "./LayerTemperatureHistoryChart";

export const IncomingAirConditionsChartTab = (props: BaseChartProps) => {

    const currentDate = dayjs();
    const priorDate = currentDate.subtract(7, 'days');
  
  
    const isControlled = typeof props.value != 'undefined';
    const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);
    const inprogress = useIsFetching([...binDBKeys.bin(props.binId), "charts"]);
  
    // Internally, we need to deal with some value. Depending on whether
    // the component is controlled or not, that value comes from its
    // props or from its internal state.
  
    const value = isControlled ? props.value : internalValue;
  
    const onDateChange = useCallback((values: RangeValue, formatter?: any) => {
      if (props.onChange) {
        props.onChange(values);
      }
      else {
        setIntervalValue(values);
      }
    }, [props.onChange]);


  
  
    return <>
      <div>
        <Space direction="horizontal" wrap>
          <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />
          <Button type="primary" disabled={inprogress > 0} icon={<RedoOutlined spin={inprogress > 0} />} onClick={() => {
            onDateChange([value?.[0]!, dayjs()], ['', '']);
          }}>
          </Button>
  
        </Space>
  
        <div style={{ width: "100%" }}>
  
  
          <AmbientPlenumChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} min={0} chartHeight={188} containerMinHeight={188 * 1} />
          <LayerDewPointChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} chartHeight={188} />

        </div>
      </div>
    </>
}