import * as React from 'react';
import AccountApiService from 'src/api/AccountApiService';
import {
    Alert,
    Button,
    Col,
    Form,
    FormInstance,
    Input,
    Layout,
    Row,
    Typography
    } from 'antd';
import { BreadcrumbsItem } from '../shared/GlobalBreadcrumb';
import { LoadingOutlined } from '@ant-design/icons';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const FormItem = Form.Item;
const Content = Layout.Content;

interface RequestResetPasswordState {
    error: any;
    resetSuccess: boolean;
    loading: boolean;
}

interface RequestResetPasswordProps {
}

class RequestResetPassword extends React.Component<RequestResetPasswordProps & RouteComponentProps<{}>, RequestResetPasswordState> {
    private readonly _formRef = React.createRef<FormInstance>();

    constructor(props: RequestResetPasswordProps & RouteComponentProps<{}>) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            error: null,
            resetSuccess: false,
            loading : false
        };
    }

    handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ loading: true });

        let model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
        AccountApiService.requestResetPassword(model).
            then(() => {
                    this.setState({ loading: false, resetSuccess: true, error: null });
                },
                 (error) => {
                    this.setState({ loading: false, error: error, resetSuccess: false });
                }
       );
    }

    render() {
        let alert: JSX.Element | null = null;
        let {error, loading, resetSuccess} = this.state;
        if (error !== null) {
            let message = 'An error occurred while trying to send the reset password email.';
            if (error.message) {
                message = error.message;
            }
            alert = (
                <Alert
                    message="Error"
                    description={message}
                    type="error"
                    showIcon={true}
                    style={{marginBottom: '12px'}}
                />);
        }
        if (resetSuccess) {
            let message = 'An email with a link to reset your password was sent to the email to provided.';
            alert = (
                <Alert
                    message="Success"
                    description={message}
                    type="success"
                    showIcon={true}
                    style={{marginBottom: '12px'}}
                />);
        }

        let labelCol = { xs: 24, sm: 6, md: 6, lg: 7, xl: 7, xxl: 7 };
        let wrapperCol = { xs: 24, sm: 17, md: 17, lg: 17, xl: 17, xxl: 17 };
        let wrapperColNoLabel = {};
        Object.keys(wrapperCol).forEach((val) => {
            wrapperColNoLabel[val] = { span: wrapperCol[val], offset: (wrapperCol[val] + labelCol[val] > 24 ? 0 : labelCol[val]) };
        });

        return (
            // type="flex"
            <Content className="content-background-login">
                <BreadcrumbsItem name="reset_password">
                        Reset Password
                </BreadcrumbsItem>
            <Row justify="space-between">
                <Col className="flex-grow" />
                    <Col md={20} sm={24} xs={24}>
                    <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <Typography.Title level={2}>
                            Reset Password
                        </Typography.Title>
                        <p>Please enter the email associated to your account.</p>
                        {alert}
                        <Form ref={this._formRef} layout="horizontal" onFinish={this.handleSubmit}>
                            <FormItem name="email" label="Email Address" rules={[{ required: true, message: 'Email is required' }]}
                                labelCol={labelCol} wrapperCol={wrapperCol}>
                                <Input placeholder="Email"/>
                            </FormItem>
                            <FormItem wrapperCol={wrapperColNoLabel}>
                                <Button type="primary"
                                        htmlType="submit"
                                        style={{width: '100%'}}
                                        size="large"
                                    disabled={loading}>
                                    {loading ? (<span><LoadingOutlined /> Processing...</span>) : <span>Reset Password</span>}
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                </Col>
                <Col className="flex-grow" />
                </Row>
            </Content>
        );
    }
}

export default withRouter(RequestResetPassword);
