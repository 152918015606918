import * as React from 'react';
import Routes from 'src/consts/Routes';
import UserDetail from './UserDetail';
import UserList from './UserList';
import { BreadcrumbsItem } from 'src/pages/shared/GlobalBreadcrumb';
import { RedirectAs404 } from 'src/utils/RouteErrors';
import { Route, Switch } from 'react-router-dom';

class Users extends React.Component<{}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <React.Fragment key="Users">
                <BreadcrumbsItem key="Breadcrumb" name="users" to={Routes.generate(Routes.USERS_BASE)} iconName="document">Users</BreadcrumbsItem>
                <Switch key="RouteWwitch">
                    <Route exact={true} path={Routes.USERS_BASE} component={UserList} />
                    <Route path={Routes.USERS_DETAIL} component={UserDetail} />

                    {/* This needs to be the last item */}
                    <Route component={RedirectAs404} />
                </Switch>
            </React.Fragment>
        );
    }
}

export default Users;
