import { Card, Modal, message } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { _deprecated } from "chart.js/dist/helpers/helpers.core";
import React, { useCallback } from "react";
import { MutableRefObject } from "react";
import IotModel from "src/consts/IotModel";
import DesiredHardwareDTO from "src/models/DesiredHardwareDTO";
import DesiredLayoutDTO from "src/models/DesiredLayoutDTO";
import DesiredRingHardwareDTO from "src/models/DesiredRingHardwareDTO";
import DesiredRingLayoutDTO from "src/models/DesiredRingLayoutDTO";
import UnifiedDeviceConfigDTO from "src/models/UnifiedDeviceConfigDTO";

export interface UnifiedDeviceConfigDTOPatch extends Omit<UnifiedDeviceConfigDTO, "overrides"> {
    overrides: Partial<UnifiedDeviceConfigDTO['overrides']>,
}

/* EXCEL SHEET COPY PASTING UTILS */

function getTotalFromIndices(lines: string[], keyName: string){
    let cent = getCenterRingSubsection(lines)
    let centCount = getIndexCount(cent, keyName)

    let peri = getPerimeterRingSubsection(lines)
    let periCount = getIndexCount(peri, keyName)

    return centCount + periCount
}

function getPerimeterRingSubsection(lines: string[]){
    let perimeterRingStart = 0;
    for(let i=0; i< lines.length; i++){
        let line = lines[i];
        let key = line.split(/\t/)[0]
        if(key=== "Perimeter Ring" || key === "Perimeter"){
            perimeterRingStart = i;
        }
    }

    return lines.slice(perimeterRingStart, lines.length);
}

function getCenterRingSubsection(lines: string[]){
    let centerRingStart = 0;
    let centerRingEnd = 0;
    for(let i=0; i< lines.length; i++){
        let line = lines[i];
        let key = line.split(/\t/)[0]
        if(key === "Center Ring"){
            centerRingStart = i;
        }
        if(key=== "Perimeter Ring" || key === "Perimeter"){
            centerRingEnd = i;
        }
    }

    return lines.slice(centerRingStart, centerRingEnd)
}

function tryGetIntValue(lines: string[], keyName: string, offset: number): number | null{

    for(let i=0; i< lines.length; i++){
        let line = lines[i];
        let key = line.split(/\t/)[0]

        if(key === keyName){
            let value = parseInt(line.split(/\t/)[1 + offset]);
            if(value !== null && value !== undefined && !Number.isNaN(value)){
                return value;
            }
        }
    }

    return null;

}

function tryGetBoolValue(lines: string[], keyName: string, offset: number): boolean | null{

    let i =0;
    let boolValue = null;

    for(i=0; i< lines.length; i++){
        let line = lines[i];
        let key = line.split(/\t/)[0]

        if(key === keyName){
            let value = parseBools(line.split(/\t/)[1 + offset]);
            if(value !== null && value !== undefined && !Number.isNaN(value)){
                boolValue = value;
            }
        }
    }

    return boolValue;
}

function tryGetFloatValue(lines: string[], keyName: string, offset: number):number | null{

    for(let i=0; i< lines.length; i++){
        let line = lines[i];
        let key = line.split(/\t/)[0]

        if(key === keyName){
            let value = parseFloat(line.split(/\t/)[1 + offset]);
            if(value !== null && value !== undefined && !Number.isNaN(value)){
                return value;
            }
        }
    }

    return null;

}

function tryGetStrValue(lines: string[], keyName: string, offset: number) : string | null{

    for(let i=0; i< lines.length; i++){
        let line = lines[i];
        let key = line.split(/\t/)[0]

        if(key === keyName){
            let value = line.split(/\t/)[1];
            if(value !== null && value !== undefined && !Number.isNaN(value)){
                return value;
            }
        }
    }

    return null;
}

function getIndexCount(lines: string[], keyName: string){

    let numIndices= 0;
    let parsedValue = 0;
    let parseCols = false;
    let colCounter = 1;

    for(let i=0; i< lines.length; i++){
        let line = lines[i];
        let key = line.split(/\t/)[0]

        if(key === keyName){
            parseCols = true;
            while(parseCols){
                parsedValue = parseInt(line.split(/\t/)[colCounter]);
                if(!Number.isNaN(parsedValue)){
                    numIndices++;
                    colCounter++;
                }else{
                    parsedValue = 0;
                    parseCols = false;
                }
            }
        }
    }
    return numIndices;
}


const parseBools = (text: string, defaultValue: boolean = false): boolean => {
    if (text == null) {
        return defaultValue;
    }
    if (text.toUpperCase() === "ON") {
        return true;
    }
    if (text.toUpperCase() === "OFF") {
        return false;
    }
    if (text.toUpperCase() === "TRUE") {
        return true;
    }
    if (text.toUpperCase() === "FALSE") {
        return false;
    }
    return defaultValue;
}

function getRing0GamSubsection(lines: string[]){
    let centerRingStart = 0;
    let centerRingEnd = 0;
    for(let i=0; i< lines.length; i++){
        let line = lines[i];
        let key = line.split(/\t/)[0]
        if(key === "Ring 0 GAM"){
            centerRingStart = i+1;
        }
    }
    centerRingEnd = centerRingStart + 3;

    return lines.slice(centerRingStart, centerRingEnd)
}

function getRing1GamSubsection(lines: string[]){
    let centerRingStart = 0;
    let centerRingEnd = 0;
    for(let i=0; i< lines.length; i++){
        let line = lines[i];
        let key = line.split(/\t/)[0]
        if(key === "Ring 1 GAM"){
            centerRingStart = i+1;
        }
    }
    centerRingEnd = centerRingStart + 3;

    return lines.slice(centerRingStart, centerRingEnd)
}

function getLine(lines: string[], keyname: string){
    for(let i =0; i < lines.length; i++){
        let line = lines[i]
        let key = line.split(/\t/)[0]
        if(key === keyname){
            return line.split(/\t/)
        }
    }
    return [];
}


export function updateTwinV2(existing: UnifiedDeviceConfigDTO, text: string): Partial<UnifiedDeviceConfigDTOPatch>{
    console.log("original text", text);
    const lines = text.split(/\r?\n/);

    const twinSettings: Partial<UnifiedDeviceConfigDTOPatch> = {...existing};
    const hardware: Partial<UnifiedDeviceConfigDTO['hardware']> = {};

    if (twinSettings.overrides == null) {
        twinSettings.overrides = {};
    }
    twinSettings.overrides.noHeater = tryGetBoolValue(lines, "No Heater", 0);
    hardware.highPoweredFans = tryGetBoolValue(lines, "High Powered Fans", 0) ?? undefined;
    twinSettings.overrides.monitoringBin = tryGetBoolValue(lines, "Monitoring Bin", 0);
    twinSettings.binName = tryGetStrValue(lines, "Bin Name", 0) ?? undefined;
    twinSettings.hardwareYear = tryGetIntValue(lines, "Hardware Year", 0)!;
    twinSettings.hasCamera = tryGetBoolValue(lines, "Has Camera", 0)!;
    twinSettings.toEmail = tryGetStrValue(lines, "To Email", 0);
    const maybeIotModel = tryGetStrValue(lines, "IoT Model", 0);
    const maybeIotModelLowercase = maybeIotModel?.toLocaleLowerCase();
    if (maybeIotModelLowercase === "reliagate") {
        twinSettings.iotModel = IotModel.Reliagate;
    }
    else if (["compulab", "compulab-iotg-imx8plus", "compulab-imx8plus"].includes(maybeIotModelLowercase!)) {
        twinSettings.iotModel = IotModel.Compulab_IOTG_IMX8PLUS;
    }
    
    hardware.fans = tryGetIntValue(lines, "Fans", 0)!;
    
    const layout: Partial<UnifiedDeviceConfigDTO['layout']> = {};
    layout.binDiameter = tryGetIntValue(lines, "Bin Diameter", 0)!;
    layout.grainEaveHeight = tryGetFloatValue(lines, "Grain Eave Height", 0)!;

    layout.hasInnerRingGAM = tryGetBoolValue(lines, "Has Inner GAM", 0);
    layout.hasOuterRingGAM = tryGetBoolValue(lines, "Has Outer GAM", 0);

    var ring0GamLines = getRing0GamSubsection(lines);
    //Gam0
    layout.innerRingGAMRadius = tryGetFloatValue(ring0GamLines, "Radius", 0)!;
    layout.innerRingGAMHeight = tryGetFloatValue(ring0GamLines, "Height", 0)!;
    layout.innerRingGAMBearing =  tryGetIntValue(ring0GamLines, "Bearing", 0)!;
  
    var ring1GamLines = getRing1GamSubsection(lines);
    //Gam1
    layout.outerRingGAMRadius = tryGetFloatValue(ring1GamLines, "Radius", 0)!;
    layout.outerRingGAMHeight = tryGetFloatValue(ring1GamLines, "Height", 0)!;
    layout.outerRingGAMBearing = tryGetIntValue(ring1GamLines, "Bearing", 0)!;

    const fanBearings = getLine(lines, "Fan Bearings");
    fanBearings.shift();

    //twinSettings.fanBearings = fanBearings.filter((a) => a.length > 0);
    layout.fanBearings = fanBearings.filter((a) => a.length > 0)
        // .slice(0, hardware.fans)
        .map(deg => Number(deg));
  
    const fanDuctArea = getLine(lines, "Fan Duct Area");
    fanDuctArea.shift();
    //twinSettings.fanDuctArea = fanDuctArea.filter((a) => a.length > 0);
    // cutoff extra areas that are past number of fans declared
    layout.fanDuctArea = fanDuctArea.filter((a) => a.length > 0)
    // .slice(0, hardware.fans)
    .map(area => Number(area));

    const layoutCenterRing: Partial<DesiredRingLayoutDTO & {OPISensorHeights: number[], opiBearings: number[], stacks: number, layers: number | null}> = {};

    let centerRingLines = getCenterRingSubsection(lines);
    layoutCenterRing.ring = tryGetIntValue(centerRingLines, "Ring", 0)!;

    layoutCenterRing.stacks = tryGetIntValue(centerRingLines, "Stacks", 0)!;
    const centerNumValves = getTotalFromIndices(centerRingLines, "Valve Heights");
    layoutCenterRing.radius = tryGetFloatValue(centerRingLines, "Radius", 0)!;
    layoutCenterRing.stackHeight = tryGetFloatValue(centerRingLines, "Stack Height", 0)!;
  
    const centerRingValveHeights = getLine(centerRingLines, "Valve Heights")
    centerRingValveHeights.shift();
    layoutCenterRing.valveHeights = centerRingValveHeights.filter((a) => a.length > 0).map(height => Number(height));
  
    const centerRingThermoHeights = getLine(centerRingLines, "Thermocouples Heights");
    centerRingThermoHeights.shift();
    layoutCenterRing.thermocoupleHeights = centerRingThermoHeights.filter(
      (a) => a.length > 0
    ).map(height => Number(height));

    const centerOPI = getLine(centerRingLines, "OPI Sensor Heights")
    centerOPI.shift();
    // OPI Sensor Heights
    layoutCenterRing.opiRhtsHeights = centerOPI.filter((a) => a.length > 0).map(height => Number(height));
  
    const stackBearings = getLine(centerRingLines, "Stack Bearings")
    stackBearings.shift();
    layoutCenterRing.stackBearings = stackBearings.filter((a) => a.length > 0).map(bearing => Number(bearing));
  
    const tempCableBearings = getLine(centerRingLines, "Temperature Cable Bearings")
    tempCableBearings.shift();
    layoutCenterRing.temperatureCableBearings = tempCableBearings.filter((a) => a.length > 0).map(bearing => Number(bearing));
  
    const opiBearings = getLine(centerRingLines, "OPI Cable Bearings")
    opiBearings.shift();
    // OPI Cable Bearings
    layoutCenterRing.opiMoistureCableBearings = opiBearings.filter((a) => a.length > 0).map(bearing => Number(bearing));
  
    if (layout.rings == null) {
        layout.rings = [];
    }
    if (hardware.rings == null) {
        hardware.rings = [];
    }

    let centerLayers = undefined;
    let layerLine = getLine(centerRingLines, "Layers")
    if ( layerLine != null) {
        centerLayers = tryGetIntValue(centerRingLines, "Layers", 0);
    }

    layoutCenterRing.layers = centerLayers;
    layout.rings.push({...existing?.layout?.rings?.[0],...layoutCenterRing as DesiredRingLayoutDTO});

    hardware.rings.push({
        ...existing?.hardware?.rings?.[0],
        ring: layoutCenterRing.ring,
        stacks: layoutCenterRing.stacks,
        temperatureCables: layoutCenterRing.temperatureCableBearings?.length ?? 0,
        thermocouples: layoutCenterRing.thermocoupleHeights.length ?? 0,
        valves: centerNumValves,
        opiMoistureCables: layoutCenterRing.opiMoistureCableBearings?.length ?? 0,
        layers: (centerLayers ?? existing?.hardware?.rings?.[0]?.layers) ?? null,
        opiRhtsPerCable: layoutCenterRing.opiRhtsHeights?.length ?? 0,
    } as DesiredRingHardwareDTO);


    if (getLine(lines, "Perimeter Ring") !== null) {
        const ring1: Partial<DesiredRingLayoutDTO & { stacks: number, opiBearings: number[], layers: number | null}> = {};

        let perimeterRinglines = getPerimeterRingSubsection(lines);
        ring1.ring = tryGetIntValue(perimeterRinglines, "Ring", 0)!;
        //ring1.Stacks = rows[39].split(/\t/)[1];
        ring1.stacks = tryGetIntValue(perimeterRinglines, "Stacks", 0)!;
        //ring1.Valves = rows[40].split(/\t/)[1];
        const ring1NumValves = getTotalFromIndices(perimeterRinglines, "Valve Heights");
        ring1.radius = tryGetFloatValue(perimeterRinglines, "Radius", 0)!;
        ring1.stackHeight = tryGetFloatValue(perimeterRinglines, "Stack Height", 0)!;

        const valveHeights = getLine(perimeterRinglines, "Valve Heights")
        valveHeights.shift();
        ring1.valveHeights = valveHeights.filter((a) => a.length > 0).map(height => Number(height));

        const thermoHeights = getLine(perimeterRinglines, "Thermocouples Heights")
        thermoHeights.shift();
        ring1.thermocoupleHeights = thermoHeights.filter((a) => a.length > 0).map(height => Number(height));

        const opiHeights =  getLine(perimeterRinglines, "OPI Sensor Heights")
        opiHeights.shift();
        ring1.opiRhtsHeights = opiHeights.filter((a) => a.length > 0).map(height => Number(height));

        const stackBearings1 = getLine(perimeterRinglines, "Stack Bearings")
        stackBearings1.shift();
        ring1.stackBearings = stackBearings1.filter((a) => a.length > 0).map(bearing => Number(bearing));

        const tempCableBearingsPerimeter = getLine(perimeterRinglines, "Temperature Cable Bearings")
        tempCableBearingsPerimeter.shift();
        ring1.temperatureCableBearings = tempCableBearingsPerimeter.filter((a) => a.length > 0).map(bearing => Number(bearing));

        const opiBearingsPerimeter =getLine(perimeterRinglines, "OPI Cable Bearings")
        opiBearingsPerimeter.shift();
        // OPI cable bearings
        ring1.opiMoistureCableBearings = opiBearingsPerimeter.filter((a) => a.length > 0).map(bearing => Number(bearing));

        let perimeterLayers = undefined;
        let layerLine = getLine(perimeterRinglines, "Layers")
        if ( layerLine != null) {
            perimeterLayers = tryGetIntValue(perimeterRinglines, "Layers", 0);
        }

        ring1.layers = perimeterLayers;

        //twinSettings.ring1 = ring1;
        layout.rings.push({...existing?.layout?.rings?.[1], ...ring1 as DesiredRingLayoutDTO});

        hardware.rings.push({
            ...existing?.hardware?.rings?.[1],
            ring: ring1.ring,
            stacks: ring1.stacks,
            temperatureCables: ring1.temperatureCableBearings?.length ?? 0,
            thermocouples: ring1.thermocoupleHeights?.length ?? 0,
            valves: ring1NumValves,
            opiMoistureCables: ring1.opiMoistureCableBearings?.length ?? null,
            layers: (perimeterLayers ?? existing?.hardware?.rings?.[1]?.layers) ?? null,
            opiRhtsPerCable: ring1.opiRhtsHeights?.length ?? 0,
        } as DesiredRingHardwareDTO)
    } else {
        console.warn("skipping populating ring1/perimeter since the rows are missing from pasted data");
    }

    
    if (getLine(lines, "Automation Type") != null) {
        const automationType = tryGetStrValue(lines, "Automation Type", 0);
        twinSettings.automationType = automationType;
        console.log("set twinSettings automation type to: ", twinSettings.automationType);
    }

    twinSettings.hardware = {...existing.hardware, ...hardware as DesiredHardwareDTO};
    twinSettings.layout = {...existing.layout, ...layout as DesiredLayoutDTO};

    if (getLine(lines, "Layer Heights for Bin") != null) {
        
        const layersTopHeights = getLine(lines, "Layer Heights for Bin");
        console.log("row 54 split results", layersTopHeights);
        layersTopHeights.shift();
        if (twinSettings.layout != null) {
            twinSettings.layout.layersTopHeight = layersTopHeights.filter((a) => a.length > 0).map(layerHeight => Number(layerHeight));
            console.log("set twinSettings.layout.layersTopHeight to: ",  twinSettings.layout.layersTopHeight);
        }
        else {
            console.log(" twinSettings.layout.layersTopHeight not updated since layout was null ");
        }
    }
    
    console.log("twin settings that will be updated", twinSettings);
    return twinSettings;

}


/**
 * @deprecated since development of updateTwinV2
 */
export function updateTwin(existing: UnifiedDeviceConfigDTO, text: string): Partial<UnifiedDeviceConfigDTOPatch> {
  
    console.log("original text", text);
    const rows = text.split(/\r?\n/);
  
    const twinSettings: Partial<UnifiedDeviceConfigDTOPatch> = {...existing};
    const hardware: Partial<UnifiedDeviceConfigDTO['hardware']> = {};

    //twinSettings.additionalProperties = "From text update";
    //twinSettings.overrides = {noHeater: true};
    //return twinSettings;
    if (twinSettings.overrides == null) {
        twinSettings.overrides = {};
    }
    twinSettings.overrides.noHeater = parseBools(rows[2].split(/\t/)[1]);
    hardware.highPoweredFans = parseBools(rows[3].split(/\t/)[1]);
    twinSettings.overrides.monitoringBin = parseBools(rows[4].split(/\t/)[1]);
    //row 5 is Hadware & layout header
    twinSettings.binName = rows[7].split(/\t/)[1];
    twinSettings.hardwareYear = Number(rows[8].split(/\t/)[1]);


    hardware.fans = Number(rows[11].split(/\t/)[1]);

    const layout: Partial<UnifiedDeviceConfigDTO['layout']> = {};
    layout.binDiameter = Number(rows[12].split(/\t/)[1]);
    layout.grainEaveHeight = Number(rows[13].split(/\t/)[1]);
 
    //Gam0
    layout.innerRingGAMRadius = Number(rows[17].split(/\t/)[1]);
    layout.innerRingGAMHeight = Number(rows[18].split(/\t/)[1]);
    layout.innerRingGAMBearing = Number(rows[19].split(/\t/)[1]);
  
    //Gam1
    layout.outerRingGAMRadius = Number(rows[21].split(/\t/)[1]);
    layout.outerRingGAMHeight = Number(rows[22].split(/\t/)[1]);
    layout.outerRingGAMBearing = Number(rows[23].split(/\t/)[1]);
  
    const fanBearings = rows[25].split(/\t/);
    fanBearings.shift();

    //twinSettings.fanBearings = fanBearings.filter((a) => a.length > 0);
    layout.fanBearings = fanBearings.filter((a) => a.length > 0)
        // .slice(0, hardware.fans)
        .map(deg => Number(deg));
  
    const fanDuctArea = rows[26].split(/\t/);
    fanDuctArea.shift();
    //twinSettings.fanDuctArea = fanDuctArea.filter((a) => a.length > 0);
    // cutoff extra areas that are past number of fans declared
    layout.fanDuctArea = fanDuctArea.filter((a) => a.length > 0)
    // .slice(0, hardware.fans)
    .map(area => Number(area));

    const layoutCenterRing: Partial<DesiredRingLayoutDTO & {OPISensorHeights: number[], opiBearings: number[], stacks: number, layers: number | null}> = {};

    layoutCenterRing.ring = Number(rows[29].split(/\t/)[1]);

    layoutCenterRing.stacks = Number(rows[30].split(/\t/)[1]);
    const centerNumValves = Number(rows[26].split(/\t/)[1]);
    layoutCenterRing.radius = Number(rows[31].split(/\t/)[1]);
    layoutCenterRing.stackHeight = Number(rows[32].split(/\t/)[1]);
  
    const centerRingValveHeights = rows[35].split(/\t/);
    centerRingValveHeights.shift();
    layoutCenterRing.valveHeights = centerRingValveHeights.filter((a) => a.length > 0).map(height => Number(height));
  
    const centerRingThermoHeights = rows[36].split(/\t/);
    centerRingThermoHeights.shift();
    layoutCenterRing.thermocoupleHeights = centerRingThermoHeights.filter(
      (a) => a.length > 0
    ).map(height => Number(height));
  
    const centerOPI = rows[32].split(/\t/);
    centerOPI.shift();
    // OPI Sensor Heights
    layoutCenterRing.opiRhtsHeights = centerOPI.filter((a) => a.length > 0).map(height => Number(height));
  
    const stackBearings = rows[37].split(/\t/);
    stackBearings.shift();
    layoutCenterRing.stackBearings = stackBearings.filter((a) => a.length > 0).map(bearing => Number(bearing));
  
    const tempCableBearings = rows[38].split(/\t/);
    tempCableBearings.shift();
    layoutCenterRing.temperatureCableBearings = tempCableBearings.filter((a) => a.length > 0).map(bearing => Number(bearing));
  
    const opiBearings = rows[40].split(/\t/);
    opiBearings.shift();
    // OPI Cable Bearings
    layoutCenterRing.opiBearings = opiBearings.filter((a) => a.length > 0).map(bearing => Number(bearing));
  
    if (layout.rings == null) {
        layout.rings = [];
    }
    if (hardware.rings == null) {
        hardware.rings = [];
    }

    let centerLayers = undefined;
    if (rows[52] != null) {
        centerLayers = Number(rows[52].split(/\t/)[1]);
    }
    layoutCenterRing.layers = centerLayers;
    layout.rings.push({...existing?.layout?.rings?.[0],...layoutCenterRing as DesiredRingLayoutDTO});


    hardware.rings.push({
        ...existing?.hardware?.rings?.[0],
        ring: layoutCenterRing.ring,
        stacks: layoutCenterRing.stacks,
        temperatureCables: layoutCenterRing.temperatureCableBearings?.length ?? 0,
        thermocouples: layoutCenterRing.thermocoupleHeights.length ?? 0,
        valves: centerNumValves,
        opiMoistureCables: layoutCenterRing.opiMoistureCableBearings?.length ?? 0,
        layers: (centerLayers ?? existing?.hardware?.rings?.[0]?.layers) ?? null,
        opiRhtsPerCable: layoutCenterRing.opiRhtsHeights?.length ?? 0,
    } as DesiredRingHardwareDTO);
  
    //perimRings
  
    if (!(rows[38] == null || rows[50] == null)) {
        const ring1: Partial<DesiredRingLayoutDTO & { stacks: number, opiBearings: number[], layers: number | null}> = {};
        ring1.ring = Number(rows[38].split(/\t/)[1]);
        //ring1.Stacks = rows[39].split(/\t/)[1];
        ring1.stacks = Number(rows[39].split(/\t/)[1]);
        //ring1.Valves = rows[40].split(/\t/)[1];
        const ring1NumValves = Number(rows[40].split(/\t/)[1]);
        ring1.radius = Number(rows[41].split(/\t/)[1]);
        ring1.stackHeight = Number(rows[42].split(/\t/)[1]);

        const valveHeights = rows[44].split(/\t/);
        valveHeights.shift();
        ring1.valveHeights = valveHeights.filter((a) => a.length > 0).map(height => Number(height));

        const thermoHeights = rows[45].split(/\t/);
        thermoHeights.shift();
        ring1.thermocoupleHeights = thermoHeights.filter((a) => a.length > 0).map(height => Number(height));

        const opiHeights = rows[46].split(/\t/);
        opiHeights.shift();
        ring1.opiRhtsHeights = opiHeights.filter((a) => a.length > 0).map(height => Number(height));

        const stackBearings1 = rows[48].split(/\t/);
        stackBearings1.shift();
        ring1.stackBearings = stackBearings1.filter((a) => a.length > 0).map(bearing => Number(bearing));

        const tempCableBearingsPerimeter = rows[49].split(/\t/);
        tempCableBearingsPerimeter.shift();
        ring1.temperatureCableBearings = tempCableBearingsPerimeter.filter((a) => a.length > 0).map(bearing => Number(bearing));

        const opiBearingsPerimeter = rows[50].split(/\t/);
        opiBearingsPerimeter.shift();
        // OPI cable bearings
        ring1.opiMoistureCableBearings = opiBearingsPerimeter.filter((a) => a.length > 0).map(bearing => Number(bearing));

        let perimeterLayers = undefined;
        if (rows[53] != null) {
            perimeterLayers = Number(rows[53].split(/\t/)[1]);
        }
        ring1.layers = perimeterLayers;


        //twinSettings.ring1 = ring1;
        layout.rings.push({...existing?.layout?.rings?.[1], ...ring1 as DesiredRingLayoutDTO});

        hardware.rings.push({
            ...existing?.hardware?.rings?.[1],
            ring: ring1.ring,
            stacks: ring1.stacks,
            temperatureCables: ring1.temperatureCableBearings?.length ?? 0,
            thermocouples: ring1.thermocoupleHeights?.length ?? 0,
            valves: ring1NumValves,
            opiMoistureCables: ring1.opiMoistureCableBearings?.length ?? null,
            layers: (perimeterLayers ?? existing?.hardware?.rings?.[1]?.layers) ?? null,
            opiRhtsPerCable: ring1.opiRhtsHeights?.length ?? 0,
        } as DesiredRingHardwareDTO)
    } else {
        console.warn("skipping populating ring1/perimeter since the rows are missing from pasted data");
    }
    if (rows[51] != null) {
        const automationType = rows[51].split(/\t/)[1];
        twinSettings.automationType = automationType;
        console.log("set twinSettings automation type to: ", twinSettings.automationType);
    }

    twinSettings.hardware = {...existing.hardware, ...hardware as DesiredHardwareDTO};
    twinSettings.layout = {...existing.layout, ...layout as DesiredLayoutDTO};

    if (rows[54] != null) {
        const layersTopHeights = rows[54].split(/\t/);
        console.log("row 54 split results", layersTopHeights);
        layersTopHeights.shift();
        if (twinSettings.layout != null) {
            twinSettings.layout.layersTopHeight = layersTopHeights.filter((a) => a.length > 0).map(layerHeight => Number(layerHeight));
            console.log("set twinSettings.layout.layersTopHeight to: ",  twinSettings.layout.layersTopHeight);
        }
        else {
            console.log(" twinSettings.layout.layersTopHeight not updated since layout was null ");
        }
    }
    else {
        console.log("row 54 is null", rows[54]);
    }


    console.log("twin settings that will be updated", twinSettings);
    return twinSettings;
};

interface DeviceUpdateFromSheetProps {
    open: boolean,
    formRef: MutableRefObject<FormInstance<UnifiedDeviceConfigDTO> | null>,
    onOk: () => void,
    onCancel: () => void,
}

interface TwinSheetFormValues {
    text: string,
}

export const DeviceUpdateModal = (props: DeviceUpdateFromSheetProps) => {
    const formId = "twinFromSheet";

    const onFinish = useCallback((values: TwinSheetFormValues) => {
        try {
            const text = values.text;
            if (text == null || text?.length === 0) {
                console.log("no text entered / null. Skipping", text);
                return;
            }

            const existingValues = props.formRef.current?.getFieldsValue();
            const patch = updateTwinV2(existingValues!, text);
            console.log("the patch", patch);
            //todo: casting to any silenced the partial update error
            props.formRef.current?.setFieldsValue(patch as any);
            props.onOk?.();
        } catch (err) {
            console.log("form crash", err);
            message.error("Parsing error. Please post pasted text on Slack/ZenDesk");
        }

    }, [props.formRef, props.onOk]);

    return (<Modal title="Paste from Module Twin spreadsheet tab" visible={props.open} onCancel={props.onCancel} okText="Apply" okButtonProps={{ htmlType: "submit", form: formId }}>

        <Form scrollToFirstError id={formId} onFinish={onFinish} initialValues={{ text: "" }}>
            <Form.Item name="text" rules={[{type: "string", required: true, min: 1, message: "No text entered"}]}>
                <TextArea id={formId} autoSize={{ minRows: 4, maxRows: 10 }} wrap="off" />
            </Form.Item>
        </Form>
    </Modal>);
};