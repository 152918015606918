import * as React from 'react';
import AdminTabs from './AdminTabs';
import { RedirectAs404 } from 'src/utils/RouteErrors';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from '../shared/GlobalBreadcrumb';
import Routes from 'src/consts/Routes';
import RoleUtil from 'src/utils/RoleUtil';

const Admin = () => (
    <React.Fragment>
        <BreadcrumbsItem name="admin" to={Routes.generate(Routes.ADMIN)} iconName="document">Admin</BreadcrumbsItem>
        <Switch>
            <Route path={Routes.ADMIN + '/1'} render={(props) => <AdminTabs {...props} tab={1}/>} />
            <Route path={Routes.ADMIN + '/2'} render={(props) => <AdminTabs {...props} tab={2}/>} />
            <Route path={Routes.ADMIN + '/3'} render={(props) => <AdminTabs {...props} tab={3}/>} />

            <Route path={Routes.ADMIN + '/4'} render={(props) => <AdminTabs {...props} tab={4}/>} />
            <Route path={Routes.ADMIN + '/5'} render={(props) => <AdminTabs {...props} tab={5}/>} />
            <Route path={Routes.ADMIN + '/6'} render={(props) => <AdminTabs {...props} tab={6}/>} />
            <Route path="/admin" component={AdminTabs} />

            {/* This needs to be the last item */}
            <Route component={RedirectAs404} />
        </Switch>
    </React.Fragment>
);

export default Admin;
