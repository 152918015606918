import EnterpriseApiService from "src/api/EnterpriseApiService";
import { useQuery } from "@tanstack/react-query";
import { QueryTypes } from "./shared";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { ApiError } from "src/api/ApiResultHandler";
import { queryClient } from "src";
import BinInfoDTO from "src/models/BinInfoDTO";

export const useGetBinByIDQuery = (binId: number, options: QueryTypes = {}) => {
    const query = useQuery(binDBKeys.binInfo(binId), async (q) => {
        return await EnterpriseApiService.getBinByID(binId, q.signal);
    }, {
        enabled: options?.enabled,
        initialData: () => {
            const data = queryClient.getQueryData<BinInfoDTO[] | undefined>(binDBKeys.allBin());
            return data?.find(bin => bin.id === binId);

        },
        onError: (err: ApiError) => {
            console.log("bindb query error", err);
            if (typeof options?.onError === 'function') {
                options?.onError(err);
            }
        },
    });

    return query;
}