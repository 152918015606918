// Constants TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
enum BoardGroup {
    All = 0,
    Hub = 1,
    Fans = 2,
    StackBoards = 3,
    SensingBoards = 4,
    RHTBoards = 5,
    OPIBoards = 6,
}
export default BoardGroup;
