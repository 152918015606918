import { Alert, Button, ButtonProps, message, Popconfirm, Space, SpaceProps } from "antd";
import Typography from "antd/es/typography/Typography";
import React, { useEffect } from "react";
import BinApiService from "src/api/BinApiService";

interface RestartDristackModuleProps {
    deviceId: string | null | undefined;
    buttonProps?: ButtonProps;
    spaceProps?: SpaceProps;
}

interface RestartDeviceHookReturns {
    restartStatus: RestartStatus,
    restartModule: () => void,
}

enum RestartStatus {
    Restarting = "Restarting",
    Idle = "Idle",
    Success = "Success",
    Failed = "Failed",
}

// enum ReachableStatus {
//     Unchecked = 'Unchecked',
//     Pinging = "Pinging",
//     Unreachable = 'Unreachable',
//     Reachable = 'Reachable',
// }

// const useReachableDevice = (deviceId: string) => {
//     const [reachable, setReachable] = React.useState<ReachableStatus>(ReachableStatus.Unchecked);

//         // ping device for confirmation
//         setReachable(ReachableStatus.Pinging);
//         try {
//             const pingable = await BinApiService.pingDevice(deviceId);
//             if (pingable) {
//                 setReachable(ReachableStatus.Reachable);
//                 message.success("dristackmodule restarted and reachable");
//             }
//             else {
//                 setReachable(ReachableStatus.Unreachable);
//                 message.error("dristackmodule is not reachable");
//             }
//         } catch {
//             message.error("Unexpected error: dristackmodule may not be reachable");
//             setReachable(ReachableStatus.Unreachable);
//         }
// }

const useRestartDevice = (deviceId: string): RestartDeviceHookReturns => {
    const [restartStatus, setRestartStatus] = React.useState(RestartStatus.Idle);

    useEffect(() => {
        if (deviceId == null) {
            setRestartStatus(RestartStatus.Idle);
            return;
        }
        setRestartStatus(RestartStatus.Idle);
    }, [deviceId]);

    const restartModule = async () => {
        try {
            setRestartStatus(RestartStatus.Restarting);
            const restartResult = await BinApiService.restartDristackModule(deviceId);
            if (restartResult === true) {
                setRestartStatus(RestartStatus.Success);
                message.success("System is restarting, check back in 5 minutes.");
            }
            else {
                message.error("Failed to get confirmation that the system began restarting");
                setRestartStatus(RestartStatus.Failed);
            }
        } catch {
            message.error("Failed to get confirmation that the system began restarting. Unexpected server error");
            setRestartStatus(RestartStatus.Failed);
        }

    };
    

    return {restartStatus, restartModule };
}

export const RestartDriStackModuleButton = ({deviceId, buttonProps, spaceProps}: RestartDristackModuleProps) => {
    const {restartStatus, restartModule } = useRestartDevice(deviceId ?? "");

    let restartNotifcation = null;
    if (restartStatus === RestartStatus.Success) {
        restartNotifcation = <Alert type="success" showIcon closable message="Restarting system. Check back in 5 minutes."/>
    }
    else if (restartStatus === RestartStatus.Failed) {
        restartNotifcation = <Alert type="error" showIcon closable message="Failed to restart system"/>
    }

    return (
        <>

            <Space direction="vertical" {...spaceProps ?? {}}>
                <Popconfirm okText="Restart" cancelText="Close" title="Restart Software Controls?" description={<Typography>System will be unavailable for 5 minutes while it restarts.</Typography>} onConfirm={restartModule}>
                    <Button loading={restartStatus === RestartStatus.Restarting} type="primary" danger {...buttonProps ?? {}} >Restart Software Controls</Button>
                </Popconfirm>

                {restartNotifcation}
            </Space>
        </>
    );
}