import HeaterMode from "src/consts/HeaterMode";

export const formatHeaterMode = (heaterMode: HeaterMode) => {
    if (heaterMode == null) {
        return "--";
    }
    if (heaterMode === HeaterMode.Ambient) {
        return "Ambient";
    }
    else if (heaterMode === HeaterMode.EnergyEff) {
        return "Efficiency";
    }
    else if (heaterMode === HeaterMode.Speed5) {
        return "5 Deg";
    }
    else if (heaterMode === HeaterMode.Speed10) {
        return "10 Deg";
    }
    else {
        return "Unknown";
    }
}

export const formatPlenumPressure = (plenumPressure: number | null | undefined): string => {
    if (!Number.isFinite(plenumPressure)) {
        return "";
    }
    if (plenumPressure == null) {
        return "";
    }

    return plenumPressure.toFixed(2);
}
