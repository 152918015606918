// Constants TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
enum SensorType {
    Thermocouple = 'Thermocouple',
    OPI_RHT = 'OPI_RHT',
    PowerCast = 'PowerCast',
    Pressure = 'Pressure',
    Current = 'Current',
    RangeFinder = 'RangeFinder',
    CO2 = 'CO2',
    Legacy_RHT = 'Legacy_RHT',
    BinSense = 'BinSense',
}
export default SensorType;
