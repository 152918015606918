// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';
import DesiredRingHardwareDTO from './DesiredRingHardwareDTO';

interface DesiredHardwareDTO {
    hubBoardYear: number | null;
    fans: number;
    fanBoardsYear: number | null;
    stackBoardsYear: number | null;
    sensorBoardsCnt: number | null;
    sensorBoardsYear: number | null;
    opiMcBoardsCnt: number | null;
    highPoweredFans: boolean;
    rings: DesiredRingHardwareDTO[] | null;
    pneumaticBoardSolenoidCounts: number[];
}
const DesiredHardwareDTO: InterfaceConstructor<DesiredHardwareDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            hubBoardYear: null,
            fans: 0,
            fanBoardsYear: null,
            stackBoardsYear: null,
            sensorBoardsCnt: null,
            sensorBoardsYear: null,
            opiMcBoardsCnt: null,
            highPoweredFans: false,
            rings: [],
            pneumaticBoardSolenoidCounts: [],
        },
        initValues);
    }
};

export default DesiredHardwareDTO;
