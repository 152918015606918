import AdminApiService from "src/api/AdminApiService";
import { useQuery } from "@tanstack/react-query";
import SoftwareLookupDTO from "src/models/SoftwareLookupDTO";
import { QueryTypes } from "./shared";
import { queryClient } from "src";

export const useGetAllSoftwareLookupsQuery = (options:QueryTypes) => {
    return useQuery<SoftwareLookupDTO[]>(["getAllSoftwareLookupsQuery"],
        async (q) => {
            return await AdminApiService.getSoftwareLookups(q.signal);
        },
        {
            enabled: options?.enabled,
            refetchInterval: options?.refetchInterval,
            refetchOnWindowFocus: options?.refetchOnWindowFocus,
            initialData: () => {
                const data = queryClient.getQueryData<SoftwareLookupDTO[] | undefined>(["getAllSoftwareLookupsQuery"]);
                if(data != null){
                    return data as SoftwareLookupDTO[];
                }
                return [];
            },
        });
}