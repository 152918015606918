import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "antd";
import dayjs from "dayjs";
import { floor, round } from "lodash-es";
import React, { useState, useCallback, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { queryClient } from "src";
import BinApiService from "src/api/BinApiService";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { RangeValue } from "./ChartContainer";
import { ChartDatePicker } from "./Co2Chart";
import { compressorColor, tooltipItemRound, useFanHeaterChangeHistory } from "./shared";
import { BaseChartProps } from "./TemperatureCableChart";
import _uniqueId from 'lodash/uniqueId';
import { useModeChangeHistory } from "./LayerMCHistoryChart";

export const TargetLayerChart = (props: BaseChartProps & {shrinkHeight?: boolean}) => {

    const currentDate = dayjs();
    const priorDate = currentDate.subtract(7, 'days');
  
    const [legendId] = useState(_uniqueId('legend-'));
    const isControlled = typeof props.value != 'undefined';
    const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);
  
    // Internally, we need to deal with some value. Depending on whether
    // the component is controlled or not, that value comes from its
    // props or from its internal state.
  
    const value = isControlled ? props.value : internalValue;
  
    const onDateChange = useCallback((values: RangeValue, formatter: any) => {
      if (props.onChange) {
        props.onChange(values);
      }
      else {
        setIntervalValue(values);
      }
    }, [props.onChange]);
  
  
    const chartQuery = useQuery({
      queryKey: [...binDBKeys.bin(props.binId), "charts", "TargetLayer", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, keepPreviousData: true, retry: 0, queryFn: async (q) => {
        const now = value?.[1];
        const priorMonth = value?.[0];
        const results = await BinApiService.getTargetLayerChart(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
        queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
  
        return results;
      }, enabled: (props.binId ?? 0) > 0,
    });
  
    const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
    const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);


    return <div>
  
      {!isControlled && <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />}
      <Skeleton loading={chartQuery.isLoading}>
      <section style={{ width: "100%", minHeight: props.chartHeight ?? "200px"}}>
        {chartQuery.data && <Line options={{
          responsive: true,
          animation: false,
          maintainAspectRatio: false,
          layout: {
            padding: {
                top: 16,
                bottom: 16,
            },
          },
          datasets: {
            line: {

              pointRadius: 0 // disable for all `'line'` datasets
            }
          },
          scales: {
            x: {
              type: "time",
              time: {
                minUnit: 'day',
              },
            },
            y: {
            type: 'category',
            labels: chartQuery.data?.categories,
            ticks: {
              autoSkip: false,
              autoSkipPadding: 32
            },
            position: 'left',
              title: {
                font: {
                  size: 16,
                },
                display: true,
                text: "Target Layer",
              },
            },
          },
          // parsing: false,
          plugins: {
            annotation: {
              annotations: [
                ...modeHistory.annotations,
                ...fanHeaterChangeHistory.annotations,
              ],
            },
            legend: {
              position: 'top' as const,
              display: false,
            },
            title: {
              display: true,
              text: 'Target Layer',
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              usePointStyle: true,
              displayColors: false,
              callbacks: {
                title: () => '',
                footer(tooltipItems, ) {
                    const tooltipItem = tooltipItems[0];
                    const strings: Array<string> = [];

                    // @ts-ignore
                    const routines = tooltipItem.raw.routines;
                    strings.push(`Routine: ${routines}`)
                    // @ts-ignore
                    const steps = tooltipItem.raw.stepLabels;
                    strings.push(`    Steps: ${steps}`)

                    // @ts-ignore I know startDate is here
                    const startDate = dayjs(tooltipItem.raw.startDate);
                    strings.push(`Start: ${startDate.format('LLL')}`);
                    // @ts-ignore I know endDate is here
                    const endDate = dayjs(tooltipItem.raw.endDate);
                    strings.push(`End: ${endDate.format('LLL')}`);
                    // @ts-ignore I know durationSeconds is here
                    let targetDuration = dayjs.duration(tooltipItems[0]?.raw?.durationSeconds * 1000);
                    strings.push(`Duration: ${floor(targetDuration.asHours())} hrs, ${roundTo((targetDuration).minutes(), 0)} min`);

                    return strings.join('\n');
                },
              }
            },
          },
        }} data={{ datasets: [

        {
            data: chartQuery.data?.data, label: "Target Layer", indexAxis: 'x', yAxisID: 'y',
            parsing: {yAxisKey: 'targetLayer',},
            spanGaps: false,
            borderWidth: 8,
            },
        ] }} />}
      </section>
      </Skeleton>
    </div>
  
  };