// Service TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
import BaseApi from './BaseApi';
import BinAssemblySubmittalDTO from '../models/BinAssemblySubmittalDTO';
import BinAssemblyDTO from '../models/BinAssemblyDTO';
import PCBLookupDTO from '../models/PCBLookupDTO';
import CreateSoftwareLookupRequestDTO from '../models/CreateSoftwareLookupRequestDTO';
import UnifiedDeviceConfigDTO from '../models/UnifiedDeviceConfigDTO';
import ECDeviceListResponseDTO from '../models/ECDeviceListResponseDTO';
import ECDeviceDTO from '../models/ECDeviceDTO';
import SoftwareLookupDTO from '../models/SoftwareLookupDTO';
import BinInfoDTO from '../models/BinInfoDTO';

export class AdminApiService extends BaseApi {

    // get: api/admin/getallecdevices?offset=${offset}
    public getAllECDevices(offset: number, signal?: AbortSignal): Promise<ECDeviceListResponseDTO> {
        let url = `api/admin/getallecdevices`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (offset != null) {
            url += `${prefix}offset=${offset}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ECDeviceListResponseDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/admin/getecdevice?ecDeviceId=${encodeURIComponent(ecDeviceId)}
    public getECDevice(ecDeviceId: string, signal?: AbortSignal): Promise<ECDeviceDTO> {
        let url = `api/admin/getecdevice`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (ecDeviceId != null) {
            url += `${prefix}ecDeviceId=${encodeURIComponent(ecDeviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ECDeviceDTO>(null, 'get', url, true, false, _signal);
    }

    // post: api/admin/generateassembly
    public generateAssembly(submittalDTO: BinAssemblySubmittalDTO, signal?: AbortSignal): Promise<BinAssemblyDTO> {
        let url = `api/admin/generateassembly`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinAssemblyDTO>(submittalDTO, 'post', url, true, false, _signal);
    }

    // post: api/admin/createsubmittalfromtext?deviceId=${encodeURIComponent(deviceId)}
    public createSubmittalFromText(text: string, deviceId: string, signal?: AbortSignal): Promise<BinAssemblySubmittalDTO> {
        let url = `api/admin/createsubmittalfromtext`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinAssemblySubmittalDTO>(text, 'post', url, true, false, _signal);
    }

    // post: api/admin/uploadbinassembly
    public uploadBinAssembly(binAssemblyDTO: BinAssemblyDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/admin/uploadbinassembly`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(binAssemblyDTO, 'post', url, true, false, _signal);
    }

    // post: api/admin/deletepcblookup
    public deletePCBLookup(pcb: PCBLookupDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/admin/deletepcblookup`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(pcb, 'post', url, true, false, _signal);
    }

    // post: api/admin/uploadpcblookup
    public uploadPCBLookup(pcbEntry: PCBLookupDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/admin/uploadpcblookup`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(pcbEntry, 'post', url, true, false, _signal);
    }

    // post: api/admin/uploadsoftwarefile?softwareLookupId=${softwareLookupId}
    public uploadSoftwareFile(softwareLookupId: number, softwareFile: File, signal?: AbortSignal): Promise<void> {
        let url = `api/admin/uploadsoftwarefile`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (softwareLookupId != null) {
            url += `${prefix}softwareLookupId=${softwareLookupId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(softwareFile, 'post', url, true, false, _signal);
    }

    // post: api/admin/createsoftwarelookup
    public createSoftwareLookup(request: FormData, signal?: AbortSignal): Promise<void> {
        let url = `api/admin/createsoftwarelookup`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(request, 'post', url, false, false, _signal);
    }

    // get: api/admin/getsoftwarelookups
    public getSoftwareLookups(signal?: AbortSignal): Promise<SoftwareLookupDTO[]> {
        let url = `api/admin/getsoftwarelookups`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, SoftwareLookupDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/admin/getpcblookups
    public getPCBLookups(signal?: AbortSignal): Promise<PCBLookupDTO[]> {
        let url = `api/admin/getpcblookups`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, PCBLookupDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/admin/getallbins
    public getAllBins(signal?: AbortSignal): Promise<string[]> {
        let url = `api/admin/getallbins`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, string[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/admin/getallpremierbins
    public getAllPremierBins(signal?: AbortSignal): Promise<BinInfoDTO[]> {
        let url = `api/admin/getallpremierbins`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinInfoDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/admin/getbinassembly?azureDeviceId=${encodeURIComponent(azureDeviceId)}
    public getBinAssemblySubmittal(azureDeviceId: string, signal?: AbortSignal): Promise<BinAssemblySubmittalDTO> {
        let url = `api/admin/getbinassembly`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (azureDeviceId != null) {
            url += `${prefix}azureDeviceId=${encodeURIComponent(azureDeviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinAssemblySubmittalDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/admin/getbin?azureDeviceId=${encodeURIComponent(azureDeviceId)}&useDeviceTwin=${useDeviceTwin}
    public getBinByID(azureDeviceId: string, useDeviceTwin: boolean, signal?: AbortSignal): Promise<UnifiedDeviceConfigDTO> {
        let url = `api/admin/getbin`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (azureDeviceId != null) {
            url += `${prefix}azureDeviceId=${encodeURIComponent(azureDeviceId)}`;
            prefix = '&';
        }
        if (useDeviceTwin != null) {
            url += `${prefix}useDeviceTwin=${useDeviceTwin}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UnifiedDeviceConfigDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/admin/getDeviceConnectionString?deviceName=${encodeURIComponent(deviceName)}
    public getDeviceConnectionString(deviceName: string, signal?: AbortSignal): Promise<string> {
        let url = `api/admin/getDeviceConnectionString`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, string>(null, 'get', url, true, false, _signal);
    }

    // post: api/admin/setbin?deviceName=${encodeURIComponent(deviceName)}&useDeviceTwin=${useDeviceTwin}
    public setBin(deviceName: string, useDeviceTwin: boolean, desiredProperties: UnifiedDeviceConfigDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/admin/setbin`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }
        if (useDeviceTwin != null) {
            url += `${prefix}useDeviceTwin=${useDeviceTwin}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(desiredProperties, 'post', url, true, false, _signal);
    }
}
var service = new AdminApiService();
export default service;
