import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "antd";
import React from "react";
import { Line } from "react-chartjs-2";
import BinApiService from "src/api/BinApiService";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { formatNumber } from "../BinStatusPage/HeaterControls";
import { ambientFanDatasetColor, plenumFanDatasetColor } from "./AmbientPlenumChart";
import { useModeChangeHistory } from "./LayerMCHistoryChart";
import { tooltipItemRound, useFanHeaterChangeHistory } from "./shared";
import { BaseChartProps } from "./TemperatureCableChart";
import { sortedIndex, sortedLastIndexBy, sortedLastIndex, sortedIndexBy } from "lodash";


export const RangeFinderChart = (props: BaseChartProps & { shrinkHeight?: boolean }) => {
    const queryClient = useQueryClient();
    const value = props.value;

    const chartQuery = useQuery({
        queryKey: [...binDBKeys.bin(props.binId), "charts", "RangeFinderChart", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, keepPreviousData: true, retry: 0, queryFn: async (q) => {
            const now = value?.[1];
            const priorMonth = value?.[0];
            const results = await BinApiService.getGrainHeightsHistory(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);

            queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });

            return results;
        }, enabled: (props.binId ?? 0) > 0,
    });

    const chartQueryUser = useQuery({
        queryKey: [...binDBKeys.bin(props.binId), "charts", "RangeFinderChartUser", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, keepPreviousData: true, retry: 0, queryFn: async (q) => {
            const now = value?.[1];
            const priorMonth = value?.[0];
            const results = await BinApiService.getGrainHeightsHistoryUserEntered(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
            queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });

            return results;
        }, enabled: (props.binId ?? 0) > 0,
    });

    const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
    const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);

    return <>

        <Skeleton loading={chartQuery.isLoading}>
            <section style={{ width: "100%", minHeight: props.chartHeight ?? "50vh" }}>
                {chartQuery.data && <Line options={{
                    scales: {
                        x: {
                            type: "time",
                            min: value?.[0]?.toISOString(),
                            time: {
                                minUnit: 'day',
                            },
                        },
                        y: {
                            type: 'linear',
                            position: 'left',
                            min: 0,
                            max: 100,
                            ticks: {
                                precision: 0,
                                stepSize: 5,
                            },
                            title: {
                                font: {
                                    size: 16,
                                },
                                display: true,
                                text: "Fill Level %",
                            },
                        },
                    },
                    plugins: {
                        annotation: {
                            annotations: [
                                ...modeHistory.annotations,
                                ...fanHeaterChangeHistory.annotations,
                            ],
                        },
                        legend: {
                            position: 'top' as const,
                            display: true,
                        },
                        title: {
                            display: true,
                            text: 'Fill Level',
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                label: (tooltipItem) => '',
                                footer: (context) => {
                                    const item = context[0];

                                    // @ts-ignore
                                    const percentFullCalculated = item?.raw?.percentFullCalculated;
                                    // @ts-ignore
                                    const bushelsFull = item?.raw?.bushelsFull;
                                    var bushelsFormatted = (new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 })).format(bushelsFull);
                                    const strings = [];
                                    strings.push(`Headspace Sensor: ${formatNumber(percentFullCalculated, { suffix: "%", decimalPlaces: 0, filler: "" })} (${bushelsFormatted} bu.)`);

                                    // hack to get get access to datasets in tooltips that don't share the same x-value & frequency
                                    const loadsDataset = item.chart.data.datasets[1]?.data;
                                    const closestUserLoadIndex = sortedLastIndexBy(loadsDataset,
                                        item.raw,
                                        // @ts-ignore
                                        (load) => load.x);

                                    const getClosestLoadFromNearestIndex = () => {
                                        if (loadsDataset == null) {
                                            return undefined;
                                        }
                                        if (loadsDataset.length === 0) {
                                            return 0;
                                        }
                                        return loadsDataset?.[closestUserLoadIndex - 1];

                                    }
                                    const closestLoad = getClosestLoadFromNearestIndex();
                                    // @ts-ignore
                                    const percentFullUser = closestLoad?.percentFullUser;
                                    // @ts-ignore
                                    const bushelsFullUser = closestLoad?.bushelsFullUser;
                                    var bushelsFormattedUser = (new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 })).format(bushelsFullUser);

                                    strings.push(`User Entered: ${formatNumber(percentFullUser, { suffix: "%", decimalPlaces: 0, filler: "" })} (${bushelsFormattedUser} bu.)`);

                                    return strings.join('\n');
                                }
                            }
                        },
                    },
                }} data={{
                    datasets: [

                        {
                            type: 'line',
                            data: chartQuery.data ?? [], label: "Headspace Sensor", indexAxis: 'x',
                            parsing: { yAxisKey: 'percentFullCalculated', },
                            spanGaps: true,
                        },
                        {
                            type: 'line',
                            // @ts-ignore - different structure than 1st dataset
                            data: chartQueryUser.data ?? [],
                            label: "User Entered", indexAxis: 'x',
                            parsing: {
                                yAxisKey: 'percentFullUser',
                            },
                            spanGaps: true,
                            stepped: true,
                            backgroundColor: plenumFanDatasetColor,
                            borderColor: plenumFanDatasetColor,
                        }
                    ]
                }} />}
            </section>
        </Skeleton>

    </>;
}