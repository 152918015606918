import { useQuery } from "@tanstack/react-query";
import { Table } from "antd";
import dayjs from "dayjs";
import React from "react";
import ShivversService from "src/api/ShivversService";
import SettingsDTO from "src/models/SettingsDTO";
import { binDBKeys, ExtendedColumnType, useColumnExpansion } from "src/pages/binOverview/BinCommander";
import { useBinInfoContext } from "src/queries/BinInfoContext";


export const GetShivversDrySettings = async (ip: string, signal?: AbortSignal) => {

    return await ShivversService.settings(ip, signal);
}
export const SettingsTable = () => {

    const binInfo = useBinInfoContext();

    const query = useQuery(
        {
            queryKey: [...binDBKeys.shivversBin(binInfo.linkIpAddress!), "dryerSettings"],
            queryFn: async (q) => GetShivversDrySettings(q.queryKey[1] as string, q.signal),
        });
    const columns: Array<ExtendedColumnType<Awaited<ReturnType<typeof GetShivversDrySettings>>[0]>> = [
        {
            title: "Date",
            dataIndex: "dateUtc",
            width: "180px",
            render(value, record, index) {
                return dayjs(value).format('hh:mm A MM/DD/YYYY');
            },
        },
        {
            title: "Max_Temp",
            dataIndex: "max_Temp",
        },
        {
            title: "Min_Temp",
            dataIndex: "min_Temp",
        },
        {
            title: "Target",
            dataIndex: "target",
        },
        {
            title: "Plenum_Cal",
            dataIndex: "plenum_Cal",
        },
        {
            title: "Grain_Cal",
            dataIndex: "grain_Cal",
        },
        {
            title: "Transfer_Mst",
            dataIndex: "transfer_Mst",
        },
        {
            title: "Moisture_Cal",
            dataIndex: "moisture_Cal",
        },
];

    const highlightSettingChanges = (exclusions: Array<string>) => {
        for (const col of columns) {
            if (typeof col.dataIndex !== 'string' || col.dataIndex == null) {
                continue;
            }

            if (exclusions.includes(col.dataIndex)) {
                continue;
            }

            col.onCell = (data, index) => {
                if (query.data == null || query.data.length === 0) {
                    return {};
                }

                const dataIndex = col.dataIndex! as keyof SettingsDTO;

                const item = data;
                const currentItem = item[dataIndex!]?.trim();
                const previousItem = query.data[index! - 1]?.[dataIndex]?.trim();
                const nextItem = query.data[index! + 1]?.[dataIndex]?.trim();

                const validNext = index! + 1 !== query.data.length;
                const validPrevious = index !== 0;

                // note: this doesn't work when reversing data with highlighting for ascending order
                // need a way to tell current sort order (usestate / ref / hook)
                const equal = !(validPrevious && currentItem !== previousItem) || (validNext && currentItem !== nextItem);

                if (equal) {
                    return {};
                }
                return {
                    style: {
                        backgroundColor: "red",
                        color: "white",
                    }
                }
            }
        }
    }
    highlightSettingChanges(["dateUtc"]);

    const transformedColumns = useColumnExpansion({
        columns: columns, datasource: query.data ?? []
    })

    return <Table sticky size="small"  pagination={{position: ["none", "none"], defaultPageSize: 100}} dataSource={query?.data ?? []} columns={transformedColumns} />
}