// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';
import EnterpriseCropDataDTO from './EnterpriseCropDataDTO';
import GrowerDTO from './GrowerDTO';
import BinInfoDTO from './BinInfoDTO';

interface EnterpriseDTO {
    id: number;
    allCropData: EnterpriseCropDataDTO[] | null;
    grower: GrowerDTO | null;
    binInfoList: BinInfoDTO[] | null;
}
const EnterpriseDTO: InterfaceConstructor<EnterpriseDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            id: 0,
            allCropData: [],
            grower: null,
            binInfoList: [],
        },
        initValues);
    }
};

export default EnterpriseDTO;
