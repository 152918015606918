import * as React from 'react';
import AccountApiService from 'src/api/AccountApiService';
import Routes from 'src/consts/Routes';
import {
    Alert,
    Button,
    Col,
    Form,
    FormInstance,
    Input,
    Layout,
    Row,
    Spin,
    Typography
    } from 'antd';
import { BreadcrumbsItem } from '../shared/GlobalBreadcrumb';
import { Link, RouteComponentProps } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

const FormItem = Form.Item;
const Content = Layout.Content;

interface ResetPasswordState {
    error: any;
    resetSuccess: boolean;
    linkVerified: boolean | null;
    loading: boolean;
}

interface ResetPasswordProps {
}

class ResetPassword extends React.Component<ResetPasswordProps & RouteComponentProps<{}>, ResetPasswordState> {
    private readonly _formRef = React.createRef<FormInstance>();

    constructor(props: ResetPasswordProps & RouteComponentProps<{}>) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            error: null,
            resetSuccess: false,
            linkVerified: null,
            loading: false
        };
    }

    componentDidMount() {
        let model = { url: location.href } as any;
        AccountApiService.canResetPassword(model).
            then(
                () => {
                    this.setState({ loading: false, error: null, linkVerified: true });
                },
                (error) => {
                    this.setState({ loading: false, error: error, linkVerified: false });
                }
            );
    }
    handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ loading: true });

        let model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
        model.url = location.href;
        AccountApiService.resetPassword(model).
            then(
                () => {
                    this.setState({ loading: false, resetSuccess: true, error: null });
                },
                (error) => {
                    this.setState({ loading: false, error: error, resetSuccess: false });
                }
            );
    }

    render() {
        let alert: JSX.Element | null = null;
        let { error, loading, resetSuccess, linkVerified } = this.state;
        if (error !== null) {
            let message = (<span>An error occurred while resetting your password.</span>);
            if (error.message) {
                message = error.message.split('\n').map((s: string) => (<span key={s}>{s}<br /></span>));
            }
            alert = (
                <Alert
                    message="Error"
                    description={message}
                    type="error"
                    showIcon={true}
                    style={{ marginBottom: '12px' }}
                />);
        }
        if (resetSuccess) {
            let message = (<span>Password was successfully reset. Please click <Link to={Routes.LOGIN}>HERE</Link> to login.</span>);
            alert = (
                <Alert
                    message="Success"
                    description={message}
                    type="success"
                    showIcon={true}
                    style={{ marginBottom: '12px' }}
                />);
        }

        let labelCol = { xs: 24, sm: 6, md: 6, lg: 7, xl: 7, xxl: 7 };
        let wrapperCol = { xs: 24, sm: 17, md: 17, lg: 17, xl: 17, xxl: 17 };
        let wrapperColNoLabel = {};
        Object.keys(wrapperCol).forEach((val) => {
            wrapperColNoLabel[val] = { span: wrapperCol[val], offset: (wrapperCol[val] + labelCol[val] > 24 ? 0 : labelCol[val]) };
        });

        return (
            // type="flex"
            <Content className="content-background-login">
                <BreadcrumbsItem name="reset_password">
                    Reset Password
                </BreadcrumbsItem>
                {linkVerified == null ?
                    (
                        <Spin tip="Checking Link..." size="large">
                            <div style={{ height: 150 }} />
                        </Spin>
                    ) :
                    (
                        <Row justify="space-between">
                            <Col className="flex-grow" />
                            <Col md={20} sm={24} xs={24}>
                                <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Typography.Title level={2}>
                                        Reset Password
                                    </Typography.Title>
                                    {alert}
                                    {linkVerified ?
                                        (
                                            <React.Fragment>
                                                <p>Please enter new password.</p>
                                                <Form ref={this._formRef} layout="horizontal" onFinish={this.handleSubmit}>
                                                    <Form.Item
                                                        name="password"
                                                        label="Password"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your password!',
                                                            },
                                                        ]}
                                                        labelCol={labelCol} wrapperCol={wrapperCol}>
                                                        <Input.Password />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="confirm"
                                                        label="Confirm Password"
                                                        dependencies={['password']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Confirm Password is required',
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(rule: any, value: string | null) {
                                                                    if (!value || getFieldValue('password') === value) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject('The two passwords that you entered do not match');
                                                                },
                                                            }),
                                                        ]}
                                                        labelCol={labelCol} wrapperCol={wrapperCol}>
                                                        <Input.Password />
                                                    </Form.Item>
                                                    <FormItem wrapperCol={wrapperColNoLabel}>
                                                        <Button type="primary"
                                                            htmlType="submit"
                                                            style={{ width: '100%' }}
                                                            size="large"
                                                            disabled={loading}>
                                                            {loading ? (<span><LoadingOutlined /> Processing...</span>) : <span>Reset Password</span>}
                                                        </Button>
                                                    </FormItem>
                                                </Form>
                                            </React.Fragment>
                                        ) :
                                        null
                                    }
                                </div>
                            </Col>
                            <Col className="flex-grow" />
                        </Row>
                    )}
            </Content>
        );
    }
}

export default withRouter(ResetPassword);
