import { combineReducers } from 'redux';
import { UserSession } from './UserSessionReducer';
import { Version } from './VersionReducer';
import { StateStoreModel } from '../state/StateStoreModel';
import { AnyAction } from 'redux';

const rootReducer = combineReducers<StateStoreModel>({
    UserSession,
    Version
});

export default function freezeState(state: StateStoreModel, action: AnyAction): StateStoreModel {
    let nextState = rootReducer(state, action);
    return Object.isFrozen(nextState) ? nextState : Object.freeze(nextState);
}
