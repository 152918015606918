// Constants TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
enum GrainType {
    Corn = 'Corn',
    Soybeans = 'Soybeans',
    Wheat = 'Wheat',
    WheatDurum = 'WheatDurum',
    HardSpringWheat = 'HardSpringWheat',
    HardWinterWheat = 'HardWinterWheat',
    SoftWinterWheat = 'SoftWinterWheat',
    Rye = 'Rye',
    LongGrainRoughRice = 'LongGrainRoughRice',
    MediumGrainRoughRice = 'MediumGrainRoughRice',
    ShortGrainRoughRice = 'ShortGrainRoughRice',
    Sorghum = 'Sorghum',
    Barley = 'Barley',
    Oats = 'Oats',
    Sunflower = 'Sunflower',
    Canola = 'Canola',
}
export default GrainType;
