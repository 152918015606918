import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Col, Form, InputNumber, Row, Space, Tag, Typography, message, Tooltip, Flex, Popover, ConfigProvider, Badge, Input, Modal, Descriptions, Spin } from "antd"
import { useForm } from "antd/es/form/Form";
import { delay, round } from "lodash";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import BinApiService from "src/api/BinApiService";
import BinDTO from "src/models/BinDTO";
import LayerPercentageDTO from "src/models/LayerPercentageDTO";
import { formatNumber } from "./HeaterControls";
import { CheckCircleOutlined, EditFilled, EditOutlined, SaveOutlined } from "@ant-design/icons";
import TemperatureSensorEnum from "src/consts/TemperatureSensorEnum";
import { getDefaultTemperatureSensorType } from "src/pages/shared/binDTOUtils";
import { CheckFailedIcon, CheckPassedIcon } from "src/pages/features/WeatherMonitorAntdOnly";
import { grainHeightOverridesPresent, useBinDTOContext } from "src/queries/BinDTOContext";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { deviceQueryKeys } from "../HomeScreen/BinDetails";
import { useDebounce } from 'use-debounce'; // You'll need to install this package: `npm install use-debounce`
import { useUploadBinStateToAzure } from "src/queries/useUploadBinStateToAzure";
import _ from "lodash";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { useForceNewBinStateUploadContext } from "./ForceNewBinStateUploadContext";
import { RefreshButton } from "./BinStatsPage";
import { useBinInfoContext } from "src/queries/BinInfoContext";

export const useDryLayerPercentAdjustMutation = (deviceId: string) => {
    return useMutation({
        mutationFn: async (params: {layerPercentages: LayerPercentageDTO[]}) => {
            return await BinApiService.setLayersTimePercentages(deviceId, params.layerPercentages);
        },
    })
}

const StatTagColor = "rgb(254,213,64)";
const activeLayerTagColor = "rgb(64,131,255)";

interface DryLayerAdjustProps {
    binDTO: BinDTO;
    deviceId: string;
}

export interface LayerAdjustment {
    percentage: number,
    number: number,
}
export interface FormValues {
    layers: LayerAdjustment[],
}

interface DryLayerRowProps {
    binDTO: BinDTO,
    layerNumber: number,
    fieldName: number,
}

export interface LayerFormatProps {
    binDTO: BinDTO;
    layerNumber: number;
}

export const LayerFormat = (props: LayerFormatProps) => {

    const maxLayers = props.binDTO?.layersAir?.length ?? 0;
    const isTopLayer = maxLayers === props.layerNumber;
    const isBottomLayer = props.layerNumber === 1;
    const targetLayer = props.binDTO?.routineEngine?.targetLayer;

    const layerInProgress = targetLayer == null && props.binDTO?.manualRoutineSettings?.targetLayer === props.layerNumber;

    let additionalLayerText = "";
    if (isTopLayer) {
        additionalLayerText = " (top valves)";
    }
    if (isBottomLayer) {
        additionalLayerText = " (bin floor)";
    }

    const layerInfo = props.binDTO?.layerGrainStates?.find(layer => layer.number == props.layerNumber);

    return (
        <Space wrap direction="horizontal">
        <Typography style={{color: "inherit"}}>Layer {props.layerNumber}{additionalLayerText}</Typography>

        {/* Search the layer Heights form LayerGrainState.bottom and .top HeightFromFloor */}
        <Tag>{formatNumber(layerInfo?.bottom?.heightFromFloor, {filler: "", decimalPlaces: 0})} - {formatNumber(layerInfo?.top?.heightFromFloor, {filler: "", decimalPlaces: 0})} ft</Tag>
        
        {layerInProgress && <Tag color="blue-inverse" icon={<CheckCircleOutlined spin />}>Processing</Tag> }
        {targetLayer === props.layerNumber && <Tag color={activeLayerTagColor}>CURRENTLY ACTIVE</Tag>}
        {false && <Typography style={{color: "inherit"}}>HH:MM remaining</Typography>}
    </Space>
    );
}
export interface GrainLayerSummaryInfoProps {
    binDTO: BinDTO;
    layerNumber: number;
}
export const GrainLayerSummaryInfo = (props: GrainLayerSummaryInfoProps) => {
    const preferredTemperatureSensorType = props.binDTO?.temperatureSensorsType ?? getDefaultTemperatureSensorType(props.binDTO);
    const layerHasGrain = props.binDTO?.layerGrainStates?.[props.layerNumber - 1]?.hasGrain ?? false;
    const layer = props.binDTO?.layerGrainStates?.[props.layerNumber - 1];
    const grainTemp = formatNumber([TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast, TemperatureSensorEnum.BinSense].includes(preferredTemperatureSensorType) ? layer?.temperatureF : layer?.thermocoupleTemperatureF , {decimalPlaces:0, filler:"--"})
    const grainRH = formatNumber(layer?.relitiveHumidity, {decimalPlaces: 0, filler:"--", suffix:"%"});
    const grainMc  = formatNumber(layer?.moistureContent, {decimalPlaces:1, filler:"--", suffix:"%"});

    return (
        <ConfigProvider theme={{
            components: {
                Popover: {
                    colorBgElevated: "rgba(0, 0,0,0.85)",
                    colorText: "#ffffff",
                }
            }
        }}>
                           <Space direction="horizontal" size="small" >
                                <Tooltip title="Average of moisture cable readings for this layer">
                                    <Tag color={StatTagColor} style={{ color: "black" }}>
                                        <Typography.Text strong>MC: {grainMc}</Typography.Text>
                                    </Tag>
                                </Tooltip>
                                <Tooltip title="Average of temperature cable readings for this layer">
                                    <Tag color={StatTagColor} style={{ color: "black" }}>
                                        <Typography.Text strong>Temp: {grainTemp}°F</Typography.Text>
                                    </Tag>
                                </Tooltip>
                                <Tooltip title="Average of the relative humidity readings (from sensors on the moisture cables) for this layer">
                                    <Tag color={StatTagColor} style={{ color: "black" }}>
                                        <Typography.Text strong>RH: {grainRH}</Typography.Text>
                                    </Tag>
                                </Tooltip>
                                {layerHasGrain &&                                 
                                <Popover trigger={['hover', 'focus', 'click']} content={<div>
                                        <span>This layer qualifies for drying based on the grain height.</span>
                                    </div>}
                                    >
                                    <Badge offset={[-12, 4]} size="small" count={<CheckPassedIcon />}>
                                        <Tag color={StatTagColor} style={{ color: "black" }}>
                                            <Typography.Text strong>Grain</Typography.Text>
                                        </Tag>
                                    </Badge>
                                </Popover>}
                                {!layerHasGrain &&                                 
                                <Popover trigger={['hover', 'focus', 'click']} content={<div>
                                    <span>This layer DOES NOT qualify for drying based on the grain height.</span>
                                </div>}>
                                        <Badge offset={[-12, 4]} size="small" count={<CheckFailedIcon />}>
                                            <Tag color={StatTagColor} style={{ color: "black" }}>
                                                <Typography.Text strong>No Grain</Typography.Text>
                                            </Tag>
                                        </Badge>
                                </Popover>}    
                            </Space>
                    </ConfigProvider>
    );
}

const DryLayerRow = (props: DryLayerRowProps) => {

    const maxLayers = props.binDTO?.layersAir?.length ?? 0;

    const placeholderValue = round(100 / maxLayers, 1);

    const formContext = useFormInstance();

    // https://chatgpt.com/share/66fc6284-5b7c-8005-8c00-75f0d46ef214  How to validate form.List on input change
    const validateListOnBlur = async () => {
          await formContext.validateFields(['layers']);
      };

    return <>

        <Row align="middle" gutter={[0, 8]} className="dry-layer-adjust-row">
            <Col xs={24}>
                <Card size="small" bodyStyle={{paddingTop: "8px", paddingBottom: "8px"}} >
                    <Row gutter={[0, 8]} align='middle'>
                        <Col xs={8}>
                            <LayerFormat binDTO={props.binDTO} layerNumber={props.layerNumber} />
                        </Col>
                        <Col xs={2} md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <GrainLayerSummaryInfo binDTO={props.binDTO} layerNumber={props.layerNumber} />
                        </Col>
                        <Col xs={10} md={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Form.Item hidden name={[props.fieldName, "number"]}>
                            </Form.Item>
                                <Form.Item name={[props.fieldName, "percentage"]} validateTrigger={['onBlur', 'onFocus', 'onInput']}
                                 rules={[{
                                    validator(rule, value, callback) {
                                        const sourceLayer = props.binDTO?.layers?.find(sourceLayer => sourceLayer.number == props.layerNumber);
                                        const layerPassesGrainCheck = !(!sourceLayer?.hasGrain && value > 0);
                                        if (!layerPassesGrainCheck) {
                                            return Promise.reject(new Error(`Doesn't qualify`));
                                        }
                                        else {
                                            return Promise.resolve();
                                        }
                                    },
                                }]}>
                                    <InputNumber required style={{width: "16ch"}} addonAfter="%" min={0} max={100} placeholder={`${placeholderValue}%`}
                                        onBlur={validateListOnBlur}
                                    ></InputNumber>
                                </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </>;
}

interface LayerAdjustmentListProps {
    binDTO: BinDTO,
}

export const LayerAdjustmentList = (props: LayerAdjustmentListProps) => {

    return <>

<section>
                <Form.List name="layers"
                    rules={[
                        {
                            validator: async (_, names) => {
                                console.log("validation names", names);

                                const formatLayersGrainCheckErrorMessage = (layers: Array<LayerPercentageDTO>): string => {
                                    const isPlural = layers.length > 1;
                                    const formattedLayers = layers.map(layer => `L${layer.number}`).join(", ");
                                    if (isPlural) {
                                        return `Layers ${formattedLayers} do not qualify for drying. Enter 0% to skip, or manually set grain heights to override the headspace sensor readings.`;
                                    }
                                    else {
                                        return `Layer ${formattedLayers} does not qualify for drying. Enter 0% to skip, or manually set grain heights to override the headspace sensor readings.`;
                                    }
                                }

                                const layersThatFailGrainCheck = (names as LayerPercentageDTO[]).flatMap((layer: LayerPercentageDTO) => {
                                    const sourceLayer = props.binDTO?.layers?.find(sourceLayer => sourceLayer.number == layer.number);
                                    if (sourceLayer == null) {
                                        return [];
                                    }

                                    if (!sourceLayer.hasGrain && layer.percentage > 0 ) {
                                        return [layer];
                                    }
                                    return [];
                                }
                                );
                                const passedGrainCheck = layersThatFailGrainCheck.length === 0;
                                if (!passedGrainCheck) {
                                    const layersFormatted = formatLayersGrainCheckErrorMessage(layersThatFailGrainCheck);
                                    return Promise.reject(new Error(layersFormatted));
                                }

                                let sum = 0;
                                for (const layer of names) {
                                    sum += layer.percentage;
                                }

                                if (sum <= 99 || sum >= 100.5) {
                                    return Promise.reject(new Error(`Combined time allocation must be 100%. It was ${round(sum, 2)}%`));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => {

                        return (<>

                            
                            <Space direction="vertical" size="small" style={{display: "flex", flexDirection: "column"}}>
                                {[...fields].reverse().map((field) => (
                                    <React.Fragment key={field.key}>
                                        <DryLayerRow binDTO={props.binDTO} layerNumber={field.key + 1} fieldName={field.name} />
                                    </React.Fragment>
                                ))}
                            </Space>

                            <Form.Item>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                    <Form.ErrorList errors={errors} />
                                </div>
                            </Form.Item>

                        </>);
                    }
                    }
                </Form.List>

            </section>
    </>;
}

export const DryLayerAdjustHelpText = () => {

    return (
        <Typography.Paragraph>Enter the percentage of time you would like each layer to be targeted with airflow. Set to 0% to skip a layer. Air will be directed layer by layer, starting from the top and proceeding to the bottom of the bin.</Typography.Paragraph>
    );
}


export const useUserOverrideGrainHeightMutation = (binId: number) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (params: {eaveHeightFt: number | null, peakHeightFt: number | null}) => {
            const result = await BinApiService.overrideGrainHeight(binId, params.eaveHeightFt, params.peakHeightFt);
            return { success: result };
        }
    });
};

// uses a newly passed context to force a new binstate upload
export const RefreshDryLayerTargetButton = () => {

    const refreshContext = useForceNewBinStateUploadContext();

    return <RefreshButton refresh={refreshContext.refresh} loading={refreshContext.isLoading} disabled={refreshContext.isLoading} />;
}

export const UserGrainHeights = (props: {}) => {

    const { binDTO } = useBinDTOContext();
    const grainHeightOverridesPresentValue = grainHeightOverridesPresent(binDTO!);

    return <>
        <Space direction="horizontal" size="small">
            {grainHeightOverridesPresentValue && <Tag color="red">Override Active</Tag>}
            <RefreshDryLayerTargetButton />
            <Typography.Text strong>Grain Heights</Typography.Text>
            <EditGrainHeights />
        </Space>
        <GrainHeightText binDTO={binDTO!} />
    </>;
}

interface UserGrainHeightForm {
    peakHeightFt: number | null;
    eaveHeightFt: number | null;
}

const userOverrideGrainHeightsFormId = "userOverrideGrainHeightsFormId";

interface EditUserGrainHeightsProps {
}

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };  
export const EditGrainHeights = (props: EditUserGrainHeightsProps) => {
    const { binDTO } = useBinDTOContext();
    const queryClient = useQueryClient();
    const grainHeightMutation = useUserOverrideGrainHeightMutation(binDTO?.id!);
    const [showEditHeights, setShowEditHeights] = useState(false);

    const uploadNewBinStateMutation = useUploadBinStateToAzure(binDTO?.deviceId!);

    const [form] = useForm<UserGrainHeightForm>();

    const values = Form.useWatch([], { form, preserve: true });

    // todo: delayed values aren't working with use-debounce with Form.useWatch
    const delayedValues = values;
    
    const grainQuery = useQuery({
        queryKey: binDBKeys.calculateGrainHeight(binDTO?.id!, {eaveHeightFt: delayedValues?.eaveHeightFt, peakHeightFt: delayedValues?.peakHeightFt }),
        queryFn: async (q) => await BinApiService.calculateGrainHeight(binDTO?.id!, delayedValues?.eaveHeightFt, delayedValues?.peakHeightFt, q.signal),
        enabled: delayedValues?.eaveHeightFt != null || delayedValues?.peakHeightFt != null,
    })

    const qualifyingLayers = (peakHeight: number): number[] => {
        const qualifyingLayers = binDTO?.layers?.flatMap((layer, index, array) => {
            if (layer.bottom == null || layer.top == null) {
                return [];
            }

            const isTopLayer = array[array.length - 1].number === layer.number;
            if (isTopLayer) {
                // taken from ValveConfig.IsInGrain
                const qualifies = (layer.bottom.heightFromFloor + 3) <= peakHeight;
                if (qualifies) {
                    return [layer.number];
                }
                else {
                    return [];
                }
            }

            const halfway = (layer.bottom?.heightFromFloor + layer.top?.heightFromFloor) / 2;
            if (peakHeight >= halfway) {
                return [layer.number];
            }
            else {
                return [];
            }
        })
        return qualifyingLayers ?? [];
    }

    const grainHeightOverridesPresentValue = grainHeightOverridesPresent(binDTO!);

    const handleSubmit = (values: UserGrainHeightForm) => {

        grainHeightMutation.mutate({eaveHeightFt: values.eaveHeightFt, peakHeightFt: values.peakHeightFt},
            {
                onSuccess(data, variables, context) {
                    // force IoT to upload a new state since the overrides update the grainHeights
                    delay(() => {
                        uploadNewBinStateMutation.mutate();
                    }, 8_000);
                    message.success("Grain height overrides saved. Grain heights will update in 10-15 seconds");
                    setShowEditHeights(false);
                },
                onError(error, variables, context) {
                    console.error("Problem overriding grain height", error, "values: ", values);
                    message.error("Problem overriding grain height. Try again.");
                },
            }
        )
    }

    const handleCancel = () => {
        setShowEditHeights(false);
      };

    const onClickClearOverrides = () => {
        grainHeightMutation.mutate({eaveHeightFt: null, peakHeightFt: null}, {
            onSuccess(data, variables, context) {
                // force IoT to upload a new state since the overrides update the grainHeights
                delay(() => {
                    uploadNewBinStateMutation.mutate();
                }, 8_000);
                message.success("Grain height overrides cleared. Grain heights will update in 10-15 seconds");
                setShowEditHeights(false);
            },
            onError(error, variables, context) {
                console.error("Problem clearing grain height overrides.", error, "values: ", values);
                message.error("Problem clearing  grain height overrides. Try again.");
            },
        });
    }

    useLayoutEffect(() => {
        if (showEditHeights) {
            console.log("updating form values to", defaultValues);
            form.setFieldsValue(defaultValues);
        }
    }, [form, showEditHeights])

    const defaultValues = {
        eaveHeightFt: grainHeightOverridesPresentValue ? binDTO?.grain?.heightAtEaves ?? null : null,
        peakHeightFt: grainHeightOverridesPresentValue ? binDTO?.grain?.heightAtPeak ?? null : null,
    };

    return <>
    <Button onClick={() => {
            setShowEditHeights(true);
        }} icon={<EditOutlined />}></Button>

        <Modal title="Grain Height Override" 
        open={showEditHeights}
        onClose={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" danger key="clearOverrides" loading={grainHeightMutation.isLoading}
          disabled={grainHeightMutation.isLoading} onClick={onClickClearOverrides}>Clear Overrides</Button>,
          <Button key="Cancel" onClick={handleCancel}>Cancel</Button>,
          <Button type="primary" key="submit" htmlType="submit" icon={<SaveOutlined />}
            loading={grainHeightMutation.isLoading}
            disabled={grainHeightMutation.isLoading}
            form={userOverrideGrainHeightsFormId}
            >
                Save
          </Button>,
        ]}
        >
            <Form preserve={false} {...formItemLayout} form={form} name={userOverrideGrainHeightsFormId}
            initialValues={defaultValues}
            onFinish={handleSubmit}
            >
                <Form.Item<UserGrainHeightForm> name="eaveHeightFt" label="Eaves Grain Depth"
                rules={[
                    {type: "number", max: binDTO?.maxGrainEaveHeight, message: `Max ${_.floor(binDTO?.maxGrainEaveHeight ?? 0, 2)}`},
                ]}>
                    <InputNumber style={{width: "16ch"}} suffix="ft" />
                </Form.Item>
                <Form.Item<UserGrainHeightForm> name="peakHeightFt" label="Peak Grain Depth"
                rules={[
                    {type: "number", max: binDTO?.maxGrainPeakHeight, message: `Max ${_.floor(binDTO?.maxGrainPeakHeight ?? 0, 2)}`},
                ]}
                    >
                    <InputNumber style={{width: "16ch"}} suffix="ft" />
                </Form.Item>
            </Form>
            <div>
                <Spin tip={"calculating"} spinning={grainQuery.isFetching}>
                    {grainQuery.data && (values?.eaveHeightFt != null || values?.peakHeightFt != null) && <>

                    <br />
                    <Typography.Text>Calc. depth @ <Typography.Text underline>Perimeter</Typography.Text> stack: </Typography.Text><Typography.Text> {formatNumber(grainQuery.data?.outerRingGrainHeightFt, { filler: "", decimalPlaces: 0, suffix: "ft" })}</Typography.Text>
                    <br />
                    <Typography.Text>Calc. depth @ <Typography.Text underline>Center</Typography.Text> stack: </Typography.Text><Typography.Text> {formatNumber(grainQuery.data?.innerRingGrainHeightFt, { filler: "", decimalPlaces: 0, suffix: "ft" })}</Typography.Text>
                    <br />
                    <Typography.Text><Typography.Text strong>Grain layers that qualify for drying</Typography.Text>: {qualifyingLayers(grainQuery.data?.innerRingGrainHeightFt).map(l => `L${l}`).join(", ")}</Typography.Text>
                    <br />
                    <br />
                    <Typography.Text><Typography.Text strong>For Each Layer</Typography.Text>: The grain must reach <Typography.Text strong>halfway</Typography.Text> to the valves above them for the layer to qualify for drying.
                        <br /><Typography.Text strong>For the Top Layer</Typography.Text>: The grain must cover the top valves by at least <Typography.Text strong>3 feet</Typography.Text> for it to qualify for drying.</Typography.Text>
                </>
                }
                </Spin>
            </div>

        </Modal>
    </>;

}

export const GrainHeightText = (props: { binDTO: BinDTO | undefined }) => {

    return <Flex vertical gap={0} style={{ paddingLeft: "8px" }}>
        <span style={{ textAlign: "right" }}>
            <Typography.Text>Eaves: {formatNumber(props.binDTO?.grain?.heightAtEaves, { filler: "", decimalPlaces: 1, suffix: " ft" })}</Typography.Text>
        </span>
        <span style={{ textAlign: "right" }}>
            <Typography.Text >Peak: {formatNumber(props.binDTO?.grain?.heightAtPeak, { filler: "", decimalPlaces: 1, suffix: " ft" })}</Typography.Text>
        </span>
    </Flex>;
};

const dryLayerAdjustFormId = "dry-layer-adjust-form-id";
export const DryLayerAdjust = (props: DryLayerAdjustProps) => {

    const [form] = useForm<FormValues>();
    //console.log("binDTO props", props.binDTO);

    const numberOfLayers = props.binDTO?.layersAir?.length ?? 0;

    const initialValues: Partial<FormValues> = {};
    initialValues.layers = [];
    for (let layer of props.binDTO?.layersAir ?? []) {
        initialValues.layers.push({
            percentage: props.binDTO?.routineEngine?.layersPercentages?.[layer.number -1 ]?.percentage ?? round((1/numberOfLayers) * 100.0, 1),
            number: layer.number,
        });
    }

    useEffect(() => {
        if (form.isFieldsTouched()) {
            console.log("dry layer field are touched");
            return;
        }
        else {
            console.log("dry layer fields are not touched");
            form.resetFields();
            return;
        }
    }, [props.binDTO]);

    console.log("layer form initial values: ", initialValues);

    const layerAdjustMutation = useDryLayerPercentAdjustMutation(props.deviceId);

    return <Card title="Layer Target Settings" extra={<>
        <div>
            <UserGrainHeights />
        </div>
    </>}>
        <DryLayerAdjustHelpText />
        <Form id={dryLayerAdjustFormId} form={form} onFinish={async (values) => {
            //console.log("layer adjust data values: ", values);
            layerAdjustMutation.mutate({layerPercentages: values.layers},
                {
                    onSuccess(data, variables, context) {
                        if (data.success === true) {
                            message.info("Updated target layer settings");
                        }
                        else {
                            message.error("Problem updating target layer settings");
                        }
                    },
                    onError(error, variables, context) {
                        console.error(error, "Error updating target layer settings");
                        message.error("Error updating target layer settings");
                    },
                }
                );
        }} initialValues={initialValues} validateTrigger={['onBlur', 'onFocus', 'onInput']}>

            <LayerAdjustmentList binDTO={props.binDTO} />
 
        </Form>

        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
            <Button loading={layerAdjustMutation.isLoading} disabled={layerAdjustMutation.isLoading} form={dryLayerAdjustFormId} htmlType='submit' type='primary'>Apply</Button>
        </div>
    </Card>
}