import * as React from 'react';
import ChangePasswordRequestModel from 'src/models/ChangePasswordRequestModel';
import { BasicValidateMessages } from 'src/consts/FormConstants';
import {
    Button,
    Form,
    Input
    } from 'antd';

const FormItem = Form.Item;

interface ChangePasswordFormProps {
    loading: boolean;
    onChangePassword: (values: ChangePasswordRequestModel) => void;
}

interface ChangePasswordFormState {
}
class ChangePasswordForm extends React.Component<ChangePasswordFormProps, ChangePasswordFormState> {
    // private formRef: FormInstance | null;
    constructor(props: ChangePasswordFormProps) {
        super(props);
    }

    render() {
        const layout = {
            labelCol: { xs: 24, sm: 8, md: 8, lg: 8, xl: 8, xxl: 8 },
            wrapperCol: { xs: 24, sm: 16, md: 16, lg: 16, xl: 16, xxl: 16 },

        };
        let wrapperColNoLabel = {};
        Object.keys(layout.wrapperCol).forEach((val) => {
            if (val !== 'span') {
                wrapperColNoLabel[val] = {
                    span: layout.wrapperCol[val],
                    offset: (layout.wrapperCol[val] + layout.labelCol[val] > 24 ? 0 : layout.labelCol[val])
                };
            }
        });

        return (
            <Form
                // ref={(ref) => (this.formRef = ref)}
                layout="horizontal"
                onFinish={this.onFinish}
                validateMessages={BasicValidateMessages}
                className="form-flex"
                {...layout}>
                <Form.Item
                    name="currentPassword"
                    label="Current Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your current password!',
                        },
                    ]}>
                    <Input.Password autoFocus={true} />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password!',
                        },
                    ]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['newPassword']}
                        rules={[
                            {
                                required: true,
                                message: 'Confirm New Password is required',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule: any, value: string | null) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match');
                                },
                            }),
                        ]}>
                        <Input.Password />
                    </Form.Item>
                <FormItem wrapperCol={wrapperColNoLabel}>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{width: '100%'}}
                    size="large"
                    disabled={this.props.loading}>
                    Save
                </Button>
                </FormItem>
            </Form >
        );
    }

    onFinish = (values: any) => {
        // this.formRef?.validateFields().then((vals: any) => {
        this.props.onChangePassword(values);
        // }).catch(error => {
        //     notification.error({
        //         message: error.message,
        //         description: error.description
        //     });
        // });
    }
}

export default ChangePasswordForm;
