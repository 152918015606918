import * as React from 'react';

interface FormattedPhoneProps {
    phone?: string | null;
}

function FormattedPhone(props: FormattedPhoneProps) {
    let { phone } = props;
    let formatted = '';
    if (phone) {
        if (phone.length >= 10) {
            formatted = `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
            if (phone.length > 10) {
                let ext = phone.substring(10);
                let zero = ''.padEnd(ext.length, '0');
                if (ext !== zero) {
                    formatted += ' x.' + (+ext);
                }
            }
        } else if (phone.length === 7) {
            formatted = `${phone.substring(0, 2)}-${phone.substring(3, 6)}`;
        } else {
            formatted = phone;
        }
    }
    return (
        <React.Fragment>
            {formatted}
        </React.Fragment>
    );
}

export default FormattedPhone;
