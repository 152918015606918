import { CheckCircleFilled } from "@ant-design/icons";
import { Badge, Button, Tag } from "antd";
import React from "react";
import { CheckPassedIcon } from "src/pages/features/WeatherMonitorAntdOnly";


export const IdleHelpText = () => {

    return <>
        <span>
            When the bin is in <b>Idle Mode</b>, the system is inactive. This means the valves will be closed, the fans, heaters & compressor will be off. You will still be able to view up-to-date sensor data, but you will not be able to make any control changes.
        </span>
    </>;
}

export const UserControlModeHelpText = () => {
    return <>
        <span>
            When the bin enters <b>User Control Mode</b> everything will be off. You will be able to turn on & off the fans & heaters, & set the position of the valves. You will also be able to define automated setpoints to run the fans, heaters & valves.
        </span>
    </>;
};

export const FillModeHelpText = () => {

    return <>
        <span>
            When the bin is in <b>Fill & Aerate Mode</b>, you will be able to add loads as you’re filling the bin. Once grain is covering the first valve of the stacks, the fans will automatically turn on when the incoming air conditions are within the temp & EMC setpoints. The heaters will not turn on in this mode. The compressor will turn on to power the valves & the stacks will automatically direct air to the top layer of grain as the bin is being filled.
        </span>
    </>;
}

export const DryModeHelpText = () => {

    return <>
        <span>
            When the bin is in <b>Dry Mode</b>, your bin will act as a batch dryer. The fans will automatically turn on when the incoming air conditions are within your temp & EMC setpoints. The heater will run based on your settings. The compressor will turn on to power the valves. The stacks will automatically direct air throughout the bin; starting by targeting the top, then the core, then moving sequentially targeting each layer of the bin from top to bottom. You can set the relative amount of time that each layer will be targeted. You can also set the amount of time you would like the bin to dry & it will cycle through each layer until the timer reaches zero.
        </span>
    </>;
}

export const StorageModeHelpText = () => {

    return <>
        <span>
            When the bin is in <b>Storage Mode</b>, the fans will automatically run for a set number of hours each week while incoming air conditions are within the temp & EMC setpoints. You can change your weekly runtime in the fan timer settings.     </span>
    </>;
}

export const UnloadModeHelpText = () => {
    return <>
        <span>
            When the bin is in <b>Unload Mode</b> everything will be off & you will be able to log load outs as you’re unloading the bin.
        </span>
    </>;
}