import BinDTO from "src/models/BinDTO";
import { BaseChartProps, OPICableChart, TemperatureCableChart, TemperatureCableOpiChart } from "./TemperatureCableChart";
import React, { useCallback, useMemo, useState } from "react";
import { RedoOutlined } from "@ant-design/icons";
import { useIsFetching } from "@tanstack/react-query";
import { Space, Button, SelectProps, Select, Switch } from "antd";
import dayjs from "dayjs";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { RangeValue } from "./ChartContainer";
import { ChartDatePicker } from "./Co2Chart";
import { LayerTemperatureHistoryChart } from "./LayerTemperatureHistoryChart";
import { ExtraCharts } from "./DryingDashboard";
import TemperatureSensorEnum from "src/consts/TemperatureSensorEnum";

export const TemperatureCableChartTab = (props: BaseChartProps & {binDTO: BinDTO}) => {

    const currentDate = dayjs();
    const priorDate = currentDate.subtract(14, 'days');
  
  
    const isControlled = typeof props.value != 'undefined';
    const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);
    const inprogress = useIsFetching([...binDBKeys.bin(props.binId), "charts"]);
  
    // Internally, we need to deal with some value. Depending on whether
    // the component is controlled or not, that value comes from its
    // props or from its internal state.
  
    const value = isControlled ? props.value : internalValue;
  
    const onDateChange = useCallback((values: RangeValue, formatter?: any) => {
      if (props.onChange) {
        props.onChange(values);
      }
      else {
        setIntervalValue(values);
      }
    }, [props.onChange]);
  

    const [extraCharts, setExtraCharts] = useState<Array<ExtraCharts>>([]);

    const hasOpiSensors = (props.binDTO?.opiMoistureCables?.length ?? 0) > 0;
    const hasTemperatureCables = (props.binDTO?.temperatureCables?.length ?? 0) > 0;
    const preferredTemperatureSource = props.binDTO?.temperatureSensorsType ?? TemperatureSensorEnum.Thermocouple;


    const extraChartSelectOptions = useMemo(() => {
      const extraChartSelectOptions: SelectProps['options'] = [];

      if (hasTemperatureCables) {
        extraChartSelectOptions.push({
            label: "Temp (per sensor)",
            value: ExtraCharts.TemperatureCables,
        })
      }
      else if (hasOpiSensors) {
        extraChartSelectOptions.push({
            label: "OPI Temp (per sensor)",
            value: ExtraCharts.OPITemperatureSensors,
        })
      }
  
      return extraChartSelectOptions;
    }, [hasOpiSensors, hasTemperatureCables]);


    const [showPerSensorChart, setShowPerSensorChart] = useState(false);

  
  
    return <>
      <div>
        <Space direction="horizontal" wrap>
          <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />
          <Button type="primary" disabled={inprogress > 0} icon={<RedoOutlined spin={inprogress > 0} />} onClick={() => {
            onDateChange([value?.[0]!, dayjs()], ['', '']);
          }}>
          </Button>

          <Switch unCheckedChildren="By Layer" checkedChildren="By Sensor" checked={showPerSensorChart} onChange={(checked, event) => {
            setShowPerSensorChart(checked);
          }} />
  
        </Space>
  
        <div style={{ width: "100%" }}>

        {/* {[TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast].includes(preferredTemperatureSoruce) && <TemperatureCableOpiChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} />} */}

        {!showPerSensorChart && <LayerTemperatureHistoryChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} chartHeight={"60vh"} preferredSensorType={preferredTemperatureSource} />}
        {showPerSensorChart && [TemperatureSensorEnum.Thermocouple].includes(preferredTemperatureSource) && <>
          <TemperatureCableChart value={value} binId={props.binId} deviceId={props.deviceId} chartHeight={"60vh"} />
        </>}
        {/* TODO: BinSense temperature chart support/query */}
        {showPerSensorChart && [TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast, TemperatureSensorEnum.BinSense].includes(preferredTemperatureSource) && <>
          <TemperatureCableOpiChart value={value} binId={props.binId} deviceId={props.deviceId} chartHeight={"60vh"} />
        </>}

        </div>
      </div>
    </>

}