import { useQuery } from "@tanstack/react-query";
import { ExtendedColumnType, binDBKeys, useColumnExpansion } from "src/pages/binOverview/BinCommander";
import { useBinInfoContext } from "src/queries/BinInfoContext";
import { GetShivversMoistureData } from "./MoistureDisplay";
import dayjs, { Dayjs } from "dayjs";
import { Table } from "antd";
import React, { useState } from "react";
import ShivversService from "src/api/ShivversService";

export const GetShivversAlertData = async (ip: string, signal?: AbortSignal) => {
    return await ShivversService.alarms(ip, null, null, signal)
}

export const ShivversTapeStrip = (props: any) => {

    const binInfo = useBinInfoContext();



    const moistureQuery = useQuery({
        queryFn: async (q) => await GetShivversMoistureData(binInfo.linkIpAddress!, {
            from: props.range?.[0] ?? null,
            to: props.range?.[1] ?? null,
        }, q.signal),
        queryKey: [...binDBKeys.moistureDisplay(binInfo.linkIpAddress!), { startDate: props.range?.[0] ?? null, endDate: props?.range?.[1] ?? null }],
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    })

    const columns: Array<ExtendedColumnType<Awaited<ReturnType<typeof GetShivversMoistureData>>['data'][0]>> = [
        {
            title: "Datein",
            dataIndex: "dateinUtc",
            sorter: (a, b) => {
                return dayjs(a.dateinUtc).diff(b.dateinUtc);
            },
            render(value, record, index) {
                return <span>{dayjs(value).format("hh:mm A MM/DD/YYYY")}</span>
            },
            sortType: 'date',
            defaultSortOrder: "descend",
        },
        {
            title: "Moisture",
            dataIndex: "moistread",
        },
        {
            title: "GrnTemp",
            dataIndex: "graintemp",
        },
        {
            title: "MoistAvg",
            dataIndex: "moistavg",
        },
        {
            title: "Status",
            dataIndex: "machine",
            filters: [
                {
                    text: "ON",
                    value: "ON"
                },
                {
                    text: "OFF",
                    value: "OFF"
                }
            ],
            onFilter: (value, record) => value.toString().trim() === record.machine?.trim(),
            onCell: (record, rowIndex) => {
                return {
                    style: {
                        backgroundColor: record.machine?.includes("ON") ? 'rgb(4, 32, 253)' : 'rgb(253, 48, 4)',
                        color: "white",
                    }
                };
            }
        },
        {
            title: "Target",
            dataIndex: "targettemp",
        },
        {
            title: "Plenum",
            dataIndex: "plenumtemp",
        },
    ];

    const transformedColumns = useColumnExpansion({
        columns: columns, datasource: moistureQuery.data?.data ?? []
    })

    return <Table
        loading={moistureQuery.isInitialLoading}
        size="small" pagination={{ defaultPageSize: 10 }} dataSource={moistureQuery.data?.data ?? []} columns={transformedColumns}
    />

};


export const ShivversAlertTable = () => {

    const binInfo = useBinInfoContext();
    const alertQuery = useQuery({
        queryFn: async (q) => await GetShivversAlertData(binInfo.linkIpAddress!, q.signal),
        queryKey: binDBKeys.alertDataShivvers(binInfo.linkIpAddress!),
        refetchOnWindowFocus: false,
    })

    const columns: Array<ExtendedColumnType<Awaited<ReturnType<typeof GetShivversAlertData>>[0]>> = [
        {
            title: "Datein",
            dataIndex: "dateinUtc",
            sorter: (a, b) => {
                return dayjs(a.dateinUtc).diff(b.dateinUtc);
            },
            render(value, record, index) {
                return <span>{dayjs(value).format("hh:mm A MM/DD/YYYY")}</span>
            },
            width: "176px",
            sortType: 'date',
            defaultSortOrder: "descend",
        },
        {
            title: "ECode",
            dataIndex: "eCode",
            width: "240px",
        },
        {
            title: "EMessage",
            dataIndex: "eMessage",
        },
    ];

    const transformedColumns = useColumnExpansion({
        columns: columns, datasource: alertQuery.data ?? []
    })

    return <Table sticky size="small" pagination={{ defaultPageSize: 10 }} dataSource={alertQuery.data ?? []} columns={transformedColumns} />

};
