import { VideoCameraOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Col, Modal, Popover, Row, Space, Tooltip, Typography, message, notification } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BinApiService from "src/api/BinApiService";
import { useBinInfoContext } from "src/queries/BinInfoContext"
import ReactPlayer from "react-player/file";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { ApiError } from "src/api/ApiResultHandler";
import RoleUtil from "src/utils/RoleUtil";

const ResponsivePlayer = (props: {url: string}) => {
      return (
        <div className='player-wrapper' key={props.url ?? null}>
          <ReactPlayer
            className='react-player'
            url={props.url}
            width="100%"
            height="100%"
            muted
            controls
            volume={0}
            playsinline
            playing
            pip
            config={{
                hlsOptions: {
                    "liveSyncDuration": 10,
                    "liveMaxLatencyDuration": 15,
                }
            }}
          />
        </div>
      )
  }

const useStartLiveStream = (binId: number) => {

    return useMutation({
        mutationFn: async (params: {cameraNumber: number}) => {
            return await BinApiService.startLiveStream(binId);
        },
        onError(error: any, variables, context) {
            console.log("error starting live stream", error);
            const isAdmin = RoleUtil.currentUserIsAdmin()
            notification.error({
              message: error?.errorDetails?.title ?? "Livestream error",
              description: isAdmin ? error?.errorDetails?.detail : undefined,
            });
        },
    });
}


const useStopLiveStream = (binId: number) => {

    return useMutation({
        mutationFn: async () => {
            return await BinApiService.stopLiveStream(binId);
        },
        onError(error: any, variables, context) {
            console.log("error stopping live stream", error);
            const isAdmin = RoleUtil.currentUserIsAdmin()
            notification.error({
              message: error?.errorDetails?.title ?? "Livestream error",
              description: isAdmin ? error?.errorDetails?.detail : undefined,
            });
        },
    });
}

export const LiveStreaming = () => {
    const queryClient = useQueryClient();
    const binInfo = useBinInfoContext();
    const binId = binInfo?.id;

    const [open, setOpen] = useState(false);

    const close = useCallback(() => {
        setOpen(false);
    }, []);

    const query = useQuery({
        queryKey: binDBKeys.livestream.camera({binId: binId, cameraNumber: 1}),
        queryFn: async (q) => {
            return await BinApiService.getStreamsForDevice(binId, q.signal);
        },
        retry: 1,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        enabled: open,
        refetchInterval: (data, query) => {
            if (query.state.fetchFailureCount > 0) {
                return false;
            }
            return 3_000;
        },
    });

    const streams = query?.data?.streamUrls ?? [];
    const stream = streams?.[0];

 
    const mutateStartLiveStreaming = useStartLiveStream(binId);
    const mutateStopLiveStreaming = useStopLiveStream(binId);

    const onLiveStreamingClick = useCallback(async () => {
        queryClient.invalidateQueries(binDBKeys.livestream.all(binId));
        mutateStartLiveStreaming.mutate({cameraNumber: 1},
            {onSuccess: (data) => {
                if (data.success === true) {
                    message.info("livestream will start in 15-20 seconds");
                    setOpen(true);
                }
            }}
        )
    }, [mutateStartLiveStreaming])

    
    const onLiveStreamingStopClick = useCallback(async () => {
        queryClient.invalidateQueries(binDBKeys.livestream.all(binId));
        mutateStopLiveStreaming.mutate(undefined,
            {onSuccess: (data) => {
                if (data.success === true) {
                    if (streams?.length > 0) {
                        message.info("livestream stopped");
                    }
                }
                else {
                    console.warn("problem stopping livestream");
                }
            }}
        )
    }, [mutateStopLiveStreaming, streams])

    const streamText = () => {
        if (stream) {
            return "Restart Livestream";
        }
        else {
            return "Start Livestream"
        }
    }
    const LIVESTREAM_DURATION_SECONDS = 90;
    const livestreamDurationReminderText = `Livestreams last for up to ${LIVESTREAM_DURATION_SECONDS} seconds`;
return <div>
        <div>
        <Button onClick={async () => {
            setOpen(true);
            await onLiveStreamingClick();
        }}
            icon={<VideoCameraOutlined />}>
                Livestream
        </Button>
        </div>
        <Modal width="45%" afterClose={() => {
            onLiveStreamingStopClick();
        }} title={<Row>
            <Col span={6}>
            <span>{<Typography.Text strong>Livestream</Typography.Text>}</span>
            </Col>
            <Col offset={3}>
                <Tooltip title={livestreamDurationReminderText}>
                    <Button loading={mutateStartLiveStreaming.isLoading} onClick={onLiveStreamingClick} icon={<VideoCameraOutlined />}>{streamText()}</Button>
                </Tooltip>
            </Col>
        </Row>
    } footer={null} open={open} onCancel={close} onOk={close}>

{(query.isError || query.failureCount > 0) && <Alert
      message="Livestreaming is temporarily unavailable"
      showIcon
      type="error"
      action={
        <Button onClick={() => query.refetch()} size="small" danger loading={query.isFetching}>
          Retry
        </Button>
      }
    />
}
            {stream != null && <Row justify={"center"}>
                <Col>
                    <Typography.Text type="secondary">{livestreamDurationReminderText}</Typography.Text>
                </Col>
            </Row>
            }
            <Row justify={"center"}>
                <Col span={24}>
                    <ResponsivePlayer url={stream} />
                </Col>
            </Row>



        </Modal>
    </div>
}