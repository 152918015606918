import * as React from 'react';
// import Routes from 'src/consts/Routes';
import UserSecurity from 'src/models/UserSecurity';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
import { UserSessionActions } from 'src/redux/actions/UserSessionActions';
import UnauthorizedComponent from 'src/pages/Unauthorized';
import { Redirect } from 'react-router-dom';
import Routes from 'src/consts/Routes';
import history from 'src/utils/HistoryUtil';
import UserSecurityDTO from 'src/models/UserSecurityDTO';
// import RouteWithLayout from './RouteWithLayout';
// import PublicLayout from 'src/layouts/PublicLayout';

interface AuthorizationProps {
    user: UserSecurity | null;
    checkAction: () => void;
}

const WithAuthorization = (allowedRoles?: UserSecurityDTO['roles'] | null, allowedUsers?: string[] | null) => {
    return (InnerComponent: React.ComponentClass | React.StatelessComponent) => {
        class Authorization extends React.Component<AuthorizationProps> {
            constructor(props: AuthorizationProps) {
                super(props);
                if (!InnerComponent) {
                    throw new Error('componentWrapper function was passed "null" for the "InnerComponent" argument.');
                }
            }

            componentDidMount() {
                this.props.checkAction();
            }

            render() {
                let authorized = false;
                let rolesSet = (allowedRoles && allowedRoles.length > 0)  ? true : false;
                let usersSet = (allowedUsers && allowedUsers.length > 0) ? true : false;

                if (this.props.user) {
                    if (!rolesSet && !usersSet) {
                        authorized = true;
                    }
                    if (!authorized && rolesSet) {
                        for (let i = 0, len = allowedRoles!.length; i < len; i++) {
                            if (this.props.user.isInRole(allowedRoles![i])) {
                                authorized = true;
                                break;
                            }
                        }
                    }
                    if (!authorized && usersSet) {
                        for (let i = 0, len = allowedUsers!.length; i < len; i++) {
                            if (this.props.user.userName === allowedUsers![i]) {
                                authorized = true;
                                break;
                            }
                        }
                    }
                } else {
                    let url = history.location.pathname + history.location.search;
                    return <Redirect to={Routes.generate(Routes.LOGIN, {}, {returnUrl: url})} />;
                }

                if (authorized) {
                    let props = { ...this.props };
                    return <InnerComponent {...props} />;
                } else {
                    return <UnauthorizedComponent />;
                }
            }
        }

        const mapStateToProps = (state: StateStoreModel) => {
            return {
                user: state.UserSession.Value
            };
        };

        const mapDispatchToProps = (dispatch: any) => {
            return {
                checkAction: () => dispatch(UserSessionActions.check()),
            };
        };

        return connect(mapStateToProps, mapDispatchToProps)(Authorization);
    };
};

export default WithAuthorization;
