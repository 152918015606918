import * as React from "react";
import { useEffect, useState } from "react";
import { Descriptions, Space, Table } from "antd";
import { RestartDriStackModuleButton } from './RestartModuleButton';
import AdminApiService from "src/api/AdminApiService";
import { sortBy } from 'lodash';
import {
    Button,
    Form,
    InputNumber,
    Layout,
    Checkbox,
    Tabs,
    notification,
    Typography,
    Card,
    Modal,
    Switch,
    Col,
    Row,
    Select,
    Radio,
    Divider,
    Spin,
} from "antd";
import { EditOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
// @ts-ignore transformed by webpack into svg url later
import { Option } from "antd/lib/mentions";
import BoardGroup from "src/consts/BoardGroup";
import { DeviceUpdateModalV2 } from "./TextUpdateV2";
import DeviceProfileDTO from "src/models/DeviceProfileDTO";
import { DeviceProfileTable } from "./DeviceProfileTable";
import SensorType from "src/consts/SensorType";
import _ from "lodash";
import AutomationType from "src/consts/AutomationType";
import BinAssemblySubmittalDTO from "src/models/BinAssemblySubmittalDTO";
import { NamePath } from "antd/es/form/interface";
import IotModel from "src/consts/IotModel";
import { useGetAllAzureIoTDeviceIDsQuery } from "src/queries/useGetAllAzureIoTDeviceIDsQuery";
import { useGetAllPCBsQuery } from "src/queries/useGetAllPCBsQuery";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { deviceQueryKeys } from "../dashboard/HomeScreen/BinDetails";
import { ApiError } from "src/api/ApiResultHandler";
import { useGetBinAssemblySubmittalQuery } from "src/queries/useGetBinAssemblySubmittalQuery";
import { useGetBinsQuery } from "src/queries/useGetBinsQuery";
import ActuatorAssemblyDTO from "src/models/ActuatorAssemblyDTO";
import ActuatorType from "src/consts/ActuatorType";

const { TabPane } = Tabs;
const { Text } = Typography;

export const UpdateDeviceV3 = () => {

    const [chosenDeviceID, setChosenDeviceID] = useState<string>("");

    const queryClient = useQueryClient();

    const getAllBinStringsQuery = useGetAllAzureIoTDeviceIDsQuery({enabled: true, refetchOnWindowFocus: false}); 
    const getAllPCBsQuery = useGetAllPCBsQuery({enabled: true, refetchOnWindowFocus: false});
    const getBinAssemblySubmittalQuery = useGetBinAssemblySubmittalQuery(chosenDeviceID, {enabled: (chosenDeviceID != "" || chosenDeviceID != null), refetchOnWindowFocus: false})
    const allDeviceIds = getAllBinStringsQuery.data;
    const allPCBs = getAllPCBsQuery.data;

    const [form] = Form.useForm<BinAssemblySubmittalDTO>();
    const values = Form.useWatch<BinAssemblySubmittalDTO>([], { form, preserve: true });

    const [loading, setLoading] = useState<boolean>(false);
    const [enableSubmitButton, setEnableSubmitButton] = useState<boolean>(false);

    const [deviceProfileModalOpen, setDeviceProfModalOpen] = useState<boolean>(false);
    const [areYouSureClearAllModalOpen, setAreYouSureClearAllModalOpen] = useState<boolean>(false);

    const [showModalFromSheet, setShowModalFromSheet] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState("1");
    const handleTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    useEffect( () => {
        if(getBinAssemblySubmittalQuery.data){
            const result = getBinAssemblySubmittalQuery.data;
            form.resetFields();
            if(result != null){
                form.setFieldsValue(result as any);
            }
        }
    }, [getBinAssemblySubmittalQuery.dataUpdatedAt])

    useEffect(() => {
        if(getBinAssemblySubmittalQuery.isError){
            let error = getBinAssemblySubmittalQuery.error as ApiError;
            notification.error({
                message: "Could not fetch device bin assembly",
                description: error?.errorDetails?.detail ?? "Contact support"
            });
        }
    }, [getBinAssemblySubmittalQuery.error]);

    useEffect(() => {
        if(getAllBinStringsQuery.isError){
            let error = getAllBinStringsQuery.error as ApiError;
            notification.error({
                message: "Could not fetch device IDs",
                description: error?.errorDetails?.detail ?? "Contact support"
            });
        }
    }, [getAllBinStringsQuery.error]);

    useEffect(() => {
        if(getAllPCBsQuery.isError){
            let error = getAllPCBsQuery.error as ApiError;
            notification.error({
                message: "Could not fetch PCB lookups",
                description: error?.errorDetails?.detail ?? "Contact support"
            });
        }
    }, [getAllPCBsQuery.error]);

    useEffect(() => {
        setEnableSubmitButton(false);
    }, [values])


    const handleDeviceIDClicked = (value: string) => {
        setChosenDeviceID(value);
        getBinAssemblySubmittalQuery.refetch();
    }

    const binIdDropdownValues = () => {
        return (
            <>
                {allDeviceIds?.map(id => (
                    <Option key={id} value={id}>
                        {id}
                    </Option>
                ))}
            </>
        );
    };

    const InputListForm = (path: NamePath, helpText: string, inRing: boolean, dependentArrayNames: string[] | null, disableInput : boolean = false) => {
        return (
            <Form.List name={[...path]}>
                {(fields, { add, remove }) => (
                    <Row style={{ paddingBottom: "20px" }}>
                        <Col style={{ paddingRight: "10px" }}>
                            <span>{helpText}</span>
                        </Col>
                        {
                            !disableInput && 
                            <>
                                <Col style={{ paddingRight: "10px" }}>
                                    <Button
                                        icon={<MinusCircleOutlined />}
                                        onClick={() => {
                                            if (fields.length > 1) {
                                                remove(fields.length - 1);
                                            } else if (fields.length <= 1) {

                                                if (fields.length == 1) {
                                                    remove(fields.length - 1)
                                                }
                                                if (dependentArrayNames !== null && dependentArrayNames.length > 0) {
                                                    if (inRing === true) {
                                                        dependentArrayNames.forEach(n => {
                                                            form.setFieldValue(["rings", path[0], n as any], []);
                                                        })

                                                    } else {
                                                        dependentArrayNames.forEach(n => {
                                                            form.setFieldValue([n as any], []);
                                                        })
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <Button icon={<PlusCircleOutlined />} onClick={() => add()} />
                                    </Form.Item>
                                </Col>
                            </>
                        }

                        {fields.map((field, index) => {
                            return (
                                <Row style={{ paddingBottom: "10px" }} gutter={8} align="middle">
                                    <Col style={{ paddingBottom: "10px" }} flex="auto">
                                        <Form.Item
                                            key={`${path}-${field.name}`}
                                            //layerStartHeights[0]
                                            name={[field.name]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter the desired value"
                                                }
                                            ]}
                                        >
                                            <InputNumber disabled={disableInput} addonBefore={`${field.name + 1}`} min={0} style={{ width: "100px" }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Row>
                )}
            </Form.List>
        );
    };

    const onFinish = async (values: any) => {
        let assemblySubmittal = values as BinAssemblySubmittalDTO;
        try {
            setLoading(true);
            const result = await AdminApiService.uploadBinAssembly(assemblySubmittal.binAssembly!);
            notification.success({ message: "Bin assembly upload successful" });
            // setChosenDeviceID("");
            setEnableSubmitButton(false);

        } catch (err) {
            console.log(err);
            notification.error({
                message: "Could not submit bin assembly",
                description: err?.errorDetails?.detail ?? "Contact support"
            });
        }finally{
            setLoading(false);
        }
    };

    const regenerateAssembly = async (values: any) => {

        let submittal = values == null ? form.getFieldsValue() : values as BinAssemblySubmittalDTO;
        
        try {
            setLoading(true);
            const validated = await form.validateFields();
        } catch (err) {
            // console.log("Error regenerating bin assembly", err);
            notification.error({
                message: err?.errorDetails?.detail ?? "Validation error. Check all your inputs",
                description: err?.detail
            });

            setLoading(false);
            return null;
        }

        try {

            const result = await AdminApiService.generateAssembly(submittal);
            if(result.binType === AutomationType.DriStack){
                form.setFieldValue("layerTopHeights", result.layers?.map( (l) => {return l.heightFromFloorTopFt}));
            }
            form.setFieldValue("binAssembly", result as any);

            setLoading(false);
            notification.success({ message: "Bin assembly validation successful" });
            setEnableSubmitButton(true);
            return result;
        } catch (err) {
            console.log("Error regenerating bin assembly", err);
            notification.error({
                message: err?.errorDetails?.detail ?? "Contact support",
                description: err?.detail
            });

            setLoading(false);
            return null;
        }
    };

    const createSubmittalFromText = (text: string): Promise<BinAssemblySubmittalDTO> => {
        return new Promise((resolve, reject) => {
            AdminApiService.createSubmittalFromText(text, chosenDeviceID)
                .then(res => resolve(res))
                .catch(err => {
                    notification.error({
                        message: err.errorDetails.detail,
                        description: err.detail
                    });
                    reject(err);
                });
        });
    };

    interface SensorWindowInterface {
        sensorName: string;
        sensorController: string;
        portNumber: string;
        indexOnPort: string;
        sensorType: string;
    }

    const SensorWindow = () => {
        let devices = form.getFieldValue(["binAssembly", "devices"]) as DeviceProfileDTO[]
        const allStackRhts = devices?.filter(b => b.boardLookupDTO?.boardGroupId === BoardGroup.StackBoards).flatMap(profile => profile.sensors);
        const allOpiRhts = devices?.filter(b => b.boardLookupDTO?.boardGroupId === BoardGroup.OPIBoards).flatMap(profile => profile.sensors)
            .filter(s => s?.sensorTypeId === SensorType.OPI_RHT);
        const allBinsenseRhts = devices?.filter(b => b.boardLookupDTO?.boardGroupId === BoardGroup.OPIBoards).flatMap(profile => profile.sensors)
            .filter(s => s?.sensorTypeId === SensorType.BinSense);
        const allThermocouples = devices?.filter(b => b.boardLookupDTO?.boardGroupId === BoardGroup.SensingBoards).flatMap(profile => profile.sensors)
            .filter(s => s?.sensorTypeId === SensorType.Thermocouple);
        const allRhtBoardSensors = devices?.filter(b => b.boardLookupDTO?.boardGroupId === BoardGroup.RHTBoards).flatMap(profile => profile.sensors)

        const allGamSensors = devices?.filter(b => b.boardLookupDTO?.boardGroupId === BoardGroup.SensingBoards).flatMap(profile => profile.sensors)
            .filter(s => s?.sensorTypeId !== SensorType.Thermocouple);
        const allFanSensors = devices?.filter(b => b.boardLookupDTO?.boardGroupId === BoardGroup.Fans).flatMap(profile => profile.sensors);

        let rhtData = allStackRhts?.map(sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--",
            layer: sensor?.layerNumber ?? "--"
        }));

        let rhtBoardData = allRhtBoardSensors?.map(sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--",
            layer: sensor?.layerNumber ?? "--"
        }));

        const sortedOpiRht = allOpiRhts?.sort((codeA, codeB) => {
            let [aA, bA, cA] = (codeA?.name ?? "").match(/\d+/g)?.map(Number) ?? [0, 0, 0];
            let [aB, bB, cB] = (codeB?.name ?? "").match(/\d+/g)?.map(Number) ?? [0, 0, 0];

            if (aA !== aB) {
                return aA - aB;
            }
            if (bA !== bB) {
                return bA - bB;
            }
            return cA - cB;
        });


        let opiRhtData = sortedOpiRht?.map(sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--",
            layer: sensor?.layerNumber ?? "--"
        }));

        const sortedBinsenseRht = allBinsenseRhts?.sort((codeA, codeB) => {
            let [aA, bA, cA] = (codeA?.name ?? "").match(/\d+/g)?.map(Number) ?? [0, 0, 0];
            let [aB, bB, cB] = (codeB?.name ?? "").match(/\d+/g)?.map(Number) ?? [0, 0, 0];

            if (aA !== aB) {
                return aA - aB;
            }
            if (bA !== bB) {
                return bA - bB;
            }
            return cA - cB;
        });


        let binSenseRhtData = sortedBinsenseRht?.map(sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--",
            layer: sensor?.layerNumber ?? "--"
        }));

        const sortedThermocouples = allThermocouples?.sort((codeA, codeB) => {
            let [aA, bA, cA] = (codeA?.name ?? "").match(/\d+/g)?.map(Number) ?? [0, 0, 0];
            let [aB, bB, cB] = (codeB?.name ?? "").match(/\d+/g)?.map(Number) ?? [0, 0, 0];

            if (aA !== aB) {
                return aA - aB;
            }
            if (bA !== bB) {
                return bA - bB;
            }
            return cA - cB;
        });


        // sortedThermocouples now contains allThermocouples sorted by SensingBoardName first,
        // then by PortNumber, and finally by IndexOnPort


        let tempCableData = sortedThermocouples?.map(sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--",
            layer: sensor?.layerNumber ?? "--"
        }));

        let fanData = allFanSensors?.map(sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--",
            layer: sensor?.layerNumber ?? "--"
        }));

        let gamData = allGamSensors?.map(sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--",
            layer: sensor?.layerNumber ?? "--"
        }));


        let data: SensorWindowInterface[] = []
        rhtData?.forEach(d => data.push(d as SensorWindowInterface));
        opiRhtData?.forEach(d => data.push(d as SensorWindowInterface));
        binSenseRhtData?.forEach(d => data.push(d as SensorWindowInterface));
        tempCableData?.forEach(d => data.push(d as SensorWindowInterface));
        fanData?.forEach(d => data.push(d as SensorWindowInterface));
        gamData?.forEach(d => data.push(d as SensorWindowInterface));
        rhtBoardData?.forEach(d => data.push(d as SensorWindowInterface));

        const columns = [
            {
                title: "Name",
                dataIndex: "sensorName",
                width: "120px",
            },
            {
                title: "Board",
                dataIndex: "sensorController"
            },
            {
                title: "Port",
                dataIndex: "portNumber"
            },
            {
                title: "Position on port",
                dataIndex: "indexOnPort"
            },
            {
                title: "Layer",
                dataIndex: "layer"
            }
        ];

        return (
            <div style={{ maxHeight: 700, maxWidth: 700, overflowY: "auto", border: "1px solid #e8e8e8" }}>
                <Table dataSource={data} columns={columns} pagination={false} scroll={{ y: 500 }} />
            </div>
        );
    };

    const ActuatorWindow = () => {
        let devices = form.getFieldValue(["binAssembly", "devices"]) as DeviceProfileDTO[]
        const allStackBoards = devices?.filter(b => b.boardLookupDTO?.boardGroupId === BoardGroup.StackBoards);

        let allSols : ActuatorAssemblyDTO[] = [];
        allStackBoards?.forEach( (stackBoard) => {
            let solenoids = stackBoard.actuators;
            solenoids?.sort( (a,b) =>{
                return a.portNumber - b.portNumber;
            })

            solenoids?.forEach( (a) => {
                allSols.push(a);
            })
        })

        const fanActuators = devices?.filter(b => b.boardLookupDTO?.boardGroupId === BoardGroup.Fans)
            .flatMap(profile => profile.actuators);

        let updatedData = allSols?.map(actuator => ({
            actuatorName: actuator?.name ?? "--",
            actuatorController: actuator?.actuatingBoardName ?? "--",
            portNumber: actuator?.portNumber ?? -1,
            layer: actuator?.layerNumber ?? "--"
        }));

        fanActuators?.forEach(a => updatedData.push({
            actuatorName: a?.name ?? "--",
            actuatorController: a?.actuatingBoardName ?? "--",
            portNumber: a?.portNumber ?? -1,
            layer: a?.layerNumber ?? "--"
        }));


        const columns = [
            {
                title: "Actuator Name",
                dataIndex: "actuatorName"
            },
            {
                title: "Actuator Board",
                dataIndex: "actuatorController"
            },
            {
                title: "Layer",
                dataIndex: "layer"
            }
        ];

        return (
            <div style={{ maxHeight: 700, maxWidth: 700, overflowY: "auto", border: "1px solid #e8e8e8" }}>
                <Table dataSource={updatedData} columns={columns} pagination={false} scroll={{ y: 500 }} />
            </div>
        );
    };

    const handleClickAutoBin = () => {

        const ringCount = values.rings?.length ?? 0;
        for(let i =0; i < ringCount; i++){
            form.setFieldValue(["rings", i, "stackBearings"], [])
            form.setFieldValue(["rings", i, "valveHeights"], [])
            form.setFieldValue(["rings", i, "stackHeightFt"], 0)
        }
        
    }

    const getReviewSection = () => {
        const deviceID = values.deviceID;
        const binId = values.binID;
        const diameter = values?.binAssembly?.diameter ?? 0.0;
        const eaveHeight = values?.binAssembly?.eaveHeight;
        const peakHeight = values?.binAssembly?.peakHeight;
        const plenumHeight = values?.binAssembly?.plenumHeight;
        const peakCapDiameterFt = values?.binAssembly?.peakCapDiameter;

        return (
            <Card title="Review section" extra={<span style={{ justifyContent: "left" }}>click validate to re-render</span>}>
                <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} size="small">
                    <Descriptions.Item label={"Device ID"}>{deviceID}</Descriptions.Item>
                    <Descriptions.Item label={"Bin ID"}>{binId}</Descriptions.Item>
                    <Descriptions.Item label={"Diameter Ft."}>{diameter}</Descriptions.Item>
                    <Descriptions.Item label={"Eave Height Ft."}>{eaveHeight}</Descriptions.Item>
                    <Descriptions.Item label={"Peak Height Ft."}>{peakHeight}</Descriptions.Item>
                    <Descriptions.Item label={"Plenum Height Ft."}>{plenumHeight}</Descriptions.Item>
                    <Descriptions.Item label={"Peak Cap Diameter Ft."}>{peakCapDiameterFt}</Descriptions.Item>
                    <Descriptions.Item label="Sensors">
                        {" "}
                        <SensorWindow />{" "}
                    </Descriptions.Item>
                    <Descriptions.Item label="Actuators">
                        {" "}
                        <ActuatorWindow />{" "}
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        );
    };

    return (
        <Layout.Content className="createBinAssembly">
            <Spin spinning={getBinAssemblySubmittalQuery.isFetching || getBinAssemblySubmittalQuery.isLoading || loading}>
                <div>
                    <Text>Device: </Text>
                    <Select
                        style={{ width: "32ch", maxHeight: "300px", overflow: "auto" }}
                        placeholder="Select Device"
                        showSearch
                        optionFilterProp="children"
                        onSelect={ (value: string) => {handleDeviceIDClicked(value)}}
                        loading={getAllBinStringsQuery.isFetching}
                    >
                        {binIdDropdownValues()}
                    </Select>
                </div>

                {chosenDeviceID && (
                    <div>
                        <fieldset>
                            <legend>
                                <b>Hardware & Layout</b> -{" "}
                                <i>
                                    {" "}
                                    <small>Changes to this section require restarting the module</small>{" "}
                                </i>

                            </legend>
                        </fieldset>

                        <Row>
                            <Col span={13} style={{ paddingRight: "10px" }}>
                                <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
                                    <TabPane tab="Basic" key="1">
                                        <i>
                                            <Row>
                                                <small>If any problems with generation of devices is noticed, delete the devices of that board family and click validate</small>
                                            </Row>
                                            <Row>
                                                <small>Auto-generation of 2021 systems is not supported. you will have to add devices manually.</small>
                                            </Row>
                                        </i>
                                        <Form
                                            scrollToFirstError={true}
                                            onFinish={onFinish}
                                            autoComplete="off"
                                            // ref={_formRef}
                                            form={form}
                                        >
                                            <Row>
                                                <Col>
                                                    <Form.Item name={"binID"} />
                                                </Col>
                                                <Col>
                                                    <Form.Item name={"deviceID"} />
                                                </Col>
                                                <Col>
                                                    <Form.Item name={"binAssembly"} />
                                                </Col>
                                            </Row>
                                            <div>
                                                <Row style={{ justifyContent: "right" }}>
                                                    <Col style={{ paddingLeft: "10px", paddingBottom: "10px" }}>
                                                        <RestartDriStackModuleButton deviceId={chosenDeviceID} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ justifyContent: "right" }}>
                                                    <Col>
                                                        <Button icon={<EditOutlined />} onClick={() => setShowModalFromSheet(true)}>
                                                            Fill from Module Twin spreadsheet
                                                        </Button>
                                                    </Col>

                                                    <Col style={{ paddingLeft: "10px" }}>
                                                        <Form.Item>
                                                            <Button type="primary"
                                                                onClick={() => {
                                                                    setAreYouSureClearAllModalOpen(true);

                                                                }}
                                                                style={{ float: "right" }}
                                                            >
                                                                Clear All
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col style={{ paddingLeft: "10px" }}>
                                                        <Form.Item>
                                                            <Button disabled={!enableSubmitButton} type="primary" htmlType="submit" style={{ float: "right" }}>
                                                                Submit
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col style={{ paddingLeft: "10px" }}>
                                                        <Form.Item>
                                                            <Button type="primary"
                                                                onClick={() => {
                                                                    regenerateAssembly(null);
                                                                }}
                                                                style={{ float: "right" }}
                                                            >
                                                                Validate
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {/* <Button icon={<EditOutlined />} onClick={() => setShowModalFromSheet(true)}>
                                                        Fill from Module Twin spreadsheet
                                                    </Button> */}
                                            <Card title="General" style={{ margin: "10px 0" }} headStyle={{ backgroundColor: "#f0f0f0" }}>
                                                <Row gutter={8} style={{ paddingBottom: "20px" }}>
                                                    <Col style={{ paddingRight: "30px" }}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }}>System Year</span>}
                                                            name={"hardwareYear"}
                                                        >
                                                            <Radio.Group>
                                                                <Radio value={2021}>2021</Radio>
                                                                <Radio value={2022}>2022</Radio>
                                                                <Radio value={2023}>2023</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col style={{ paddingRight: "30px" }} >
                                                        <Form.Item
                                                            name={"binType"}
                                                            label={<span style={{ fontWeight: "bold" }}>Automation Type</span>}
                                                        >
                                                            <Radio.Group name="automationType">
                                                                <Radio value={AutomationType.DriStack}>DriStack</Radio>
                                                                <Radio onClick={handleClickAutoBin} value={AutomationType.AutoBin}>AutoBin</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row style={{ paddingBottom: "20px" }}>
                                                    <Col style={{ paddingRight: "10px" }}>
                                                        <Form.Item
                                                            name={"hasCamera"}
                                                            key={"hasCamera"}
                                                            label={"Bin Has Camera?"}
                                                        >
                                                            <Switch />

                                                        </Form.Item>
                                                    </Col>

                                                    <Col>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }}>Fans have heater?</span>}
                                                            name={"fansHaveHeaters"}
                                                        >
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>
                                                    
                                                </Row>
                                                <Row>
                                                    <Col style={{ paddingRight: "10px" }}>
                                                        <Button onClick={() => setDeviceProfModalOpen(true)}>Device Profiles</Button>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item
                                                        label="IoT Model"
                                                        extra="reliagate or compulab"
                                                        name={'iotModel'}
                                                        rules={[{ required: false, message: 'reliagate or compulab' }]}>
                                                            <Radio.Group name="iotModel">
                                                                <Radio value={IotModel.Reliagate}>Reliagate</Radio>
                                                                <Radio value={IotModel.Compulab_IOTG_IMX8PLUS}>Compulab</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Card>

                                            <Card extra={values.binType === AutomationType.DriStack ? "These are autopopulated from valve heights" : "These heights represent the height to the top of the layer"} 
                                                title="Layers" style={{ margin: "10px 0" }} headStyle={{ backgroundColor: "#f0f0f0" }}>
                                                {InputListForm(["layerTopHeights"], "Layer top heights Ft.", false, null, values.binType === AutomationType.DriStack)}
                                            </Card>

                                            <Card title="Fans" style={{ margin: "10px 0" }} headStyle={{ backgroundColor: "#f0f0f0" }}>
                                                <Form.List name={["fans"]}>
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        <Row style={{ paddingBottom:"20px"}}>
                                                            <Col style={{paddingRight:"10px"}}>
                                                                <span>Enter new fan assembly</span>
                                                            </Col>
                                                            <Col style={{ paddingRight: "10px" }}>
                                                                <Button 
                                                                icon={<MinusCircleOutlined />} 
                                                                onClick={ () => {
                                                                    if(fields.length >= 1){
                                                                        remove(fields.length - 1);
                                                                    }
                                                                }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Form.Item>
                                                                    <Button icon={<PlusCircleOutlined/>} onClick={() => add()}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        {fields.map( (field, index) => {
                                                            return (
                                                                <>
                                                                    <Divider/>
                                                                    <Row style={{ paddingBottom: "10px", justifyContent:"center"}} gutter={2}>
                                                                        <Col>
                                                                            <Form.Item label={<span style={{fontWeight:"bold"}}>Fan {index + 1}</span>} />
                                                                            {/* {<span style={{fontWeight:"bold"}}>Fan {index + 1}:</span>} */}
                                                                        </Col>
                                                                        <Col style={{paddingRight:"15px"}}>
                                                                            <Form.Item
                                                                            key={`${field.name}-bearing`}
                                                                            //layerStartHeights[0]
                                                                            name={[field.name, "bearing"]}
                                                                            label={"Bearing"}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Enter the desired value"
                                                                                }
                                                                            ]}
                                                                            >
                                                                                <InputNumber controls={false} min={0} style={{ width: "50px" }}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col>
                                                                            <Form.Item
                                                                            key={`${field.name}-area`}
                                                                            //layerStartHeights[0]
                                                                            name={[field.name, "inletAreaFtSq"]}
                                                                            label={"Area Ft. Sq."}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Enter the desired value"
                                                                                }
                                                                            ]}
                                                                            >
                                                                                <InputNumber controls={false} min={0} style={{ width: "50px" }}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row style={{paddingBottom:"10px", justifyContent:"center"}}>
                                                                        <Col style={{paddingRight:"10px"}}>
                                                                            <Form.Item label={<span style={{fontWeight:"bold"}}>Sensors</span>} />
                                                                            {/* <span style={{fontWeight:"bold"}}>Sensors</span> */}
                                                                        </Col>
                                                                        <Col style={{paddingRight:"25px"}}>
                                                                            <Form.Item
                                                                            key={`${field.name}-hasPlenumRHTSensor`}
                                                                            //layerStartHeights[0]
                                                                            name={[field.name, "hasPlenumRHTSensor"]}
                                                                            label={"Plenum RHT"}
                                                                            valuePropName="checked">
                                                                                <Checkbox/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col style={{paddingRight:"25px"}}>
                                                                            <Form.Item
                                                                            key={`${field.name}-hasAmbientRHTSensor`}
                                                                            //layerStartHeights[0]
                                                                            name={[field.name, "hasAmbientRHTSensor"]}
                                                                            label={"Ambient RHT"}
                                                                            valuePropName="checked"
                                                                            >
                                                                                <Checkbox/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col style={{paddingRight:"25px"}}>
                                                                            <Form.Item
                                                                            key={`${field.name}-hasPlenumPressureSensor`}
                                                                            name={[field.name, "hasPlenumPressureSensor"]}
                                                                            label={"Pressure"}
                                                                            valuePropName="checked"
                                                                            >
                                                                                <Checkbox/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </>
                                                                );
                                                            })}
                                                    </>
                                                 )}
                                                
                                                </Form.List>
                                            </Card> 

                                            <Card title="GAMs" style={{ margin: "10px 0" }} headStyle={{ backgroundColor: "#f0f0f0" }}>
                                                <Form.Item
                                                    label={"Has Inner GAM?"}
                                                    name={["hasInnerGam"]}
                                                    key={`hasInnerGam`}
                                                >
                                                    <Switch
                                                    onChange={ (val) =>{
                                                        if(val === false){  
                                                            form.setFieldValue(["innerGam", "gamRadiusFt"], null);
                                                            form.setFieldValue(["innerGam", "gamHeightFt"], null);
                                                            form.setFieldValue(["innerGam", "gamBearing"], null);
                                                            form.setFieldValue(["innerGam", "gamBoardNumber"], null);
                                                            form.setFieldValue(["innerGam", "gamPort"], null);
                                                            form.setFieldValue(["innerGam", "hasCO2Sensor"], null);
                                                            form.setFieldValue(["innerGam", "hasRangeFinder"], null);
                                                        }
                                                    }}
                                                    />
                                                </Form.Item>
                                                {
                                                    values.hasInnerGam &&
                                                    <>
                                                        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                            <Col style={{paddingRight:"5px"}}>
                                                                <Form.Item
                                                                    label="GAM Radius Ft."
                                                                    name={["innerGam", "gamRadiusFt"]}
                                                                    key={`innerGam-gamRadiusFt`}
                                                                >
                                                                    <InputNumber style={{ width: "75px" }} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                            <Col style={{ paddingRight: "5px" }}>
                                                                <Form.Item
                                                                    label="GAM Height Ft."
                                                                    name={["innerGam", "gamHeightFt"]}
                                                                    key={`innerGam-gamHeightFt`}
                                                                >
                                                                    <InputNumber style={{ width: "75px" }} />
                                                                </Form.Item>
                                                            </Col>

                                                            <Col style={{ paddingRight: "5px" }}>
                                                                <Form.Item
                                                                    label="GAM Bearing"
                                                                    name={["innerGam", "gamBearing"]}
                                                                    key={`innerGam-gamBearing`}
                                                                >
                                                                    <InputNumber style={{ width: "75px" }} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                            <Col style={{ paddingRight: "5px" }}>
                                                                <Form.Item
                                                                    key={`innerGam-gamBoardNumber`}
                                                                    label={`Controller`}
                                                                    name={["innerGam", "gamBoardNumber"]}
                                                                >
                                                                    <InputNumber />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col style={{ paddingRight: "5px" }}>
                                                                <Form.Item
                                                                    label="Port"
                                                                    name={["innerGam", "gamPort"]}
                                                                    key={`innerGam-gamPort`}
                                                                >
                                                                    <InputNumber style={{ width: "75px" }} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                            <Col>
                                                                <Form.Item
                                                                name={["innerGam", "hasCO2Sensor"]}
                                                                key={`innerGam-hasCO2Sensor`}
                                                                valuePropName="checked"
                                                                >
                                                                    <Checkbox>CO2</Checkbox>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col>
                                                                <Form.Item
                                                                name={["innerGam", "hasRangeFinder"]}
                                                                key={`innerGam-hasRangeFinder`}
                                                                valuePropName="checked"
                                                                >
                                                                    <Checkbox>Range Finder</Checkbox>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }

                                                <Form.Item
                                                    label={"Has Outer GAM?"}
                                                    name={["hasOuterGam"]}
                                                    key={`hasOuterGam`}
                                                >
                                                    <Switch
                                                    onChange={ (val) =>{
                                                        if(val === false){  
                                                            form.setFieldValue(["outerGam", "gamRadiusFt"], null);
                                                            form.setFieldValue(["outerGam", "gamHeightFt"], null);
                                                            form.setFieldValue(["outerGam", "gamBearing"], null);
                                                            form.setFieldValue(["outerGam", "gamBoardNumber"], null);
                                                            form.setFieldValue(["outerGam", "gamPort"], null);
                                                            form.setFieldValue(["outerGam", "hasCO2Sensor"], null);
                                                            form.setFieldValue(["outerGam", "hasRangeFinder"], null);
                                                        }
                                                    }}
                                                    />
                                                </Form.Item>
                                                {
                                                    values.hasOuterGam &&
                                                    <>
                                                        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                            <Col style={{paddingRight:"5px"}}>
                                                                <Form.Item
                                                                    label="GAM Radius Ft."
                                                                    name={["outerGam", "gamRadiusFt"]}
                                                                    key={`outerGam-gamRadiusFt`}
                                                                >
                                                                    <InputNumber style={{ width: "75px" }} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                            <Col style={{ paddingRight: "5px" }}>
                                                                <Form.Item
                                                                    label="GAM Height Ft."
                                                                    name={["outerGam", "gamHeightFt"]}
                                                                    key={`outerGam-gamHeightFt`}
                                                                >
                                                                    <InputNumber style={{ width: "75px" }} />
                                                                </Form.Item>
                                                            </Col>

                                                            <Col style={{ paddingRight: "5px" }}>
                                                                <Form.Item
                                                                    label="GAM Bearing"
                                                                    name={["outerGam", "gamBearing"]}
                                                                    key={`outerGam-gamBearing`}
                                                                >
                                                                    <InputNumber style={{ width: "75px" }} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                            <Col style={{ paddingRight: "5px" }}>
                                                                <Form.Item
                                                                    key={`outerGam-gamBoardNumber`}
                                                                    label={`Controller`}
                                                                    name={["outerGam", "gamBoardNumber"]}
                                                                >
                                                                    <InputNumber />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col style={{ paddingRight: "5px" }}>
                                                                <Form.Item
                                                                    label="Port"
                                                                    name={["outerGam", "gamPort"]}
                                                                    key={`outerGam-gamPort`}
                                                                >
                                                                    <InputNumber style={{ width: "75px" }} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                            <Col>
                                                                <Form.Item
                                                                name={["outerGam", "hasCO2Sensor"]}
                                                                key={`outerGam-hasCO2Sensor`}
                                                                valuePropName="checked"
                                                                >
                                                                    <Checkbox>CO2</Checkbox>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col>
                                                                <Form.Item
                                                                name={["outerGam", "hasRangeFinder"]}
                                                                key={`outerGam-hasRangeFinder`}
                                                                valuePropName="checked"
                                                                >
                                                                    <Checkbox>Range Finder</Checkbox>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }
                                            </Card>

                                            <Card
                                                title="Rings" headStyle={{ backgroundColor: "#f0f0f0" }}
                                            >
                                                <Form.List name={"rings"}>
                                                    {(fields, { add, remove }) => {

                                                        return (
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <Row>
                                                                    <PlusCircleOutlined
                                                                        style={{ margin: "8px" }}
                                                                        onClick={() => {
                                                                            add();
                                                                        }}
                                                                    />
                                                                    {fields.length > 0 ? (
                                                                        <MinusCircleOutlined
                                                                            style={{ margin: "8px" }}
                                                                            onClick={() => {
                                                                                remove(fields.length - 1);
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </Row>

                                                                {fields.map((field, index) => {


                                                                    return (
                                                                        <Card title={`Ring ${index}`} style={{ margin: "10px 0", marginLeft: "25px", paddingBottom: "25px" }} headStyle={{ backgroundColor: "#f0f0f0" }}>
                                                                            <div style={{ margin: "10px 0" }}>
                                                                                <Row gutter={16} style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                                                    <Col>
                                                                                        <Form.Item
                                                                                            name={[field.name, "ringNumber"]}
                                                                                            key={`ringNumber-${field.name}`}
                                                                                            initialValue={index}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col style={{ paddingRight: "5px" }}>
                                                                                        <Form.Item
                                                                                            label="Radius Ft."
                                                                                            name={[field.name, "radiusFt"]}
                                                                                            key={`radiusFt-${field.name}`}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: "Please enter the ring radius"
                                                                                                }
                                                                                            ]}
                                                                                        >
                                                                                            <InputNumber style={{ width: "75px" }} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    {
                                                                                        values.binType === AutomationType.DriStack &&
                                                                                        <Col style={{ paddingRight: "5px" }}>
                                                                                            <Form.Item
                                                                                                label="Stack Height Ft."
                                                                                                name={[field.name, "stackHeightFt"]}
                                                                                                key={`stackHeightFt-${field.name}`}
                                                                                            >
                                                                                                <InputNumber style={{ width: "75px" }} />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                    }

                                                                                    <Col style={{ paddingRight: "5px" }}>
                                                                                        <Form.Item
                                                                                            label="Cable Length Ft."
                                                                                            name={[field.name, "sensorCableLengthFt"]}
                                                                                            key={`sensorCableLengthFt-${field.name}`}
                                                                                        >
                                                                                            <InputNumber style={{ width: "75px" }} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            {
                                                                                values.binType === AutomationType.DriStack &&
                                                                                <div style={{ margin: "10px 0" }}>

                                                                                    {InputListForm([field.name, "stackBearings"], "Stack bearing", true, ["valveHeights"])}

                                                                                    {InputListForm([field.name, "valveHeights"], "Valve height Ft.", true, null)}

                                                                                    <Divider />
                                                                                </div>
                                                                            }

                                                                            <div style={{ margin: "10px 0" }}>
                                                                                {InputListForm([field.name, "opiCableBearings"], "OPI cable bearing", true, ["opiSensorHeights", "opiCableMappings"])}
                                                                                {
                                                                                    (values.rings?.[field.name]?.opiCableBearings?.length ?? 0) > 0 &&
                                                                                    <>
                                                                                        {InputListForm([field.name, "opiSensorHeights"], "OPI sensor height Ft.", true, null)}

                                                                                        <Form.List name={[field.name, "opiCableMappings"]}>
                                                                                            {(fields, { add, remove }) => (
                                                                                                <>
                                                                                                    <Row style={{ paddingBottom: "20px" }}>
                                                                                                        <Col style={{ paddingRight: "10px" }}>
                                                                                                            <span>OPI Cable mappings</span>
                                                                                                        </Col>
                                                                                                        <Col style={{ paddingRight: "10px" }}>
                                                                                                            <Button
                                                                                                                icon={<MinusCircleOutlined />}
                                                                                                                onClick={() => {
                                                                                                                    if (fields.length > 0) {
                                                                                                                        remove(fields.length - 1);
                                                                                                                    }
                                                                                                                }}
                                                                                                            />

                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Form.Item>
                                                                                                                <Button icon={<PlusCircleOutlined />} onClick={() => add()} />
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <Row>
                                                                                                        {fields.map((field, index) => {

                                                                                                            return (
                                                                                                                <div style={{ marginLeft: "25px" }}>
                                                                                                                    <Row>
                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`cableNumber-${field.name}`}
                                                                                                                                    label={"Cable"}
                                                                                                                                    name={[field.name, "cableNumber"]}
                                                                                                                                    rules={[
                                                                                                                                        {
                                                                                                                                            required: true
                                                                                                                                        }]}
                                                                                                                                >

                                                                                                                                    <InputNumber min={0} style={{ width: "100px" }} />

                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`boardNumber-${field.key}`}
                                                                                                                                    label={`Controller`}
                                                                                                                                    name={[field.name, "boardNumber"]}
                                                                                                                                    rules={[
                                                                                                                                        {
                                                                                                                                            required: true,
                                                                                                                                            message: "Enter the controller name"
                                                                                                                                        }]}

                                                                                                                                >
                                                                                                                                    <InputNumber min={0} style={{ width: "75px" }} />
                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>

                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`port-${field.name}`}
                                                                                                                                    label={"Port"}
                                                                                                                                    name={[field.name, "port"]}
                                                                                                                                    rules={[
                                                                                                                                        {
                                                                                                                                            required: true
                                                                                                                                        }
                                                                                                                                    ]}
                                                                                                                                >
                                                                                                                                    <InputNumber min={0} style={{ width: "100px" }} />
                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </Row>
                                                                                                                    <Divider />
                                                                                                                </div>
                                                                                                            );
                                                                                                        })}
                                                                                                    </Row>
                                                                                                </>
                                                                                            )}
                                                                                        </Form.List>
                                                                                    </>
                                                                                }
                                                                            </div>

                                                                            {/* BinSense */}
                                                                            <div style={{ margin: "10px 0" }}>
                                                                                {InputListForm([field.name, "binSenseCableBearings"], "BinSense cable bearing", true, ["binSenseSensorHeights", "binSenseCableMappings"])}
                                                                                {
                                                                                    (values.rings?.[field.name]?.binSenseCableBearings?.length ?? 0) > 0 &&
                                                                                    <>
                                                                                        {InputListForm([field.name, "binSenseSensorHeights"], "BinSense sensor height Ft.", true, null)}

                                                                                        <Form.List name={[field.name, "binSenseCableMappings"]}>
                                                                                            {(fields, { add, remove }) => (
                                                                                                <>
                                                                                                    <Row style={{ paddingBottom: "20px" }}>
                                                                                                        <Col style={{ paddingRight: "10px" }}>
                                                                                                            <span>BinSense Cable mappings</span>
                                                                                                        </Col>
                                                                                                        <Col style={{ paddingRight: "10px" }}>
                                                                                                            <Button
                                                                                                                icon={<MinusCircleOutlined />}
                                                                                                                onClick={() => {
                                                                                                                    if (fields.length > 0) {
                                                                                                                        remove(fields.length - 1);
                                                                                                                    }
                                                                                                                }}
                                                                                                            />

                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Form.Item>
                                                                                                                <Button icon={<PlusCircleOutlined />} onClick={() => add()} />
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <Row>
                                                                                                        {fields.map((field, index) => {

                                                                                                            return (
                                                                                                                <div style={{ marginLeft: "25px" }}>
                                                                                                                    <Row>
                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`cableNumber-${field.name}`}
                                                                                                                                    label={"Cable"}
                                                                                                                                    name={[field.name, "cableNumber"]}
                                                                                                                                    rules={[
                                                                                                                                        {
                                                                                                                                            required: true
                                                                                                                                        }]}
                                                                                                                                >

                                                                                                                                    <InputNumber min={0} style={{ width: "100px" }} />

                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`boardNumber-${field.key}`}
                                                                                                                                    label={`Controller`}
                                                                                                                                    name={[field.name, "boardNumber"]}
                                                                                                                                    rules={[
                                                                                                                                        {
                                                                                                                                            required: true,
                                                                                                                                            message: "Enter the controller name"
                                                                                                                                        }]}

                                                                                                                                >
                                                                                                                                    <InputNumber min={0} style={{ width: "75px" }} />
                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>

                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`port-${field.name}`}
                                                                                                                                    label={"Port"}
                                                                                                                                    name={[field.name, "port"]}
                                                                                                                                    rules={[
                                                                                                                                        {
                                                                                                                                            required: true
                                                                                                                                        }
                                                                                                                                    ]}
                                                                                                                                >
                                                                                                                                    <InputNumber min={0} style={{ width: "100px" }} />
                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </Row>
                                                                                                                    <Divider />
                                                                                                                </div>
                                                                                                            );
                                                                                                        })}
                                                                                                    </Row>
                                                                                                </>
                                                                                            )}
                                                                                        </Form.List>
                                                                                    </>
                                                                                }
                                                                            </div>

                                                                            <div style={{ margin: "10px 0" }}>
                                                                                {InputListForm([field.name, "tempCableBearings"], "Temp. Cable Bearings", true, ["thermoucoupleHeights", "tempCableMappings"])}
                                                                                {
                                                                                    (values.rings?.[field.name]?.tempCableBearings?.length ?? 0) > 0 &&
                                                                                    <>
                                                                                        {InputListForm([field.name, "thermoucoupleHeights"], "Thermocouple height Ft.", true, null)}
                                                                                        <Form.List name={[field.name, "tempCableMappings"]}>
                                                                                            {(fields, { add, remove }) => (
                                                                                                <>
                                                                                                    <Row style={{ paddingBottom: "20px" }}>
                                                                                                        <Col style={{ paddingRight: "10px" }}>
                                                                                                            <span>Temp Cable mappings</span>
                                                                                                        </Col>
                                                                                                        <Col style={{ paddingRight: "10px" }}>
                                                                                                            <Button
                                                                                                                icon={<MinusCircleOutlined />}
                                                                                                                onClick={() => {
                                                                                                                    if (fields.length > 0) {
                                                                                                                        remove(fields.length - 1);
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Form.Item>
                                                                                                                <Button icon={<PlusCircleOutlined />} onClick={() => add()} />
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <Row>
                                                                                                        {fields.map((field, index) => {

                                                                                                            return (
                                                                                                                <div style={{ marginLeft: "25px" }}>
                                                                                                                    <Row>
                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`cableNumber-${field.name}`}
                                                                                                                                    label={"Cable"}
                                                                                                                                    name={[field.name, "cableNumber"]}
                                                                                                                                    rules={[
                                                                                                                                        {
                                                                                                                                            required: true
                                                                                                                                        }
                                                                                                                                    ]}
                                                                                                                                >
                                                                                                                                    <InputNumber min={0} style={{ width: "75px" }} />
                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>

                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`sensorStartNumber-${field.name}`}
                                                                                                                                    label={"Sensor Start"}
                                                                                                                                    name={[field.name, "sensorStartNumber"]}
                                                                                                                                >
                                                                                                                                    <InputNumber min={0} style={{ width: "75px" }} />
                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`sensorEndNumber-${field.name}`}
                                                                                                                                    label={"Sensor End"}
                                                                                                                                    name={[field.name, "sensorEndNumber"]}
                                                                                                                                >
                                                                                                                                    <InputNumber min={0} style={{ width: "75px" }} />
                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>

                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`boardNumber-${field.key}`}
                                                                                                                                    label={`Controller`}
                                                                                                                                    name={[field.name, "boardNumber"]}
                                                                                                                                    rules={[
                                                                                                                                        {
                                                                                                                                            required: true,
                                                                                                                                            message: "Enter the controller name"
                                                                                                                                        }]}
                                                                                                                                >
                                                                                                                                    <InputNumber min={0} style={{ width: "75px" }} />
                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>

                                                                                                                        <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                    key={`port-${field.name}`}
                                                                                                                                    label={"Port"}
                                                                                                                                    name={[field.name, "port"]}
                                                                                                                                    rules={[
                                                                                                                                        {
                                                                                                                                            required: true
                                                                                                                                        }]}
                                                                                                                                >
                                                                                                                                    <InputNumber min={0} style={{ width: "75px" }} />
                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </Row>
                                                                                                                    <Divider />
                                                                                                                </div>
                                                                                                            );
                                                                                                        })}
                                                                                                    </Row>
                                                                                                </>
                                                                                            )}
                                                                                        </Form.List>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            {/* </div> */}
                                                                        </Card>
                                                                    );
                                                                })}

                                                                <Row style={{ justifyContent: "right" }}>
                                                                    <Col style={{ paddingLeft: "10px" }}>
                                                                        <Form.Item>
                                                                            <Button type="primary"
                                                                                onClick={() => {
                                                                                    setAreYouSureClearAllModalOpen(true);
                                                                                }}
                                                                                style={{ float: "right" }}
                                                                            >
                                                                                Clear All
                                                                            </Button>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col style={{ paddingLeft: "10px", paddingBottom: "10px" }}>
                                                                        <RestartDriStackModuleButton deviceId={chosenDeviceID} />
                                                                    </Col>
                                                                    <Col style={{ paddingLeft: "10px" }}>
                                                                        <Form.Item>
                                                                            <Button disabled={!enableSubmitButton} type="primary" htmlType="submit" style={{ float: "right" }}>
                                                                                Submit
                                                                            </Button>
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col style={{ paddingLeft: "10px" }}>
                                                                        <Form.Item>
                                                                            <Button
                                                                                type="primary"
                                                                                onClick={() => {
                                                                                    regenerateAssembly(null)
                                                                                    // regenerateAssembly(null)
                                                                                }}
                                                                                style={{ float: "right" }}
                                                                            >
                                                                                Validate
                                                                            </Button>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    }}
                                                </Form.List>
                                            </Card>

                                        </Form>
                                    </TabPane>
                                    {/* <TabPane tab="Advanced" key="2">

                                        <h1>Coming soon....</h1>
                                    </TabPane> */}
                                </Tabs>
                            </Col>

                            <Col span={11}>{getReviewSection()}</Col>
                        </Row>
                    </div>
                )}

                <Modal
                    title="Device Profile"
                    cancelButtonProps={{ style: { display: "none" } }} // or { hidden: true }
                    open={deviceProfileModalOpen}
                    onOk={() => setDeviceProfModalOpen(false)}
                    onCancel={() => setDeviceProfModalOpen(false)}
                >
                    <DeviceProfileTable
                        deviceProfiles={(form.getFieldValue(["binAssembly", "devices"]) ?? []) as DeviceProfileDTO[]}
                        pcbLookups={allPCBs}
                        onUpdateDevices={(assemb: DeviceProfileDTO[]) => {
                            form.setFieldValue(["binAssembly", "devices"], assemb);
                        }}

                    />
                </Modal>

                <DeviceUpdateModalV2
                    open={showModalFromSheet}
                    submittalForm={form}
                    onOk={() => setShowModalFromSheet(false)}
                    onCancel={() => setShowModalFromSheet(false)}
                    createSubmittalFromText={createSubmittalFromText}
                    deviceId={chosenDeviceID}
                />

                <Modal
                    open={areYouSureClearAllModalOpen}
                    centered
                    closable={false}
                    footer={null}
                    width={300}
                    style={{ textAlign: "center" }}
                >
                    <h2>Are you sure you want to clear all fields?</h2>
                    <Button onClick={() => setAreYouSureClearAllModalOpen(false)}
                        style={{ marginRight: "10px" }}
                    >
                        No
                    </Button>
                    <Button onClick={() => {
                        form.resetFields()
                        setAreYouSureClearAllModalOpen(false);
                    }}
                        style={{ marginRight: "10px" }}
                    >
                        Yes
                    </Button>
                </Modal>
                {/* <Modal open={getBinAssemblySubmittalQuery.isFetching || getBinAssemblySubmittalQuery.isLoading || loading} centered closable={false} footer={null} width={100} style={{ textAlign: "center" }}>
                    <Spin size="large" />
                </Modal> */}
            </Spin>
        </Layout.Content>
    );
};
