import React, { useMemo } from "react";
import BinDTO from "src/models/BinDTO";
import { BinInfoContext, useBinInfoContext } from "src/queries/BinInfoContext";
import { useUploadBinStateToAzure } from "src/queries/useUploadBinStateToAzure";

interface ForceNewBinStateUploadContextValue {
  refresh: () => Promise<void>,
  loading: boolean,
}

const defaultValues: ForceNewBinStateUploadContextValue = {
  refresh: () => Promise.resolve(),
  loading: false,
}

export const ForceNewBinStateUploadContext = React.createContext<ForceNewBinStateUploadContextValue>(defaultValues);


export const useForceNewBinStateUploadContext = () => {
  const binInfo = React.useContext(BinInfoContext);
  const updateRequest = React.useContext(ForceNewBinStateUploadContext);
  if (updateRequest == null) {
    throw new Error("no value provided for ForceNewBinStateUploadContext");
  }

  const uploadBinStateToAzureMutation = useUploadBinStateToAzure(binInfo?.deviceId!);

  return useMemo(() => {
    return {
    refresh: updateRequest?.refresh,
    isLoading: updateRequest?.loading,
  };
}, [updateRequest]);
};
