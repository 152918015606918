import { BaseChartProps } from "./TemperatureCableChart";
import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "antd";
import dayjs from "dayjs";
import { round } from "lodash-es";
import React, { useState, useCallback, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { queryClient } from "src";
import BinApiService from "src/api/BinApiService";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { RangeValue } from "./ChartContainer";
import { ChartDatePicker } from "./Co2Chart";
import { ChartData, ChartDataset } from "chart.js";
import FanHeaterDataPointDTO from "src/models/FanHeaterDataPointDTO";
import { any } from "prop-types";
import { fanStatusColor, heaterStatusColor, tooltipItemRound, useFanHeaterChangeHistory } from "./shared";
import { useModeChangeHistory } from "./LayerMCHistoryChart";

enum FanStatusEnum {
  ON = "ON",
  OFF = "OFF",
}

// max 12 fans
const manualColors: Array<string> = ["#cd5051", "#813332", "#51cccc", "#328180", "#8ecc50", "#588133", "#8f51cc", "#583381", "#cdaf51", "#806c33", "#51cd71", "#338144"];


export const FansChart = (props: BaseChartProps & { hasHeater: boolean }) => {


  const currentDate = dayjs();
  const priorDate = currentDate.subtract(7, 'days');

  const [value, setValue] = useState<RangeValue>([priorDate, currentDate]);

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    setValue(values);
  }, []);

  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "Fan&Heater", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, keepPreviousData: true, retry: 0, queryFn: async (q) => {
      const now = value?.[1];
      const priorMonth = value?.[0];
      const results = await BinApiService.getFanHeaterChart(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
      queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });

      return results;
    }, enabled: (props.binId ?? 0) > 0,
  });




  // @ts-ignore string | DayJS(not possible)
  const fansDatasets: Array<ChartDataset<'line', { x: string, fanAmps: number }[]>> = chartQuery.data?.fans?.map((fan, index) => {

    return {
      data: fan.data!, label: fan.label!, indexAxis: 'x', yAxisID: 'y2', parsing: {
        yAxisKey: 'fanAmps',
      },
      backgroundColor: manualColors[index],
      borderColor: manualColors[index],
    };
  }) ?? [];

  const anyFanOnDataset = useMemo(() => {
    if (chartQuery.data == null) {
      return [];
    }

    let newData: Array<{ x: string, y: FanStatusEnum | null }> = chartQuery.data.fanHeaterAnyOnStatus?.data?.map((point) => {
      let fanOnStatus = null;
      switch (point.anyFanIsOn) {
        case true: {
          fanOnStatus = FanStatusEnum.ON;
          break;
        }
        case false: {
          fanOnStatus = FanStatusEnum.OFF;
          break;
        }
        case null: {
          fanOnStatus = null;
          break;
        }
      }
      return { x: point.x as string, y: fanOnStatus };
    }) ?? [];
    return newData;


  }, [chartQuery.data]);



  // @ts-ignore string | dayJS not possible
  const heatersDatasets: Array<ChartDataset<'line'>> = chartQuery.data?.fans?.flatMap((fan, index) => {
    if (props.hasHeater === false) {
      return [];
    }

    return [{
      data: fan.data!, label: `Heater ${fan.label!}`, indexAxis: 'x', yAxisID: 'yheater2', parsing: {
        yAxisKey: 'heaterAmps',
      },
      backgroundColor: manualColors[index],
      borderColor: manualColors[index],
    }];
  }) ?? [];

  const anyHeaterOnDataset = useMemo(() => {
    if (chartQuery.data == null) {
      return [];
    }

    let newData: Array<{ x: string, y: FanStatusEnum | null }> = chartQuery.data.fanHeaterAnyOnStatus?.data?.map((point) => {
      let heaterOnStatus = null;
      switch (point.anyHeaterIsOn) {
        case true: {
          heaterOnStatus = FanStatusEnum.ON;
          break;
        }
        case false: {
          heaterOnStatus = FanStatusEnum.OFF;
          break;
        }
        case null: {
          heaterOnStatus = null;
          break;
        }
      }
      return { x: point.x as string, y: heaterOnStatus };
    }) ?? [];
    return newData;


  }, [chartQuery.data]);



  const datasets: Array<ChartDataset<'line'>> = [];

  // @ts-ignore
  datasets.push(...fansDatasets);

  if (props.hasHeater) {
    datasets.push(...heatersDatasets);



  }

  datasets.push(
    // @ts-ignore
    {
      // @ts-ignore
      data: anyFanOnDataset,
      label: "Fan Status", backgroundColor: fanStatusColor, borderColor: fanStatusColor, yAxisID: "y", indexAxis: "x", stepped: true
    },
  );
  if (props.hasHeater) {
    datasets.push(
      // @ts-ignore
      { data: anyHeaterOnDataset, label: "Heater Status", backgroundColor: heaterStatusColor, borderColor: heaterStatusColor, yAxisID: "yheater", indexAxis: "x", stepped: true },
    );
  }

  const fanHeaterChangeyAxes = useMemo(() => ['y', 'y2', 'yheater', 'yheater2'], []);
  const modeChangeAxes = useMemo(() => ['y'], []);

  const modeHistory = useModeChangeHistory(props.binId, value?.[0]!, value?.[1]!, {yAxes: modeChangeAxes});
  const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1], {yAxes: fanHeaterChangeyAxes});


  return <div>

    <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />
    <Skeleton loading={chartQuery.data == null}>
      {chartQuery.data != null && <section style={{ width: "100%", minHeight: "75vh" }}>
        <Line options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          layout: {
            padding: {
              top: 16,
              bottom: 16,
            },
          },
          datasets: {
            line: {
              pointRadius: 0 // disable for all `'line'` datasets
            }
          },
          scales: {
            x: {
              type: "time",
              time: {
                minUnit: 'day',
              },
            },

            yheater: {
              position: 'left',
              offset: true,
              type: "category",
              labels: ['ON', "OFF"],
              display: props.hasHeater,
              stack: "demo",
              stackWeight: props.hasHeater ? 1 : 0,
              title: {
                font: {
                  size: 16,
                },
                display: true,
                text: "Heater Status",
              },
            },
            yheater2: {
              stack: "demo",
              position: "left",
              beginAtZero: true,
              display: props.hasHeater,
              suggestedMax: 2,
              title: {
                font: {
                  size: 16,
                },
                display: true,
                text: "Heater Amps",
              },
              stackWeight: props.hasHeater ? 2 : 0,
            },

            y: {
              position: 'left',
              offset: true,
              type: "category",
              labels: ['ON', "OFF"],
              stack: "demo",
              stackWeight: 1,
              title: {
                font: {
                  size: 16,
                },
                display: true,
                text: "Fan Status",
              },
            },
            y2: {
              stack: "demo",
              position: "left",
              beginAtZero: true,
              suggestedMax: 50,
              title: {
                font: {
                  size: 16,
                },
                display: true,
                text: "Fan Amps",
              },
              stackWeight: 2,
            },

          },


          plugins: {
            annotation: {
              annotations: [
                ...modeHistory.annotations,
                ...fanHeaterChangeHistory.annotations,
              ],
            },
            legend: {
              position: 'top' as const,
            },
            title: {
              display: true,
              text: props.hasHeater ? 'Fan & Heater Status' : 'Fan Status',
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: tooltipItemRound,
              },
            },
          },
        }} data={{ datasets: datasets }} />
      </section>}
    </Skeleton>
  </div>

}
