// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';
import BinInfoDTO from './BinInfoDTO';

interface GrowerDTO {
    growerID: number;
    growerName: string | null;
    isActive: boolean;
    isExternal: boolean;
    externalId: string | null;
    growerEmail: string | null;
    cornTargetPrice: number | null;
    cornDefaultTargetMoisture: number | null;
    notes: string | null;
    bins: BinInfoDTO[] | null;
}
const GrowerDTO: InterfaceConstructor<GrowerDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            growerID: 0,
            growerName: null,
            isActive: false,
            isExternal: false,
            externalId: null,
            growerEmail: null,
            cornTargetPrice: null,
            cornDefaultTargetMoisture: null,
            notes: null,
            bins: [],
        },
        initValues);
    }
};

export default GrowerDTO;
