// Constants TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
export default class Role {
    public static readonly ADMIN: string = 'Admin';
    public static readonly GROWER: string = 'Grower';
    public static readonly POWERUSER: string = 'PowerUser';
    public static readonly SUPPORT: string = 'Support';
    public static readonly DEALER: string = 'Dealer';
    public static readonly DSM: string = 'DSM';
    public static readonly HEMPDSM: string = 'Hemp DSM';
    public static readonly SUPERADMIN: string = 'Super Admin';
    public static readonly VIEWER: string = 'Viewer';
}
