// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';

interface DesiredDynamicMCDTO {
    mcOffsetsByLayer: number[];
    mcOffsetsByLayerString: string | null;
    gainAH: number;
    gainCFM: number;
    gainByLayer: number[];
    gainByLayerString: string | null;
}
const DesiredDynamicMCDTO: InterfaceConstructor<DesiredDynamicMCDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            mcOffsetsByLayer: [],
            mcOffsetsByLayerString: null,
            gainAH: 0,
            gainCFM: 0,
            gainByLayer: [],
            gainByLayerString: null,
        },
        initValues);
    }
};

export default DesiredDynamicMCDTO;
