import React, { useState, useEffect } from 'react';
import { Space, Table, notification, DatePicker, Select, Spin, Button } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import type { ColumnsType } from 'antd/es/table';
import type { SelectProps } from 'antd';
import BinApiService from 'src/api/BinApiService';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import AlertHistoryRequestDTO from 'src/models/AlertHistoryRequestDTO';
import { DefaultOptionType } from 'antd/es/select';
import { ExtendedColumnType, useColumnExpansion } from 'src/pages/binOverview/BinCommander';

interface AlertsTableProps {
    growerId: number | undefined;
}

interface AlertDataType {
    key: number;
    binId: number;
    binName: string | null;
    alertTypeId: number;
    alertTypeName: string | null;
    message: string | null;
    createdDateTime: string | Dayjs;
    wasSent: string | null;
}

const columns: Array<ExtendedColumnType<AlertDataType>> = [
    {
        title: 'Bin Name',
        dataIndex: 'binName',
        key: 'binName',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Alert Type Name',
        dataIndex: 'alertTypeName',
        key: 'alertTypeName',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
    },
    {
        title: 'Date and Time',
        key: 'createdDateTime',
        dataIndex: 'createdDateTime',
        sortType: "date",
        defaultSortOrder: "descend",
        render: (text) => <a>{dayjs(text).format('MMMM DD, YYYY hh:mm A')}</a>
    },
    {
        title: 'Notification sent?',
        key: 'wasSent',
        dataIndex: 'wasSent',
        filters: [
            {
                text: "Yes",
                value: "Yes"
            },
            {
                text: "No",
                value: "No"
            }
        ],
        onFilter: (value, record) => value === record.wasSent,
        defaultFilteredValue: ["Yes"]
    },
];


export const AlertsHistoryTable = (props: AlertsTableProps) => {
    const queryClient = useQueryClient();
    const { RangePicker } = DatePicker;
    const [selectedDateRange, setSelectedDateRange] = useState([dayjs().subtract(3, 'days'), dayjs()]);
    const [binNameOptions, setBinNameOptions] = useState<DefaultOptionType[]>([]);
    const [allAlertData, setAllAlertdata] = useState<AlertDataType[]>([]);
    const [selectedAlertData, setSelectedAlertdata] = useState<AlertDataType[]>([]);

    const alertHistoryQuery = useQuery({
        queryKey: ["alertHistoryQuery", { growerId: props.growerId, selectedDateRange: selectedDateRange }],
        retry: false,
        enabled: selectedDateRange.every(val => val != null),
        queryFn: async (q) => {
            const newAlertData: AlertDataType[] = [];
            try {
                const dto: AlertHistoryRequestDTO = {
                    growerId: props.growerId ?? 0,
                    allBins: false,
                    from: selectedDateRange[0].toISOString(),
                    to: selectedDateRange[1].toISOString(),
                }

                const response = await BinApiService.getBinAlertHistory(dto, q.signal);
                response.binAlertsHistory?.forEach((alertHistoryDto) => {
                    let i = 0;
                    alertHistoryDto.alerts?.forEach((alert) => {
                        const a: AlertDataType = {
                            key: i++,
                            binId: alert.binId,
                            binName: alert.binName,
                            alertTypeId: alert.alertTypeId,
                            alertTypeName: alert.alertTypeName,
                            message: alert.message,
                            createdDateTime: alert.createdDateTime,
                            wasSent: alert.wasSent === true ? 'Yes' : 'No',
                        };
                        newAlertData.push(a);
                    });
                });

                setAllAlertdata(newAlertData);
                setSelectedAlertdata(newAlertData);

                const bins: DefaultOptionType[] = [];
                response.bins?.forEach( (bin) =>{
                    const opt: DefaultOptionType = {
                        label: bin.name,
                        value: bin.id
                    }
                    bins.push(opt);
                })
                setBinNameOptions(bins);

            } catch (err) {
                console.error('Error fetching alert history list', err);
                notification.error({
                    message: err?.title,
                    description: err?.detail ?? err.message,
                });
                throw err;
            }
            return [];
        }
    });

    const onSelectChange = (e: number[]) => {

        if (e.length === 0) {
            setSelectedAlertdata(allAlertData);
        } else {
            var selectedBinsAlertData = allAlertData.filter(a => e.includes(a.binId) === true);
            setSelectedAlertdata(selectedBinsAlertData);
        }
    }

    const onChangeDate = (value: [dayjs.Dayjs, dayjs.Dayjs]) => {
        if (value) {
            setSelectedDateRange(value);
        }
      };

      const transformedColumns = useColumnExpansion({columns: columns, datasource: selectedAlertData})


    return (
        <>
            <Space direction="horizontal" size={50} style={{ width: '100%' }}>

                <RangePicker
                    // showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onChangeDate}
                    defaultValue={[
                        dayjs(selectedDateRange[0]), // Convert dayjs object to moment
                        dayjs(selectedDateRange[1]), // Convert dayjs object to moment
                      ]
                    }
                    // onOk = {onChangeDate}
                />

                <Select
                    mode="multiple"
                    placeholder="Select Bins Here"
                    style={{ width: 300 }}
                    options={binNameOptions}
                    onChange={onSelectChange}
                />
                
            </Space>
            <Table columns={transformedColumns} dataSource={selectedAlertData} />
        </>
    );
};