import { useMutation } from "@tanstack/react-query";
import BinApiService from "src/api/BinApiService";


export const useUploadBinStateToAzure = (deviceId: string) => {
    return useMutation({
      mutationFn: async () => {
        return await BinApiService.uploadBinStateToAzure(deviceId);
      }
    });
  }
  