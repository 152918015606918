import * as React from 'react';
import Home from './Home';
import { RedirectAs404 } from 'src/utils/RouteErrors';
import { Route, Switch } from 'react-router-dom';

const Dashboard = () => (
    <Switch>
        <Route path="/" component={Home} />

        {/* This needs to be the last item */}
        <Route component={RedirectAs404} />
    </Switch>
);

export default Dashboard;
