import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "antd";
import React from "react";
import { Line } from "react-chartjs-2";
import BinApiService from "src/api/BinApiService";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { plenumFanDatasetColor } from "./AmbientPlenumChart";
import { useModeChangeHistory } from "./LayerMCHistoryChart";
import { tooltipItemRound, useFanHeaterChangeHistory } from "./shared";
import { BaseChartProps } from "./TemperatureCableChart";

export const MoistureRemovalRHChart = (props: BaseChartProps & { shrinkHeight?: boolean }) => {
    const queryClient = useQueryClient();
    const value = props.value;

    const chartQuery = useQuery({
        queryKey: [...binDBKeys.bin(props.binId), "charts", "MoistureRemoval", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, keepPreviousData: true, retry: 0, queryFn: async (q) => {
            const now = value?.[1];
            const priorMonth = value?.[0];
            const results = await BinApiService.getMoistureRemovalData(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
            queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });

            return results;
        }, enabled: (props.binId ?? 0) > 0,
    });

    const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
    const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);

    return <>

        <Skeleton loading={chartQuery.isLoading}>
            <section style={{ width: "100%", minHeight: props.chartHeight ?? "200px" }}>
                {chartQuery.data && <Line options={{
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    datasets: {
                        line: {
                            pointRadius: 0 // disable for all `'line'` datasets
                        }
                    },
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                minUnit: 'day',
                            },
                        },
                        y: {
                            type: 'linear',
                            position: 'left',
                            title: {
                                font: {
                                    size: 16,
                                },
                                display: true,
                                text: "RH %",
                            },
                        },
                    },
                    // parsing: false,
                    plugins: {
                        annotation: {
                            annotations: [
                                ...modeHistory.annotations,
                                ...fanHeaterChangeHistory.annotations,
                                {
                                    type: 'line',
                                    yMin: 0,
                                    yMax: 0,
                                    borderColor: '#333',
                                    borderWidth: 1,
                                    drawTime: 'beforeDatasetsDraw',
                                }
                            ],
                        },
                        legend: {
                            position: 'top' as const,
                            display: true,
                        },
                        title: {
                            display: true,
                            text: 'Relative Humidity (%)',
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                label: tooltipItemRound,
                            }
                        },
                    },
                }} data={{
                    datasets: [

                        {
                            type: 'line',
                            data: chartQuery.data, label: "Plenum", indexAxis: 'x',
                            parsing: { yAxisKey: 'plenumRH', },
                            spanGaps: true,
                            borderColor: plenumFanDatasetColor,
                            backgroundColor: plenumFanDatasetColor,
                        },
                        {
                            type: 'line',
                            data: chartQuery.data, label: "Headspace", indexAxis: 'x',
                            parsing: { yAxisKey: 'headspaceRH', },
                            spanGaps: true,
                        },
                        {
                            type: 'line',
                            data: chartQuery.data, label: "RH removal (headspace RH - plenum RH) (%)", indexAxis: 'x',
                            parsing: { yAxisKey: 'rhRemoval', },
                            backgroundColor: 'purple',
                            borderColor: 'purple',
                            spanGaps: false,
                        },
                    ]
                }} />}
            </section>
        </Skeleton>

    </>;
}