import AdminApiService from "src/api/AdminApiService";
import { useQuery } from "@tanstack/react-query";
import PCBLookupDTO from "src/models/PCBLookupDTO";
import { QueryTypes } from "./shared";
import { queryClient } from "src";

export const useGetAllPCBsQuery = (options: QueryTypes) => {

    return useQuery<PCBLookupDTO[]>({
        queryKey:["getAllPCBsQuery"],
        queryFn: async (q) => {
            return await AdminApiService.getPCBLookups(q.signal);
        },
        enabled: options?.enabled,
        refetchInterval: options?.refetchInterval,
        refetchOnWindowFocus: options?.refetchOnWindowFocus,
        initialData: () => {
            const data = queryClient.getQueryData<PCBLookupDTO[] | undefined>(["getAllPCBsQuery"]);
            if(data != null){
                return data as PCBLookupDTO[];
            }
            return [];
        }
    })
}