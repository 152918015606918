import * as React from 'react';
import { Input } from 'antd';

interface State {
    value: any;
}
interface InputProps {
    style?: any;
    initialValue?: string | null;
    onChange(value: any): void;
}
export default class PhoneInput extends React.Component<InputProps, State> {
    constructor(props: InputProps) {
        super(props);
        this.state = {
            value: this.props.initialValue
        };
    }
    onChange = (e: any) => {
        var { value } = e.target;
        var lastVal = (value as string)[(value as string).length - 1];
        var secLastVal = (value as string)[(value as string).length - 2];
        const reg = /^-?\d*(\.\d*)?$/;
        var test = (lastVal === '-' && secLastVal === undefined);
        if ((!isNaN(value) && reg.test(value)) || value === '' || test ) {

            this.props.onChange(value);
            this.setState({ value: value });
        }
    }

    render() {

        return (
            <Input
                {...this.props.style}
                value={this.state.value}
                onChange={this.onChange} />
        );
    }
}
