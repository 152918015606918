import * as React from 'react';
import BinDTO from 'src/models/BinDTO';
//import LayerDTO from 'src/models/LayerDTO';
import { LayerMCInfo, transformLayers } from '../BinStatusPage/BinVisualThree';
import { formatNumber } from '../BinStatusPage/HeaterControls';

interface CanvasProps {
    width: number;
    height: number;
    binData: BinDTO;
}
interface Point2D {
    x: number;
    y: number;
}

export const hasDyanmicLayers = (binData: BinDTO): boolean => {
    // const _hasDynamicLayers: boolean = (binData?.dynamicLayers?.length ?? 0) > 0;

    // disable showing dynamic layers until dynamicMC fixed
    const _hasDynamicLayers: boolean = false;
    return _hasDynamicLayers;
}

const createLayers = (binData: BinDTO, ctx: CanvasRenderingContext2D, width: number, height: number) => {
    let layers: LayerMCInfo[] | null = [];
    if (binData?.layerGrainStates) {
        layers = transformLayers(binData);
    }
    else if (hasDyanmicLayers(binData)) {
        layers = binData?.dynamicLayers;
    }
    else {
        layers = binData?.layers;
    }

    // let binHeight = binData.eaveHeight;
    let layerCount = layers?.length != null ? layers.length : 0;
    (layers || []).forEach((layer) => {
        let topPercent = (1 / layerCount) * layer.number;
        let bottomPercent = (1 / layerCount) * (layer.number - 1);
        let fillIn = true;

        // alex: skip using provided layer top and bottom for 2D visualization for now. Will fallback to equally spaced layers
        // Todo: Need to diagnose why layer.top and bottom are sometimes overlapping other layers when refreshing from device instead of Azure snapshot
        // if (layer.top !== null && layer.top!.heightFromFloor <= binHeight && layer.bottom!.heightFromFloor <= binHeight) {
        //     topPercent = layer.top!.heightFromFloor >= binHeight ? 1 : layer.top!.heightFromFloor / binHeight;
        //     bottomPercent = layer.bottom!.heightFromFloor !== 0 ? layer.bottom!.heightFromFloor / binHeight : 0;
        // }

        // let topPercent = tempArr[i].top >= binHeight ? 1 : tempArr[i].top / binHeight;
        // let bottomPercent = tempArr[i].bottom !== 0 ? tempArr[i].bottom / binHeight : 0;
        if (topPercent != null && bottomPercent != null) {
            let x = width * .1;
            let y = (((1 - (topPercent - .01)) * .6) + .3) * height;
            ctx.strokeStyle = '#C7C7C7';
            ctx.fillStyle = getFillColor(binData, layer, false);
            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.bezierCurveTo(x + (width * .05), y + (height * .05), width * .85, y + (height * 0.05), width * .9, y);
            x = width * .9;
            y = (((1 - (bottomPercent + .005)) * .6) + .3) * height;
            ctx.lineTo(x, y);
            ctx.bezierCurveTo(x - (width * .05), y + (height * .05), width * .15, y + (height * 0.05), width * .1, y);
            ctx.closePath();
            if (fillIn) {
                ctx.fill();
            }
            ctx.stroke();

            // add shadow to layer
            if (fillIn) {

                let t = .65;
                let p1 = { x: width * .1, y: (((1 - (topPercent - .01)) * .6) + .3) * height };
                let p2 = { x: p1.x + (width * .05), y: p1.y + (height * .05) };
                let p3 = { x: width * .85, y: p1.y + (height * 0.05) };
                let p4 = { x: width * .9, y: p1.y };
                let top = getCubicBezierAtPoint(p1, p2, p3, p4, t);
                ctx.fillStyle = getFillColor(binData, layer, true);
                ctx.strokeStyle = getFillColor(binData, layer, true);
                ctx.beginPath();
                ctx.moveTo(top.x, top.y + 1);
                ctx.bezierCurveTo(top.x, top.y, p3.x, p3.y - 3, p4.x, p4.y + 1);
                p1 = { x: width * .9, y: (((1 - (bottomPercent + .005)) * .6) + .3) * height };
                p2 = { x: p1.x - (width * .05), y: p1.y + (height * .05) };
                p3 = { x: width * .15, y: p1.y + (height * 0.05) };
                p4 = { x: width * .1, y: p1.y };
                ctx.lineTo(p1.x - 1, p1.y - 1);
                t = .35;
                let bottom1 = getCubicBezierAtPoint(p1, p2, p3, p4, t);
                let bottom2 = getCubicBezierAtPoint(p1, p2, p3, p4, .2);
                ctx.bezierCurveTo(p2.x - 5, p2.y - 9, bottom2.x, bottom2.y - 2, bottom1.x, bottom1.y - 1);
                ctx.closePath();
                ctx.fill();
                ctx.stroke();
            }

            // add loyer labels
            x = width * .5;
            y = (((1 - (((topPercent - (topPercent - bottomPercent) / 2)) - .01)) * .6) + .346) * height;
            ctx.font = '10px Helvetica';
            ctx.fillStyle = '#FFFFFF';
            ctx.textAlign = 'center';
            // if (layer.mc === 0) {
                // ctx.fillText(`L${layer.number}`, x, y);
            // } else {
            ctx.fillText(`L${layer.number}: ${formatNumber(layer?.mc, {decimalPlaces: 1, filler: "", suffix: "%"})}`, x, y);
            // }
        }
    });

};
const getFillColor = (binData: BinDTO, layer: LayerMCInfo, highlight: boolean) => {
    const YELLOW_COLOR = '#D1BE14';
    const LIGHT_BLUE = '#15CAD1';
    const DARK_BLUE = '#1595D1';
    const EMPTY_COLOR = "#c7c7c7";
    if (!layer.hasGrain) {
        return EMPTY_COLOR;
    }
    var targetMC = binData.currentBatch?.targetMC ? binData.currentBatch?.targetMC : 15.5;
    var mc = layer.mc;
    if ( mc === 0 ) {
        if (highlight) {
            return '#aaaaaa';
        }
        return '#c7c7c7';
    }

    if (mc == null) {
        return YELLOW_COLOR;
    }

    if(mc < 16){
        return YELLOW_COLOR
    }
    if(mc >= 16 && mc <= 20){
        return LIGHT_BLUE;
    }
    if(mc >= 20){
        return DARK_BLUE;
    }

    if (targetMC) {
        var interval = (mc - targetMC >= 0 ? mc - targetMC : 0) / (20 - targetMC);
        var piecewiseStart = 38 * (interval > 1 ? 1 : interval);
        var colorNum = 54 + (piecewiseStart <= 21 ? piecewiseStart : (107 + piecewiseStart));
        
        // var colorNum = 54 + (178 * (interval > 1 ? 1 : interval));
        if (mc <= targetMC) {
            colorNum = 54;
        }
        if (highlight) {
            return `hsl(${colorNum}, 80%, 40%)`;
        }
        return `hsl(${colorNum}, 82%, 45%)`;
    }
    if (highlight) {
        return '#12846e';
    }
    return '#138D75';
};
const getCubicBezierAtPoint = (p1: Point2D, p2: Point2D, p3: Point2D, p4: Point2D, t: number) => {
    let retPoint = { x: 0, y: 0 };

    retPoint.x = (Math.pow((1 - t), 3) * p1.x) +
        (3 * Math.pow(1 - t, 2) * t * p2.x) +
        (3 * (1 - t) * Math.pow(t, 2) * p3.x) +
        (Math.pow(t, 3) * p4.x);

    retPoint.y = (Math.pow((1 - t), 3) * p1.y) +
        (3 * Math.pow(1 - t, 2) * t * p2.y) +
        (3 * (1 - t) * Math.pow(t, 2) * p3.y) +
        (Math.pow(t, 3) * p4.y);

    return retPoint;
};
const Canvas = ({ width, height, binData }: CanvasProps) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            if (ctx) {

                let x = width * .1, y = height * .3;
                // create the body of the bin
                ctx.fillStyle = '#AEAEAE';
                ctx.strokeStyle = '#C7C7C7';
                ctx.lineJoin = 'bevel';
                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.bezierCurveTo(x + (width * .05), y + (height * .05), width * .85, y + (height * 0.05), width * .9, y);
                x = width * .9;
                y = height * .9;
                ctx.lineTo(x, y);
                ctx.bezierCurveTo(x - (width * .05), y + (height * .05), width * .15, y + (height * 0.05), width * .1, y);
                ctx.closePath();
                ctx.fill();
                ctx.stroke();

                // create the eave top
                ctx.fillStyle = '#C7C7C7';
                ctx.beginPath();
                x = width * .1, y = height * .3;
                ctx.moveTo(x, y);
                ctx.bezierCurveTo(x + (width * .05), y + (height * .05), width * .85, y + (height * 0.05), width * .9, y);
                x = width * ((2 * 4 / 15) + .05), y = height * .15;
                ctx.lineTo(x, y);
                ctx.bezierCurveTo(x - (width * .01), y + (width * .01), width * ((4 / 15) + .16), y + (width * .01), width * ((4 / 15) + .15), y);
                ctx.closePath();
                ctx.fill();
                ctx.stroke();

                // create very tip
                ctx.fillStyle = '#D7D7D7';
                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.bezierCurveTo(
                    x - (width * .01),
                    y - (height * .01),
                    width * ((4 / 15) + .16),
                    y - (width * .01),
                    width * ((4 / 15) + .15),
                    y);

                x = width * ((4 / 15) + .15);
                ctx.bezierCurveTo(
                    x + .01,
                    y + (width * .01),
                    width * ((2 * 4 / 15) + .04),
                    y + (width * .01),
                    x = width * ((2 * 4 / 15) + .05),
                    y
                );
                ctx.fill();
                ctx.stroke();

                // add shadow detail
                let t = .3;
                let p1 = { x: width * ((2 * 4 / 15) + .05), y: height * .15 };
                let p2 = { x: p1.x - (width * .01), y: p1.y + (width * .01) };
                let p3 = { x: width * ((4 / 15) + .16), y: p1.y + (width * .01) };
                let p4 = { x: width * ((4 / 15) + .15), y: p1.y };
                let topPoint = getCubicBezierAtPoint(p1, p2, p3, p4, t);

                p1 = { x: width * .1, y: height * .3 };
                p2 = { x: p1.x + (width * .05), y: p1.y + (height * .05) };
                p3 = { x: width * .85, y: p1.y + (height * 0.05) };
                p4 = { x: width * .9, y: p1.y };
                t = .65;
                let bottomPoint = getCubicBezierAtPoint(p1, p2, p3, p4, t);

                ctx.strokeStyle = '#AAAAAA';
                ctx.fillStyle = '#AAAAAA';
                ctx.beginPath();
                ctx.moveTo(topPoint.x, topPoint.y);
                ctx.lineTo(bottomPoint.x, bottomPoint.y);
                ctx.bezierCurveTo(bottomPoint.x, bottomPoint.y, p3.x, p3.y, p4.x, p4.y);
                x = width * ((2 * 4 / 15) + .05), y = height * .15;
                ctx.lineTo(x, y);
                // ctx.moveTo(topPoint.x, topPoint.y);
                ctx.bezierCurveTo(
                    width * ((2 * 4 / 15) + .05) - (width * .01),
                    height * .15 + (height * .01),
                    topPoint.x,
                    topPoint.y,
                    topPoint.x,
                    topPoint.y);
                ctx.fill();
                ctx.stroke();

                // // bin shadow
                // ctx.beginPath();
                // ctx.moveTo(bottomPoint.x + 1, bottomPoint.y + 1);
                // ctx.bezierCurveTo(bottomPoint.x + 1, bottomPoint.y + 1, p3.x, p3.y, p4.x, p4.y);
                // x = width * .9;
                // y = height * .9;
                // ctx.lineTo(x, y);
                // p1 = {x: x, y: y};
                // p2 = { x: (x - (width * .05)), y: y + (height * .05) };
                // p3 = { x: width * .15, y: y + (height * .05) };
                // p4 = { x: width * .1, y: y };
                // t = .35;
                // let binBottomPoint = getCubicBezierAtPoint(p1, p2, p3, p4, t);
                // ctx.bezierCurveTo(p2.x, p2.y, binBottomPoint.x, binBottomPoint.y, binBottomPoint.x, binBottomPoint.y);
                // ctx.closePath();
                // ctx.fill();
                // ctx.stroke();

                createLayers(binData, ctx, width, height);

            }
        }
    });

    return <canvas ref={canvasRef} height={height} width={width} />;
};

Canvas.defaultProps = {
    width: parent.innerWidth,
    height: parent.innerHeight
};

export default Canvas;
