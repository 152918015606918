import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TooltipItem } from "chart.js";
import { AnnotationOptions } from "chartjs-plugin-annotation";
import { round, uniqueId } from "lodash-es";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import BinApiService from "src/api/BinApiService";
import { useMemo } from "react";
import FanOffReason from "src/consts/FanOffReason";
import HeaterOffReason from "src/consts/HeaterOffReason";
import { Dayjs } from "dayjs";

export const compressorColor = "#583381";
export const fanStatusColor = compressorColor
export const heaterStatusColor = "#8f51cc";



export const tooltipItemRound = (tooltipItem: TooltipItem<'line'>) => {
    let label = tooltipItem.dataset.label ?? "";

    if (label) {
        label += ': ';
    }

    if (tooltipItem.dataset.label?.includes("Status")) {

        if (typeof (tooltipItem.raw as any)?.y === 'string') {

            // assumed category
            label += (tooltipItem.raw as any)?.y;
            return label;
        }
    }

    label += round(tooltipItem.parsed.y, 1)?.toFixed(1);
    return label;
  }




  enum FanState {
    Off,
    On,
    Paused
  }
  
  enum HeaterState {
    Off,
    On,
    Paused,
  }

  export const fanOnBackgroundColor = 'rgb(196, 255, 191)';
  export const FanPausedBackgroundColor = 'rgb(245, 245, 245)';
  
  export const heaterOnBackgroundColor = '#fad9bb';
  
  interface FanHeaterAnnotationOptions {
    yAxes?: string[]
  }
  
  export const useFanHeaterChangeHistory = (binId: number, startDate: Dayjs | null | undefined, endDate: Dayjs  | null | undefined, options?: FanHeaterAnnotationOptions) => {

    const queryClient = useQueryClient();

    const yAxes = useMemo(() => {
      return options?.yAxes ?? ['y'];
    }, [options?.yAxes])

    const chartQuery = useQuery({
      queryKey: [...binDBKeys.bin(binId), "charts", "getFanHeaterChangeHistory", { startDate: startDate, endDate: endDate }],
      queryFn: async (q) => {
  
        const result = await BinApiService.getFanHeaterChangeHistory(binId, startDate?.toISOString()!, endDate?.toISOString()!, null, q.signal);
        queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
        return result;
      },
      refetchInterval: Infinity, retry: false, keepPreviousData: true,
      enabled: (binId ?? 0) > 0 && startDate != null && endDate != null,
    });
  
    const annotations = useMemo(() => {
      const fanHeaterAnnotations = chartQuery.data?.flatMap((datapoint, index) => {
  
        const isFanOn = () => {
          if (datapoint.fanOffReasonId == null) {
            return FanState.On;
          }
          if ([FanOffReason.FanTimer, FanOffReason.KeepFansOffOverride].includes(datapoint.fanOffReasonId)) {
            return FanState.Off;
          }
      
          return FanState.Paused;
        };
  
        const isHeaterOn = () => {
          if (datapoint.heaterOffReasonId == null) {
            return HeaterState.On;
          }
          return HeaterState.Off;
        };
  
        const statusAnnotations: AnnotationOptions[] = [];
  
        const fanStatus = isFanOn();
        const heaterStatus = isHeaterOn();
        if (fanStatus == FanState.On) {
          const annotation: AnnotationOptions = {
            type: 'box',
            xScaleID: 'x',
            id: uniqueId('fanChange-'),
            xMin: datapoint.x as string,
            xMax: datapoint.nextCaptureTimeUTC as string ?? undefined,
            backgroundColor: fanOnBackgroundColor,
            borderWidth: 0,
            z: -2,
            drawTime: 'beforeDatasetsDraw',        
          };
          for (const yaxis of yAxes) {
            statusAnnotations.push({...annotation, yScaleID: yaxis});
          }
        }
  
        else if (fanStatus == FanState.Paused) {
          const annotation: AnnotationOptions = {
            type: 'box',
            xScaleID: 'x',
            id: uniqueId('fanChange-'),
            xMin: datapoint.x as string,
            xMax: datapoint.nextCaptureTimeUTC as string ?? undefined,
            backgroundColor: FanPausedBackgroundColor,
            borderWidth: 0,
            z: -2,
            drawTime: 'beforeDatasetsDraw',        
          };
          for (const yaxis of yAxes) {
            statusAnnotations.push({...annotation, yScaleID: yaxis});
          }
        }
  
        if (heaterStatus == HeaterState.On) {
          const annotation: AnnotationOptions = {
            type: 'box',
            id: uniqueId('heaterChange-'),
            xMin: datapoint.x as string,
            xMax: datapoint.nextCaptureTimeUTC as string ?? undefined,
            //backgroundColor: 'rgb(250, 217, 187)',
            backgroundColor: heaterOnBackgroundColor,
            z: -2,
            borderWidth: 0,
            drawTime: 'beforeDatasetsDraw',        
          };
          for (const yaxis of yAxes) {
            statusAnnotations.push({...annotation, yScaleID: yaxis});
          }
        }
  
        return statusAnnotations;
      }) ?? [];
      return fanHeaterAnnotations;
    }, [chartQuery.data, yAxes]);
  
    return {data: chartQuery.data, annotations: annotations};
  }