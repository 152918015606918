import * as React from 'react';
import BinInfoDTO from 'src/models/BinInfoDTO';
import Column from 'antd/lib/table/Column';
// import EnterpriseApiService from 'src/api/EnterpriseApiService';
import EnterpriseCropDataDTO from 'src/models/EnterpriseCropDataDTO';
import GoogleMapReact from 'google-map-react';
import Routes from 'src/consts/Routes';
import SiteDTO from 'src/models/SiteDTO';
import TextArea from 'antd/lib/input/TextArea';
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Layout,
    Modal,
    // notification,
    Row,
    Slider,
    Table,
    FormInstance
    } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
//import '@ant-design/compatible/assets/index.css';
import { RouteComponentProps } from 'react-router-dom';

const FormItem = Form.Item;

interface State {
    visible: boolean;
    site: SiteDTO;
    binName: string;
    binHeight: number | undefined;
    model: BinInfoDTO;
}

interface SiteFormValues {
    circle: any;
    binLatLng: any;
    binSize: number;
}

const mapStyles = {
    width: '100%',
    height: '100%'
};

const siteData = [
    {
        key: '1',
        label: 'Average MC %',
        type: 'moisturePercentage',
        corn: '--',
        soy: '--',
        other: '--',
    },
    {
        key: '2',
        label: 'Total Market Value',
        type: 'currentValue',
        corn: '--',
        soy: '--',
        other: '--',
    },
    {
        key: '3',
        label: 'Max Market Value',
        type: 'maxMarket Value',
        corn: '--',
        soy: '--',
        other: '--',
    },
    {
        key: '4',
        label: 'Available Storage',
        type: 'storageLeft',
        corn: '--',
        soy: '--',
        other: '--',
    },
    {
        key: '5',
        label: 'Sell',
        type: 'sellPrice',
        corn: '--',
        soy: '--',
        other: '--',
    },
    {
        key: '6',
        label: 'By',
        type: 'sellBy',
        corn: '--',
        soy: '--',
        other: '--',
    },
];

export type siteHistoryState = {
    site: SiteDTO;
};

export type siteHistoryProps = RouteComponentProps<{}, {}, siteHistoryState>;

export default class MoreSiteDetail extends React.Component<siteHistoryProps, State> {
    private readonly _formRef = React.createRef<FormInstance>();
    private formValues: SiteFormValues;
    private map: any;
    private maps: any;
    constructor(props: siteHistoryProps) {
        super(props);

        const { state } = props.location;
        this.state = {
            visible: false,
            site: state.site,
            binName: '',
            binHeight: 0,
            model: BinInfoDTO.create()
        };
        this.formValues = {
            circle: null,
            binLatLng: {
                lat: 0,
                lng: 0
            },
            binSize: 16
        };
    }

    createTableData(site: SiteDTO) {
        site.siteCropData!.forEach((crop: EnterpriseCropDataDTO) => {
            var croptype = crop.cropName;
            siteData.forEach((row: any) => {
                var type = crop[row.type];
                if (type == null) {
                    type = '--';
                }
                switch (croptype) {
                    case 'Corn':
                        return row.corn = type;
                    case 'Soy':
                        return row.soy = type;
                    default:
                        return row.other = type;
                }
            });
        });
    }

    showAddSiteForm = () => {
        this.setState({ visible: true });
    }

    onBinSliderChange = (value: any) => {
        this.formValues.binSize = value;
    }

    handleSubmit = () => {
        /*this._formRef!.current!.validateFields().then((_model: any) => {
            if (this.state.site.id) {
                EnterpriseApiService.addBin(this.state.site.id, {
                    id: 0,
                    name: _model.name,
                    binLat: this.formValues.binLatLng.lat,
                    binLng: this.formValues.binLatLng.lng,
                    height: _model.height,
                    diameter: _model.diameter,
                    description: _model.description,
                    notes: _model.notes,
                    manufacturer: _model.manufacturer,
                    fanModel: _model.fanModel,
                    siteId: this.state.site.id,
                    binStats: null,
                    levelData: null,
                    targetMoistureContent: 17.0
                }).then(binInfo => {
                    console.log(binInfo);
                    this.setState({ visible: false });
                    this._formRef!.current!.resetFields();
                }).catch(error => {
                    notification.error({
                        message: error.message,
                        description: error.description
                    });
                });
            }
        }).catch((info: any) => {
            console.log('Validate Failed', info);
        });*/
    }

    placeCircle = ({ lat, lng }: any) => {
        this.formValues.binLatLng = { lat: lat, lng: lng };
        if (this.formValues.circle) { this.formValues.circle.setMap(null); }
        this.formValues.circle = new this.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillOpacity: 0.0,
            map: this.map,
            center: this.formValues.binLatLng,
            radius: this.formValues.binSize / 2
        });
    }

    handleCancel = () => {
        this._formRef!.current!.resetFields();
        this.setState({ visible: false });
    }

    render() {
        const { site } = this.state;

        this.createTableData(site);

        return (
            <Layout.Content className="dashboard-details">
                <Row>
                    <PageHeader
                        onBack={() => { this.props.history.push(Routes.HOME_ROUTE); }}
                        title={site.siteName}
                        style={{ width: '100%' }}
                        extra={[
                            <Button
                                key={1}
                                ghost
                                onClick={this.showAddSiteForm}>+ Add Bin
                            </Button>,
                            <Modal
                                key={2}
                                title="Add Bin"
                                okText="Submit"
                                open={this.state.visible}
                                onOk={this.handleSubmit}
                                onCancel={this.handleCancel}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Form ref={this._formRef}>
                                            <FormItem label="Bin Name" name="name" rules={[{ required: true, message: 'Enter the name of the Bin' }]}>
                                                <Input />
                                            </FormItem>
                                            <FormItem
                                                label="Bin Height"
                                                name="height"
                                                rules={[{ required: true, message: 'Enter the height of the Bin' }]}>
                                                <InputNumber />
                                            </FormItem>
                                            <FormItem
                                                label="Diameter of The Bin"
                                                name="diameter"
                                                rules={[{ required: true, message: 'Enter the Diameter of the Bin' }]}>
                                                <InputNumber />
                                            </FormItem>
                                            <FormItem
                                                label="Manufacturer of the Bin"
                                                name="manufacturer"
                                                rules={[{ required: true, message: 'Enter the Bin Manufacturer' }]}>
                                                <Input />
                                            </FormItem>
                                            <FormItem
                                                label="Fan Model"
                                                name="fanModel"
                                                rules={[{ required: true, message: 'Enter the Model of Fan Used' }]}>
                                                <Input />
                                            </FormItem>
                                            <FormItem
                                                label="Bin Description"
                                                name="description"
                                                rules={[{ required: false, message: 'Enter a Description of the Bin' }]}>
                                                <TextArea autoSize={{ minRows: 3 }} />
                                            </FormItem>
                                            <FormItem
                                                label="Additional Notes"
                                                name="notes"
                                                rules={[{ required: false, message: 'Enter a Description of the Bin' }]}>
                                                <TextArea autoSize={{ minRows: 3 }} placeholder="Add any additonal notes about the bin here" />
                                            </FormItem>
                                            <FormItem
                                                initialValue={this.formValues.binSize}
                                                label="Google Maps Circle Bin Size"
                                                name="diameter">
                                                <Slider
                                                    max={40}
                                                    onChange={(value: number) => {
                                                        this.formValues.binSize = value;
                                                    }} />
                                            </FormItem>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{ height: 300 }}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: 'AIzaSyB1MNw0ezLxq6DTfZGztncZ_9KytzgYRJE', libraries: 'places' }}
                                            style={mapStyles}
                                            options={map => ({
                                                mapTypeId: map.MapTypeId.SATELLITE,
                                                zoomControl: false,
                                                disableDefaultUI: true,
                                                scrollwheel: false,
                                                disableDoubleClickZoom: true
                                            })}
                                            defaultCenter={{ lat: site.siteLat, lng: site.siteLng }}
                                            yesIWantToUseGoogleMapApiInternals={true}
                                            onClick={this.placeCircle}
                                            onGoogleApiLoaded={({ map, maps }) => {
                                                this.map = map;
                                                this.maps = maps;

                                            }}
                                            defaultZoom={site.mapsZoomLevel!}
                                        />
                                    </Col>
                                </Row>
                            </Modal>
                        ]}
                    />
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingBottom: 20 }}>
                                <Table dataSource={siteData} bordered={true} pagination={false}>
                                    <Column
                                        align="center"
                                        title="LABEL"
                                        dataIndex="label"
                                        key="Label"
                                    />
                                    <Column
                                        align="center"
                                        title="CORN"
                                        dataIndex="corn"
                                        key="corn"
                                    />
                                    <Column
                                        align="center"
                                        title="SOY"
                                        dataIndex="soy"
                                        key="soy"
                                    />
                                    <Column
                                        align="center"
                                        title="OTHER"
                                        dataIndex="other"
                                        key="other"
                                    />
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        );
    }
}
