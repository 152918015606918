import { CheckCircleFilled, CloseCircleFilled, RedoOutlined, WarningFilled } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Card, Descriptions, DescriptionsProps, Divider, Flex, Grid, Row, Select, Space, Tag, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useWindowSize } from "rooks";
import ShivversService from "src/api/ShivversService";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { formatNumber } from "src/pages/dashboard/BinStatusPage/HeaterControls";
import { useBinInfoContext } from "src/queries/BinInfoContext";
import { DATETIME_FORMAT } from "./shared";

export const CheckPassedIcon = () => {
    return <CheckCircleFilled style={{ color: "green" }} />
}

export const CheckFailedIcon = () => {
    return <CloseCircleFilled style={{ color: 'red' }} />
}

export const HourSelector = (props: any) => {
    return <>
        <Select value={props.hours} loading={props.loading}
            onChange={(value, option) => {
                props.onHourChange(value)
            }}
            options={[
                {
                    label: "2 hours",
                    value: 2,
                },
                {
                    label: "4 hours",
                    value: 4,
                },
                {
                    label: "8 hours",
                    value: 8,
                },
                {
                    label: "12 hours",
                    value: 12,
                },
            ]} />
    </>
}

export const ReadingOnOffDisplay = () => {

    const [hours, setHour] = useState(2);

    const binInfo = useBinInfoContext();
    const query = useQuery({
        queryKey: [...binDBKeys.shivversBin(binInfo.linkIpAddress!), "cycleAverages", { seconds: hours * 3600 }],
        queryFn: async (q) => await ShivversService.cycleAverages(binInfo.linkIpAddress!, hours * 3600, q.signal),
        staleTime: 100,
    });

    const windowSize = useWindowSize();



    const formatTime = (seconds: number | undefined) => {
        if (seconds == null) {
            return AVG_FILLER;
        }
        const duration = dayjs.duration(seconds, 'seconds');
        return `${Math.floor(duration.asHours())}:${duration.minutes()}`;
    }

    const AVG_FILLER = '----';

    const onCycleItems: DescriptionsProps['items'] = [
        {
            label: "Moisture Avg.",
            children: <Typography.Text>{formatNumber(query.data?.onCycleInfo?.moistread, { filler: AVG_FILLER, decimalPlaces: 2 })}</Typography.Text>,
        },
        {
            label: "Grain Temp Avg.",
            children: <Typography.Text>{formatNumber(query.data?.onCycleInfo?.graintemp, { filler: AVG_FILLER, decimalPlaces: 2 })}</Typography.Text>,
        },
        {
            label: "Time On (hh:mm)",
            children: <Typography.Text>{formatTime(query.data?.onCycleInfo?.durationSeconds)}</Typography.Text>,
        },
    ];

    const offCycleItems: DescriptionsProps['items'] = [
        {
            label: "Moisture Avg.",
            children: <Typography.Text>{formatNumber(query.data?.offCycleInfo?.moistread, { filler: AVG_FILLER, decimalPlaces: 2 })}</Typography.Text>,
        },
        {
            label: "Grain Temp Avg.",
            children: <Typography.Text>{formatNumber(query.data?.offCycleInfo?.graintemp, { filler: AVG_FILLER, decimalPlaces: 2 })}</Typography.Text>,
        },
        {
            label: "Time Off (hh:mm)",
            children: <Typography.Text>{formatTime(query.data?.offCycleInfo?.durationSeconds)}</Typography.Text>,
        },
    ];

    const horizontal = () => {
        if (windowSize.innerWidth! <= 600) {
            return false;
        }
        else if (windowSize.innerWidth! <= 900) {
            return true;
        }
        else if (windowSize.innerWidth! > 900 && windowSize.innerWidth! < 1200) {
            return true;
        }
        else if (windowSize.innerWidth! >= 1200 && windowSize.innerWidth! < 1500) {
            return false;
        }
        else if (windowSize.innerWidth! >= 1500) {
            return true;
        }
        else
            return false;
    }



    return <>
        <Card style={{ width: "100%" }} styles={{
            title: {
                width: "100%",
            },
            body: {
                minWidth: horizontal() ? "460px" : "240px",
                width: "100%",
            }
        }} loading={query.isInitialLoading} title={<Flex gap="small">
            <span>Cycle Averages</span>
            <Tag>{formatNumber(query.data?.numberOfSamples, { filler: "No Data", decimalPlaces: 0, suffix: " Samples" })}</Tag>
        </Flex>
        }>

            <Flex gap="middle" align="center" justify="center" >
                <Typography.Text>Choose Hours</Typography.Text>
                <HourSelector hours={hours} onHourChange={setHour} loading={query.isFetching} />
                {/* <Typography.Text strong># Samples</Typography.Text> <Typography.Text>{5}</Typography.Text> */}
            </Flex>
            <Flex gap={"middle"} justify="center" wrap="wrap" vertical={!horizontal()}>
                <Descriptions title="On Cycles Avg." labelStyle={{ width: "144px" }} bordered items={onCycleItems} size="small" column={1} />
                <Descriptions title="Off Cycles Avg." labelStyle={{ width: "144px" }} bordered items={offCycleItems} size="small" column={1} />
            </Flex>
        </Card>
    </>;
};

export const Overview = (props: any) => {

    const binInfo = useBinInfoContext();

    const lastReadingQuery = useQuery({
        queryKey: [binDBKeys.moistureDisplay(binInfo.linkIpAddress!), { last: 1 }],
        queryFn: async (q) => await ShivversService.lastReadings(binInfo.linkIpAddress!, 1, q.signal),
        refetchOnWindowFocus: false,
        staleTime: 1000,
    })



    const lastReading = lastReadingQuery.data?.[0];
    const itemsRest: DescriptionsProps['items'] = [];

    if (props.showLastTapeReadingDate) {
        itemsRest.push({
            label: "Last Tape Reading",
            children: lastReading?.dateinUtc != null ? dayjs(lastReading?.dateinUtc)?.format(DATETIME_FORMAT) : null,
        });
    }

    itemsRest.push(...[
        {
            label: "Moisture",
            children: lastReading?.moistread,
        },
        {
            label: "Avg. Moisture",
            children: lastReading?.moistavg,
        },
        {
            label: "Grain Temp",
            children: lastReading?.graintemp,
        },
        {
            label: "Plenum Temp",
            children: lastReading?.plenumtemp,
        },
        {
            label: "Target Temp",
            children: lastReading?.targettemp,
        },
        {
            label: "Machine",
            children: <Space direction="horizontal" wrap size="small">
                {lastReading?.machine}
                {lastReading != null ? lastReading?.machine?.includes("ON") ? <CheckPassedIcon /> : <CheckFailedIcon /> : null}
            </Space>,
        },
    ]);

    const isShivversPremier = binInfo.isPremier;
    if (isShivversPremier) {
        const item = {
            label: "Static Pressure",
            children: lastReading?.pressure,
        };
        itemsRest.push(item);
    }

    return <>
        <Descriptions title={props.title ?? undefined} bordered layout={props.layout} items={itemsRest} size="small" column={props.columns ?? 1} />
    </>;
}