// Constants TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
enum RoutineType {
    Fill = 'Fill',
    PreDry = 'PreDry',
    Core = 'Core',
    MeasureMC = 'MeasureMC',
    ReStack = 'ReStack',
    CrossFlow = 'CrossFlow',
    Aeration = 'Aeration',
    ManualDry = 'ManualDry',
    SelfCheck = 'SelfCheck',
    Paused = 'Paused',
    TopDry = 'TopDry',
    Custom = 'Custom',
    Unknown = 'Unknown',
}
export default RoutineType;
