import { FireTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";
import { maxBy } from "lodash";
import React from "react";
import { useBinDTOContext } from "src/queries/BinDTOContext";
import { formatNumber } from "./HeaterControls";

export const HotspotIndicator = (props: {layerNumber: number}) => {

    const {binDTO, ...binHelper} = useBinDTOContext();

    if (binDTO == null) {
        return null;
    }

    if (binDTO.hotspot?.hotSpotSensors == null || binDTO.hotspot?.hotSpotSensors.length === 0) {
        return null;
    }

    const hotspotsInLayer = binDTO.hotspot.hotSpotSensors.filter(hotspot => hotspot.layerNumber === props.layerNumber);
    if (hotspotsInLayer == null || hotspotsInLayer.length === 0) {
        return null;
    }

    const highestTemperatureHotspot = maxBy(hotspotsInLayer, (hotspot) => hotspot.temperatureF);

    // undefined means that all the temperatures were not a well formed number https://github.com/lodash/lodash/issues/3629
    if (highestTemperatureHotspot === undefined) {
        return null;
    }

    const hotspotTooltipText = `Hotspot alert: a sensor in layer ${props.layerNumber} has a temp reading of ${formatNumber(highestTemperatureHotspot.temperatureF, {decimalPlaces: 0, filler: ""})}°F, which is above your ${binDTO.hotspot.temperatureThresholdF}°F alert setpoint.`;

    return (
        <Tooltip title={hotspotTooltipText}>
            <FireTwoTone twoToneColor="red" />
        </Tooltip>
    );

    
}