import { useQuery } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import React, { useState, useCallback, useMemo } from "react";
import { queryClient } from "src";
import BinApiService from "src/api/BinApiService";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { RangeValue } from "./ChartContainer";
import { tooltipItemRound, useFanHeaterChangeHistory } from "./shared";
import { BaseChartProps, htmlLegendPlugin } from "./TemperatureCableChart";
import _uniqueId from 'lodash/uniqueId';
import { Line } from "react-chartjs-2";
import { ChartDatePicker } from "./Co2Chart";
import { Skeleton } from "antd";
import { ambientFanDatasetColor, plenumFanDatasetColor } from "./AmbientPlenumChart";
import { AnnotationOptions, LineAnnotationOptions } from "chartjs-plugin-annotation";
import uniqueId from "lodash/uniqueId";
import FanOffReason from "src/consts/FanOffReason";
import HeaterOffReason from "src/consts/HeaterOffReason";

interface ModeChangeHistoryOptions {
  yAxes?: string[],
}
export const useModeChangeHistory = (binId: number, startDate: Dayjs | null | undefined, endDate: Dayjs  | null | undefined, options?: ModeChangeHistoryOptions) => {

  const yAxes = useMemo(() => {
    return options?.yAxes ?? ['y'];
  }, [options?.yAxes])

  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(binId), "charts", "modeChangeChart", { startDate: startDate, endDate: endDate }],
    queryFn: async (q) => {

      const result = await BinApiService.getOperatingModeChangesDataset(binId, startDate?.toISOString()!, endDate?.toISOString()!, null, q.signal);
      queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
      return result;
    },
    refetchInterval: Infinity, retry: false, keepPreviousData: true,
    enabled: (binId ?? 0) > 0 && startDate != null && endDate != null,
  });

  const formatOperatingMode = (mode: string): string => {
    if (mode === "Manual") {
      return "User Control";
    }
    else if (mode === "FillBin") {
      return "Fill & Aerate"
    }
    else if (mode === "PreDry") {
      return "Pre Dry";
    }
    else if (mode === "TopDry") {
      return "Top Dry"
    }
    return mode;
  }

  const annotations = useMemo(() => chartQuery.data?.data?.flatMap((datapoint, index) => {
    const mode = datapoint.operatingMode as string;
    const formattedMode = formatOperatingMode(mode);
    const content = `${formattedMode}`;
    const _annotations: AnnotationOptions[] = [];
    const annotation: AnnotationOptions = {
      id: uniqueId('modeChange-'),
      type: 'line',
      scaleID: 'x',
      borderDash: [6, 6],
      borderWidth: 1,
      borderColor: "#aaa",
      value: datapoint.x as string,
      drawTime: 'beforeDatasetsDraw',
      label: {
        textStrokeColor: "white",
        textStrokeWidth: 1,
        display: true,
        content: content,
        backgroundColor: 'transparent',
        rotation: 0,
        color: "black",
        position: 'start',
        xAdjust(ctx, options) {
          let value = ctx.chart.ctx.measureText(content);
          return value.width / 2;
        },
        font: {
          size: 12,
        }
      }
    };
    for (const yaxis of yAxes) {
      _annotations.push({...annotation, yScaleID: yaxis});
    }
    return _annotations;
  }) ?? [], [chartQuery.data, yAxes]);

  return useMemo(() => {
    return {chartQuery, annotations};
  }, [chartQuery, annotations]);
}





export const LayerMCHistoryChart = (props: BaseChartProps & {yTitle?: string}) => {
    const currentDate = dayjs();
    const priorDate = currentDate.subtract(14, 'days');
    
    const [legendId] = useState(_uniqueId('legend-'));
    const isControlled = typeof props.value != 'undefined';
    const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);
  
    // Internally, we need to deal with some value. Depending on whether
      // the component is controlled or not, that value comes from its
      // props or from its internal state.
  
      const value = isControlled ? props.value : internalValue;
  
    const onDateChange = useCallback((values: RangeValue, formatter: any) => {
      if (props.onChange) {
        props.onChange(values);
      }
      else {
        setIntervalValue(values);
      }
    }, [props.onChange]);

    const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
    const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);

    const chartQuery = useQuery({
      queryKey: [...binDBKeys.bin(props.binId), "charts", "LayerSummary", { startDate: value?.[0], endDate: value?.[1] }],
      queryFn: async (q) => {
        const now = value?.[1];
        const priorMonth = value?.[0];
  
        const result = await BinApiService.getLayerSummaryHistory(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
        queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
        return result;
      },
      refetchInterval: Infinity, retry: false, keepPreviousData: true,
      enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null,
    });
  
  
    return (<div>
      {!isControlled && <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />}
      <Skeleton loading={chartQuery.data == null}>
        {chartQuery.data && <section style={{ width: "100%", height: props.chartHeight ?? "480px", display: "flex", justifyContent: "center" }}>
          <Line options={{
            responsive: true,
            animation: false,
            spanGaps: false,
            resizeDelay: 250,
            maintainAspectRatio: false,
            datasets: {
              line: {
                pointRadius: 0 // disable for all `'line'` datasets
              }
            },
            scales: {
              x: {
                type: "time",
                time: {
                  minUnit: 'day',
                  displayFormats: {
                    //day: 'l'
                  }
                },
              },
              y: {
                min: props.min ?? undefined,
                max: props.max ?? undefined,
                title: {
                  font: {
                    size: 16,
                  },
                  display: true,
                  text: props.yTitle ?? "MC (%)",
                }
              }
            },
            interaction: {
              includeInvisible: true,            
            },
            plugins: {
              annotation: {
                annotations: [
                  ...modeHistory.annotations,
                  ...fanHeaterChangeHistory.annotations,
                ],
              },
              legend: {
                position: 'top' as const,
                display: true,
              },
              title: {
                display: true,
                text: 'Moisture Cables',
              },
  
              tooltip: {
                mode: 'index',
                
                intersect: false,
                callbacks: {
                  label: tooltipItemRound,
                },
              },
  
            },
          }}
            data={

                { datasets: [
                     ...[...chartQuery.data ?? []]?.reverse()?.map(dataset => {

                return {
                    data: dataset.data,
                    label: dataset.label!,
                    parsing: {
                        yAxisKey: 'mc',
                    }
                };
            }) ?? [],

            ] }} />
        </section>
        }
        <section id={`legend-layerSummary-container${legendId}`}></section>
      </Skeleton>
    </div>);
  }
  