import ApiResultHandler from './ApiResultHandler';
import FileDownload from 'src/utils/FileDownload';

function add(obj: {}, list: any[]): Function {
    list.push(obj);
    return () => {
        const index = list.indexOf(obj);
        if (index >= 0) {
            list.splice(index, 1);
        }
    };
}

let globalPreRequests: RequestProcessor[] = [];
let globalPostRequests: ResponseProcessor[] = [];

export enum HttpVerb {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export class RequestProcessor {
    public success: (url: string, config: RequestInit) => RequestInit | Promise<RequestInit>;
    public error: (error: any) => any;
}

export class ResponseProcessor {
    public success: (response: Response) => Response | Promise<Response>;
    public error: (error: any) => any;
}

abstract class BaseApi {
    private baseUrl = '/' + ($appPath && $appPath.length > 0 ? '/' : '');
    private preRequests: RequestProcessor[] = [];
    private postRequests: ResponseProcessor[] = [];

    /**
     * Ads a pre-request processor to the api service instance.
     * Returns function to call to remove pre-request processor.
     * @param preRequest The RequestProcessor to add.
     */
    public addPreRequestProcessor(preRequest: RequestProcessor) {
        return add(preRequest, this.preRequests);
    }

    /**
     * Ads a post-request processor to the api service instance.
     * Returns function to call to remove post-request processor.
     * @param postRequest The RequestProcessor to add.
     */
    public addPostRequestProcessor(postRequest: ResponseProcessor) {
        return add(postRequest, this.postRequests);
    }

    /**
     * Clears all pre-request processor from the api service instance.
     */
    public clearPreRequestProcessor() {
        this.preRequests = [];
    }

    /**
     * Clears all post-request processor from the api service instance.
     */
    public clearPostRequestProcessor() {
        this.postRequests = [];
    }

    /**
     * Main entry point for derived api service classes to call.
     * @param req Payload of the body of the request.
     * @param requestVerb HttpVerb to use for the request.
     * @param relativeUrl The app-relative url path of the endpoint to hit foer the request.
     * @param isJson Whether or not the request to a JSON or FormData request.
     * @param isDownload Whether the request is to downloa a file.
     */
    protected SendRequest<REQ, RES>(req: REQ, requestVerb: string, relativeUrl: string, isJson: boolean, isDownload: boolean, signal: AbortSignal) {
        var requestVerbUpper = requestVerb.toUpperCase();
        return this.send<REQ, RES>(req, relativeUrl, requestVerbUpper, isJson, isDownload, signal);
    }

    /**
     * Internal entry point for all requests.
     * @param req Payload of the body of the request.
     * @param relativeUrl The app-relative url path of the endpoint to hit foer the request.
     * @param method HttpVerb to use for the request.
     * @param isJson Whether or not the request to a JSON or FormData request.
     * @param isDownload Whether the request is to downloa a file.
     */
    private send<REQ, RES>(req: REQ, relativeUrl: string, method: string, isJson: boolean, isDownload: boolean, signal: AbortSignal): Promise<RES> {
        let promise: Promise<Response>;
        if (isDownload) {
            promise = this.getFilePromise<REQ>(req, relativeUrl, method, signal);
        } else {
            promise = this.getBasePromise<REQ>(req, relativeUrl, method, isJson, signal);
        }

        return promise
            .then(response => {
                let ct = response.headers.get('Content-Type');
                if (ct && ct.indexOf('/json') > 0) {
                    return response.json();
                }
                return null;
            })
            .catch(e => {
                if (DEBUG) {
                    console.log(e);
                }
                return Promise.reject(e);
            });
    }

    /**
     * Get a base promise for all request except file downloads.
     * @param req Payload of the body of the request.
     * @param relativeUrl The app-relative url path of the endpoint to hit foer the request.
     * @param method HttpVerb to use for the request.
     * @param shouldHaveJsonBody Whether or not the request to a JSON or FormData request.
     */
    private getBasePromise<REQ>(req: REQ, relativeUrl: string, method: string, shouldHaveJsonBody: boolean, signal: AbortSignal): Promise<Response> {
        let promise = this.getRequestPromise(req, relativeUrl, method, shouldHaveJsonBody, signal);
        let url = this.resolveUrl(relativeUrl);
        let promise2 = promise.then(args => fetch(url, { ...args, signal }).then(ApiResultHandler.handle));
        return this.appendToResponsePromise(req, url, method, promise2);
    }

    /**
     * Get a base promise for file download request.
     * @param req Payload of the body of the request.
     * @param relativeUrl The app-relative url path of the endpoint to hit foer the request.
     * @param method HttpVerb to use for the request.
     */
    private getFilePromise<REQ>(req: REQ, relativeUrl: string, method: string, signal: AbortSignal): Promise<Response> {
        let promise = this.getRequestPromise(req, relativeUrl, method, true, signal);

        let url = this.resolveUrl(relativeUrl);
        if (req) {
            url += '?_payload=' + JSON.stringify(req);
        }

        let promise2 = promise.then(args => new Promise<Response>((resolve, reject) => {
            FileDownload.download(
                url,
                function (error: string | null) {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(new Response());
                    }
                });
            })
        );
        return this.appendToResponsePromise(req, url, method, promise2);
    }

    /**
     * Creates the RequestInit object to use for the request.
     * @param req Payload of the body of the request.
     * @param method HttpVerb to use for the request.
     * @param isJson Whether or not the request to a JSON or FormData request.
     */
    private createRequestObject<REQ>(req: REQ, method: string, isJson: boolean, signal: AbortSignal): RequestInit {
        if (!isJson) {
            return this.createRequestObjectFormData(req, method, signal);
        }

        var requestInfo: RequestInit = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache': 'no-cache'
            },
            credentials: 'include',
            signal: signal,
        };

        if (method !== HttpVerb.GET) {
            requestInfo.body = JSON.stringify(req);
        }
        return requestInfo;
    }

    /**
     * Creates the RequestInit object to use for the request that uses FormData.
     * @param req Payload of the body of the request.
     * @param method HttpVerb to use for the request.
     */
    private createRequestObjectFormData<REQ>(req: REQ, method: string, signal: AbortSignal): RequestInit {
        if (!(req instanceof FormData)) {
            throw new Error('request object is not of type FormData');
        }
        var requestInfo: RequestInit = {
            method: method,
            body: req as any,
            credentials: 'include',
            signal: signal,
        };

        return requestInfo;
    }

    /**
     * Gets the initial promise based on the RequestInit with pre-request processors attached.
     * @param req Payload of the body of the request.
     * @param relativeUrl The app-relative url path of the endpoint to hit foer the request.
     * @param method HttpVerb to use for the request.
     * @param shouldHaveJsonBody Whether or not the request to a JSON or FormData request.
     */
    private getRequestPromise<REQ>(req: REQ, relativeUrl: string, method: string, shouldHaveJsonBody: boolean, signal: AbortSignal): Promise<RequestInit>  {
        let info = this.createRequestObject(req, method, shouldHaveJsonBody, signal);
        let promise = Promise.resolve(info);

        let globalPreRequestsRev = globalPreRequests.reverse();
        globalPreRequestsRev.forEach(function (processor: RequestProcessor) {
            promise = promise.then(args => processor.success(relativeUrl, args), processor.error);
        });

        let preRequestsRev = this.preRequests.reverse();
        preRequestsRev.forEach(function (processor: RequestProcessor) {
            promise = promise.then(args => processor.success(relativeUrl, args), processor.error);
        });
        return promise;
    }

    /**
     * Append post-requests processors to Response promise.
     * @param req Payload of the body of the request.
     * @param resolvedUrl Resolved site-root url.
     * @param method HttpVerb to use for the request.
     * @param promise Whether or not the request to a JSON or FormData request.
     */
    private appendToResponsePromise<REQ>(req: REQ, resolvedUrl: string, method: string, promise: Promise<Response>): Promise<Response>  {
        let globalPostRequestsRev = globalPostRequests.reverse();
        globalPostRequestsRev.forEach(function (processor: ResponseProcessor) {
            promise = promise.then(args => processor.success, processor.error);
        });

        let postRequestsRev = this.postRequests.reverse();
        postRequestsRev.forEach(function (processor: ResponseProcessor) {
            promise = promise.then(processor.success, processor.error);
        });

        return promise;
    }

    /**
     * Resolves a relative url to a site-root url.
     * @param relativeUrl Relative url to resolve.
     */
    private resolveUrl(relativeUrl: string): string {
        return new URL(relativeUrl, window.location.origin).href;
    }
}

/**
 * Adds a pre-request processor to global list or just one api service instance.
 * Returns function to call to remove pre-request processor.
 * @param preRequest The RequestProcessor to add.
 * @param api optional api instance (if null add to global list).
 */
export function addPreRequestProcessor(preRequest: RequestProcessor, api?: BaseApi | null | undefined) {
    if (api) {
        return api.addPreRequestProcessor(preRequest);
    }
    return add(preRequest, globalPreRequests);

}

/**
 * Adds a post-request processor to global list or just one api service instance.
 * Returns function to call to remove post-request processor.
 * @param postRequest The RequestProcessor to add.
 * @param api optional api instance (if null add to global list).
 */
export function addPostRequestProcessor(postRequest: ResponseProcessor, api?: BaseApi | null | undefined) {
    if (api) {
        return api.addPostRequestProcessor(postRequest);
    }
    return add(postRequest, globalPostRequests);

}

/**
 * Clears all pre-request processor from the api service instance.
 * @param api optional api instance (if null clears global list).
 */
export function clearPreRequestProcessors(api: BaseApi | null | undefined) {
    if (api) {
        api.clearPreRequestProcessor();
    } else {
        globalPreRequests = [];
    }
}

/**
 * Clears all post-request processor from the api service instance.
 * @param api optional api instance (if null clears global list).
 */
export function clearPostRequestProcessors(api: BaseApi | null | undefined) {
    if (api) {
        api.clearPostRequestProcessor();
    } else {
        globalPostRequests = [];
    }
}

export default BaseApi;
