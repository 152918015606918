import { PageHeader } from "@ant-design/pro-layout";
import { Button, Card, Col, DatePicker, Divider, Flex, Layout, Modal, Row, Space, Tag, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Routes from "src/consts/Routes";
import { useBinInfoContext } from "src/queries/BinInfoContext"
import { MoistureDisplayChart, TemperatureDisplayChart, MoistureDisplay, GetShivversMoistureData } from "./MoistureDisplay";
import { Overview, ReadingOnOffDisplay } from "./Overview";
import { GetShivversAlertData, ShivversAlertTable, ShivversTapeStrip } from "./TapeStrip";
import { IpTag, ShivversTag } from "./ShivversTag";
import { DryerSettingsCard } from "./DryerSettingsText";
import { RedoOutlined } from "@ant-design/icons";
import { SystemStatusChangeBackgroundLegend } from "./ChartStatusLegend";
import utc from 'dayjs/plugin/utc' // ES 2015
import timezone from 'dayjs/plugin/timezone' // ES 2015
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import ShivversService from "src/api/ShivversService";
import RoleUtil from "src/utils/RoleUtil";
dayjs.extend(utc);
dayjs.extend(timezone);

export const ChartsTab = () => {
    const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
    const binInfo = useBinInfoContext();

    return <>
        <Card title={
            <Flex gap="large" align="center" wrap="wrap">
                <Typography.Text>Charts</Typography.Text>
                <DatePicker.RangePicker allowClear
                    onChange={(dates, dateStrings) => {
                        const userTimezone = dayjs.tz.guess();

                        let startOf = null;
                        let endOf = null;
                        if (dates?.[0] != null) {
                            const convertedStart = dayjs.tz(dates[0], userTimezone);
                            startOf = dayjs.tz(convertedStart?.startOf?.('day'));
                        }
                        if (dates?.[1] != null) {
                            const convertedEnd = dayjs.tz(dates[1], userTimezone);
                            endOf = convertedEnd?.endOf?.('day');
                        }

                        setDates([startOf, endOf]);
                    }}
                    showTime={false} />
            </Flex>
        }
            //</>size="small"
            extra={
                <SystemStatusChangeBackgroundLegend />
            }>
            <Row justify={"space-between"} gutter={[24, 32]}>
                <Col md={12} xs={24}>
                    <MoistureDisplayChart binId={binInfo.id} deviceId={binInfo.deviceId!} value={dates} />
                </Col>
                <Col md={12} xs={24}>
                    <TemperatureDisplayChart binId={binInfo.id} deviceId={binInfo.deviceId!} value={dates} />
                </Col>
            </Row>
            <MoistureDisplay value={dates} />
        </Card>
    </>;
}

export const PremierStatsPage = () => {

    const history = useHistory();
    const location = useLocation();
    const binInfo = useBinInfoContext();

    document.title = binInfo.name ?? "No device name";

    const lastReadingQuery = useQuery({
        queryKey: [binDBKeys.moistureDisplay(binInfo.linkIpAddress!), { last: 1 }],
        queryFn: async (q) => await ShivversService.lastReadings(binInfo.linkIpAddress!, 1, q.signal),
        refetchOnWindowFocus: false,
        staleTime: 1000,
    })

    const lastTapestripTime = lastReadingQuery?.data?.[0]?.dateinUtc != null ? dayjs(lastReadingQuery?.data?.[0]?.dateinUtc) : null;

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showTapestripLog, setShowTapestripLog] = useState(false);

    // timestrip history datepicker: todo: refactor
    const [tapestripHistoryModalRange, setTapestripHistoryModalRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);

    const queryClient = useQueryClient();
    queryClient.prefetchQuery({
        queryFn: async (q) => await GetShivversMoistureData(binInfo.linkIpAddress!, undefined, q.signal),
        queryKey: [...binDBKeys.moistureDisplay(binInfo.linkIpAddress!), { startDate: null, endDate: null }],
    });

    queryClient.prefetchQuery({
        queryFn: async (q) => await GetShivversAlertData(binInfo.linkIpAddress!, q.signal),
        queryKey: binDBKeys.alertDataShivvers(binInfo.linkIpAddress!),
    });

    return <>
        <Layout.Content className="dashboard">
            <Row justify={'space-between'} align="middle">
                <Col>
                    <PageHeader
                        style={{ width: "100%", paddingRight: 16, paddingLeft: 10 }}
                        onBack={() => {
                            // key unique to the location, only present if we came to this page from another first using React Router. ex. clicking details in BinDetails.
                            // https://reactrouter.com/en/main/start/concepts#locations
                            const hasHistory = !(location?.key == null);
                            if (!hasHistory) {
                                // going to the homepage is the best we can do currently (no knowledge of prior history or upper route/component to go back to)
                                history.push(Routes.HOME_ROUTE);
                            } else {
                                history.goBack();
                            }
                        }}
                        title={binInfo.name}
                        tags={[
                            <ShivversTag />,
                            <IpTag ip={binInfo?.linkIpAddress} />,
                        ]}
                    />
                </Col>
                <Col>
                    <Typography.Text style={{ textAlign: "center", verticalAlign: "center" }} type="secondary">{`Last Tape Reading: ${lastTapestripTime != null ? lastTapestripTime?.format("MM/DD/YYYY h:mm a") : '----'}`}</Typography.Text>
                </Col>
                <Col style={{ marginLeft: "auto" }}>
                    <Button size="small" icon={<RedoOutlined />} type="primary" />
                </Col>
            </Row>

            <Modal title={<>
                <Flex gap="large" align="center" wrap="wrap">
                    <Typography.Text>Tapestrip</Typography.Text>
                    <DatePicker.RangePicker allowClear
                        onChange={(dates, dateStrings) => {
                            const userTimezone = dayjs.tz.guess();

                            let startOf = null;
                            let endOf = null;
                            if (dates?.[0] != null) {
                                const convertedStart = dayjs.tz(dates[0], userTimezone);
                                startOf = dayjs.tz(convertedStart?.startOf?.('day'));
                            }
                            if (dates?.[1] != null) {
                                const convertedEnd = dayjs.tz(dates[1], userTimezone);
                                endOf = convertedEnd?.endOf?.('day');
                            }

                            setTapestripHistoryModalRange([startOf, endOf]);
                        }}
                        showTime={false} />
                </Flex>
            </>}
                styles={{ body: { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' } }}
                width={"60%"}
                footer={null}
                open={showTapestripLog} onOk={() => {
                    setShowTapestripLog(false)
                }} onCancel={() => {
                    setShowTapestripLog(false)
                }}>
                <ShivversTapeStrip range={tapestripHistoryModalRange} />
            </Modal>

            <Modal title="Alerts"
                styles={{ body: { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' } }}
                width={"85%"}
                footer={null}
                open={showAlertModal} onOk={() => {
                    setShowAlertModal(false)
                }} onCancel={() => {
                    setShowAlertModal(false)
                }}>
                <ShivversAlertTable />
            </Modal>



            <Row justify={'space-around'} gutter={[16, 16]}>
                <Col xs={24}
                    xl={8}
                    style={{
                        justifyContent: "center",
                    }}>
                    <Flex flex="wrap" justify={'center'}>
                        <Card
                            title="Last Tape Reading"
                            extra={
                                <Flex gap="small">
                                    <Button onClick={() => {
                                        setShowTapestripLog(true);
                                    }}>Tapestrip</Button>
                                    <Button onClick={() => {
                                        setShowAlertModal(true);
                                    }}>Alerts</Button>
                                </Flex>
                            }
                            style={{
                                width: "100%",
                            }}
                            styles={{
                                body: {
                                    padding: 0,
                                }
                            }}
                        >
                            <Overview layout="horizontal" showLastTapeReadingDate={true} />
                        </Card>
                    </Flex>
                </Col>
                <Col xs={24} xl={8}>
                    <Flex justify={'center'} flex="wrap">
                        <ReadingOnOffDisplay />
                    </Flex>
                </Col>
                <Col xs={24} xl={8}>
                    <Flex justify={"center"}>
                        <DryerSettingsCard />
                    </Flex>


                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <ChartsTab />
                </Col>
            </Row>
        </Layout.Content>
    </>;
}
