import { Button, Col, Modal, Row, Typography } from "antd";
import React from "react";
import { ReportedFormFanSettings, SettingsFormValues, WeatherMonitorForm } from "../features/WeatherMonitorForm";
import { FormInstance } from "antd/lib/form/Form";
import BinDTO from "src/models/BinDTO";
import { CurrentReadings } from "../features/WeatherMonitorAntdOnly";
import { useForm } from "antd/es/form/Form";


interface ManualFanSetingsModal {
    open: boolean;
    form?: FormInstance<SettingsFormValues>,
    onSubmit: (values: SettingsFormValues) => Promise<boolean>,
    onCancel: () => void;
    fanSettings: Partial<ReportedFormFanSettings> | null | undefined,
    binDTO: BinDTO,
    deviceId: string,

}
export const ManualFanSettingsModal = (props: ManualFanSetingsModal) => {

    console.log("manual fan modal props", props);

    const [form] = useForm<SettingsFormValues>(props.form);



    return <>
        <Modal title="Review Manual Fan Settings" open={props.open} onCancel={props.onCancel} footer={[
                                        <Button key="back" onClick={props.onCancel}>
                                        Cancel (No Changes)
                                    </Button>
        ]} width={"700px"} >
        <Row>
            <Col>
            <Typography.Text type="secondary">The following fan settings are being used, make changes if necessary.</Typography.Text>
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            <Col flex="0 1 360px">
                <WeatherMonitorForm deviceId={props.deviceId} onCancel={props.onCancel} onSubmit={props.onSubmit} greyControl={false} binDTO={props.binDTO} form={form} newFanSettings={props.fanSettings} />
        </Col>
        <Col xs={24} md={8} >
            <CurrentReadings bin={props.binDTO} fans={props.binDTO.fans} ambientAirCombined={props.binDTO.ambientAir} plenumAirCombined={props.binDTO?.plenumAir} fanRunTimeSeconds={props.binDTO.grain?.fanRunTimeSeconds} />
        </Col>
        </Row>
        </Modal>
    </>;
}