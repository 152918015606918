import * as React from "react";
import { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Switch, Spin, Button, Skeleton, notification, Tabs, TabsProps, Tag, } from "antd";
import BinDTO from "src/models/BinDTO";
import InfiniteScroll from "react-infinite-scroll-component";
import SolenoidIgnoredStateDTO from "src/models/SolenoidIgnoredStateDTO";
import SetIgnoredSolenoidsRequestDTO from "src/models/SetIgnoredSolenoidsRequestDTO";
import BinApiService from "src/api/BinApiService";
import DesiredIgnoredSolenoidDTO from "src/models/DesiredIgnoredSolenoidDTO";
import SensorIgnoredStateDTO from "src/models/SensorIgnoredStateDTO";
import SetIgnoredSensorStateDTO from "src/models/SetIgnoredSensorStateDTO";
import SetIgnoredSensorRequestDTO from "src/models/SetIgnoredSensorRequestDTO";


export interface SensorActuatorControlModalProps {
    open: boolean;
    closeModal(): void;
    bin: BinDTO | undefined;
}

export const SensorActuatorControlModal: React.FC<SensorActuatorControlModalProps> = ({open, closeModal, bin}) => {

    const handleCloseModal = () => {
        closeModal();
    }

    const onTabChange = () => {

    }

    const ActuatorControl = () => {

        const [ignoredActuatorForm] = Form.useForm<SetIgnoredSolenoidsRequestDTO>();
        const [allBinSolenoidIDs, setAllBinSolenoidIDs] = useState<SolenoidIgnoredStateDTO[]>([]);
        const [loading, setLoading] = useState<boolean>(false);

        const onActuatorControlFinish = async (values: SetIgnoredSolenoidsRequestDTO)=> {
            try {
                setLoading(true);
                values.deviceId = bin?.deviceId!;
                const result = await BinApiService.setIgnoredSolenoids(values);
                notification.success({ message: "Successfully set solenoids to ignore" });
                closeModal();
            } catch (error) {
                notification.error({
                    message: "Could not set solenoids to ignore",
                    description: error?.errorDetails?.detail ?? "Contact support"
                });
            } finally {
                setLoading(false);
            }
        }

        const resetData = () => {
            let ignoredSolenoids : string[] = bin?.desiredProperties?.ignoredSolenoids ?? [];

            let allSolenoids: SolenoidIgnoredStateDTO[] = [];
            bin?.stacks?.forEach(s => {
                s.valves?.forEach(v => {
                    if (v?.spoolCylinderId != null) {
                        let solenoidAvailable = ignoredSolenoids?.find(b => b === v.spoolCylinderId) != null ? false : true;
                        allSolenoids.push({
                            solenoidId: v.spoolCylinderId,
                            available: solenoidAvailable
                        });
                    }
                    if (v?.gateCylinderId != null) {
                        let solenoidAvailable = ignoredSolenoids?.find(b => b === v.gateCylinderId) != null ? false : true;
                        allSolenoids.push({
                            solenoidId: v.gateCylinderId,
                            available: solenoidAvailable
                        });
                    }
                });
            });

            let requestForm: SetIgnoredSolenoidsRequestDTO = {
                deviceId: bin?.deviceId!,
                solenoids: allSolenoids
            };

            ignoredActuatorForm.setFieldsValue(requestForm as any);
            setAllBinSolenoidIDs(allSolenoids);
        }

        useEffect(() => {
            if(bin && !ignoredActuatorForm.isFieldsTouched()){
                resetData();
            }
        }, [bin]);

        return (
            <Form scrollToFirstError={true} autoComplete="off" form={ignoredActuatorForm} onFinish={onActuatorControlFinish}>
                <div
                    id="scrollableDiv"
                    style={{
                        height: 500,
                        overflow: "auto",
                        padding: "0 16px",
                        border: "1px solid rgba(140, 140, 140, 0.35)"
                    }}
                >
                    <Spin spinning={loading}>
                        <InfiniteScroll
                            dataLength={allBinSolenoidIDs.length}
                            next={() => {}}
                            hasMore={false}
                            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                            scrollableTarget="scrollableDiv"
                        >
                            <Form.List name="solenoids">
                                {fields => (
                                    <>
                                        {fields.map(({ name }) => {
                                            const solenoid = allBinSolenoidIDs[name];
                                            return (
                                                <Row style={{ padding: "10px" }}>
                                                    <Col>
                                                        <Form.Item name={[name, "available"]} valuePropName="checked">
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <span style={{ paddingLeft: "10px" }}>{`${solenoid.solenoidId}`}</span>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </>
                                )}
                            </Form.List>
                        </InfiniteScroll>
                    </Spin>
                </div>
                <Row style={{ paddingTop: "10px", justifyContent:"end"}}>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        )
    }

    const SensorControl = () => {

        const [ignoredSensorForm] = Form.useForm();
        const [loading, setLoading] = useState<boolean>(false);
        const [allBinSensorIds, setAllBinSensorIds] = useState<SensorIgnoredStateDTO[]>([]);

        const onSensorControlFinish = async(values: any) => {
            try {
                setLoading(true);
                values.deviceId = bin?.deviceId!;
                const result = await BinApiService.setIgnoredSensors(values);
                notification.success({ message: "Successfully set sensors to ignore" });
                closeModal();
            } catch (error) {
                notification.error({
                    message: "Could not set sensors to ignore",
                    description: error?.errorDetails?.detail ?? "Contact support"
                });
            } finally {
                setLoading(false);
            }
        }


        const resetData = () => {
            let ignoredSensors : string[] = bin?.desiredProperties?.ignoredSensors ?? [];

            let allSensors: SensorIgnoredStateDTO[] = [];
            bin?.opiMoistureCables?.forEach( (opiMc) => {
                opiMc?.rhtStates?.forEach( (rht) => {
                    if(rht?.id != null){
                        let sensorAvailable = ignoredSensors?.find(b => b === rht.id) != null ? false : true;
                        allSensors.push({
                            sensorId: rht.id,
                            available: sensorAvailable,
                            currentMCReading: rht.mc,
                            currentTempReading: rht.temperature
                        })
                    }
                })
            });

            bin?.temperatureCables?.forEach( (tempCable) => {
                tempCable?.thermocouples?.forEach( (tc) => {
                    if(tc?.id != null){
                        let sensorAvailable = ignoredSensors?.find(b => b === tc.id) != null ? false : true;
                        allSensors.push({
                            sensorId: tc.id,
                            available: sensorAvailable,
                            currentMCReading: null,
                            currentTempReading: tc.temperature
                        })
                    }
                })
            });

            bin?.binSenseMoistureCables?.forEach( (binsenseCable) => {
                binsenseCable?.rhtStates?.forEach( (rht) => {
                    if(rht?.id != null){
                        let sensorAvailable = ignoredSensors?.find(b => b === rht.id) != null ? false : true;
                        allSensors.push({
                            sensorId: rht.id,
                            available: sensorAvailable,
                            currentMCReading: rht.mc,
                            currentTempReading: rht.temperature
                        })
                    }
                })
            });

            bin?.moistureCables_RF?.forEach( (rfMc) => {
                rfMc?.wirelessStates?.forEach( (rht) => {
                    if(rht?.id != null){
                        let sensorAvailable = ignoredSensors?.find(b => b === rht.id) != null ? false : true;
                        allSensors.push({
                            sensorId: rht.id,
                            available: sensorAvailable,
                            currentMCReading: rht.mc,
                            currentTempReading: rht.temperature
                        })
                    }
                })
            });

            let requestForm: SetIgnoredSensorRequestDTO = {
                deviceId: bin?.deviceId!,
                sensors: allSensors
            };

            ignoredSensorForm.setFieldsValue(requestForm as any);
            setAllBinSensorIds(allSensors);
        }

        useEffect(() => {
            if(bin && !ignoredSensorForm.isFieldsTouched()){
                resetData();
            }
        }, [bin]);

        return (
            <Form scrollToFirstError={true} autoComplete="off" form={ignoredSensorForm} onFinish={onSensorControlFinish}>
                <div
                    id="scrollableDiv"
                    style={{
                        height: 500,
                        overflow: "auto",
                        padding: "0 16px",
                        border: "1px solid rgba(140, 140, 140, 0.35)"
                    }}
                >
                    <Spin spinning={loading}>
                        <InfiniteScroll
                            dataLength={allBinSensorIds.length}
                            next={() => {}}
                            hasMore={false}
                            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                            scrollableTarget="scrollableDiv"
                        >
                            <Form.List name="sensors">
                                {fields => (
                                    <>
                                        {fields.map(({ name }) => {
                                            const sensor = allBinSensorIds[name];
                                            let readingsBadgeStr = "";
                                            if(sensor.currentMCReading != null){
                                                readingsBadgeStr += ` ${roundTo(sensor.currentMCReading, 1)}%MC`
                                            }
                                            if(sensor.currentTempReading != null){
                                                readingsBadgeStr += ` ${roundTo(sensor.currentTempReading, 1)}°F`
                                            }
                                            return (
                                                <Row style={{ padding: "10px" }}>
                                                    <Col>
                                                        <Form.Item name={[name, "available"]} valuePropName="checked">
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <span style={{ paddingLeft: "10px" }}>{`${sensor.sensorId}`}</span>
                                                    </Col>
                                                    <Col style={{paddingLeft:"5px"}}>
                                                        <Tag style={{ backgroundColor:'#adc9c9'}}>{`${readingsBadgeStr}`}</Tag>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </>
                                )}
                            </Form.List>
                        </InfiniteScroll>
                    </Spin>
                </div>
                <Row style={{ paddingTop: "10px", justifyContent:"end"}}>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        );
    }

    const PlenumControl = () => {
        const [ignorePlenumTemperatureForm] = Form.useForm();
        const [loading, setLoading] = useState<boolean>(false);

        const onIgnoreTemperatureControlFinish = async(values: any) => {
            try {
                setLoading(true);
                const ignoreTemperature = values?.ignoreTemperature ?? false;
                const result = await BinApiService.ignorePlenumTemperatureSensors(ignoreTemperature, bin?.deviceId!);
                notification.success({ message: "Successfully set whether to ignore the plenum temperature" });
                closeModal();
            } catch (error) {
                notification.error({
                    message: "Could not set whether to ignore the plenum temperature",
                    description: error?.errorDetails?.detail ?? "Contact support"
                });
            } finally {
                setLoading(false);
            }
        }

        const resetData = () => {
            ignorePlenumTemperatureForm.setFieldValue("ignoreTemperature", bin?.ignorePlenumTemperatureSensor ?? false);
        }

        useEffect(() => {
            if(bin && !ignorePlenumTemperatureForm.isFieldsTouched()){
                resetData();
            }
        }, [bin]);

        return (
            <Form form={ignorePlenumTemperatureForm} onFinish={onIgnoreTemperatureControlFinish}>
                <div
                    id="scrollableDiv"
                    style={{
                        height: 500,
                        overflow: "auto",
                        padding: "0 16px",
                        border: "1px solid rgba(140, 140, 140, 0.35)"
                    }}
                >
                    <Spin spinning={loading}>
                        <Row style={{ padding: "10px" }}>
                            <Col style={{ padding: "7px" }}>
                                <span>Ignore Plenum Temperature</span>
                            </Col>
                            <Col>
                                <Form.Item name={"ignoreTemperature"}>
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </div>
                <Row style={{ paddingTop: "10px", justifyContent:"end"}}>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        )
    }

    const items: TabsProps['items'] = [
        {
            key: "1",
            label: "Actuators",
            children: <ActuatorControl/>
        },
        {
            key: "2",
            label: "Sensors",
            children: <SensorControl/>
        },
        {
            key: "3",
            label: "Plenum",
            children: <PlenumControl/>
        }
    ]

    return (
        <Modal
        title="Sensor/Actuator Control"
        open={open}
        onCancel={handleCloseModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }} 
        width="20%"
        >
            <p style={{ fontSize: "0.875rem", fontStyle: "italic", color: "#888" }}>
                Sensors/Actuators that are checked will be actuated by the system, whereas those that are not will be ignored.
                These lists are submitted <span style={{fontWeight:"bold"}}>seperately</span>
            </p>
            <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
        </Modal>
    );
}

export default SensorActuatorControlModal;