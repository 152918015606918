// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';
import GamDTO from './GamDTO';
import CustomMappingDTO from './CustomMappingDTO';
import DesiredRingLayoutDTO from './DesiredRingLayoutDTO';

interface DesiredLayoutDTO {
    binDiameter: number;
    grainEaveHeight: number;
    gamRadius: number;
    gamHeight: number;
    gamBearing: number;
    hasOuterRingGAM: boolean | null;
    hasInnerRingGAM: boolean | null;
    gams: GamDTO[] | null;
    outerRingGAMRadius: number;
    outerRingGAMHeight: number;
    outerRingGAMBearing: number;
    outerGamMapping: CustomMappingDTO | null;
    innerRingGAMRadius: number;
    innerRingGAMHeight: number;
    innerRingGAMBearing: number;
    innerGamMapping: CustomMappingDTO | null;
    fanBearings: number[];
    fanDuctArea: number[];
    rings: DesiredRingLayoutDTO[] | null;
    layersTopHeight: number[] | null;
}
const DesiredLayoutDTO: InterfaceConstructor<DesiredLayoutDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            binDiameter: 0,
            grainEaveHeight: 0,
            gamRadius: 0,
            gamHeight: 0,
            gamBearing: 0,
            hasOuterRingGAM: null,
            hasInnerRingGAM: null,
            gams: [],
            outerRingGAMRadius: 0,
            outerRingGAMHeight: 0,
            outerRingGAMBearing: 0,
            outerGamMapping: null,
            innerRingGAMRadius: 0,
            innerRingGAMHeight: 0,
            innerRingGAMBearing: 0,
            innerGamMapping: null,
            fanBearings: [],
            fanDuctArea: [],
            rings: [],
            layersTopHeight: [],
        },
        initValues);
    }
};

export default DesiredLayoutDTO;
