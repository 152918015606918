import AdminApiService from "src/api/AdminApiService";
import { useQuery } from "@tanstack/react-query";
import { QueryTypes } from "./shared";
import { queryClient } from "src";

export const useGetAllAzureIoTDeviceIDsQuery = (options: QueryTypes = {}) => {
    return useQuery<string[]>(["getAllAzureIoTDeviceIDsQuery"],
        async (q) => {
            return await AdminApiService.getAllBins(q.signal);
        },
        {
            enabled: options?.enabled,
            refetchInterval: options?.refetchInterval,
            refetchOnWindowFocus: options?.refetchOnWindowFocus,
            initialData: () => {
                const data = queryClient.getQueryData<string[] | undefined>(["getAllAzureIoTDeviceIDsQuery"]);
                if(data != null){
                    return data as string[];
                }
                return [];
            },
            
        });
}