// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';

interface LoginDTO {
    userName: string | null;
    password: string | null;
    rememberMe: boolean | null;
    devUserName: string | null;
}
const LoginDTO: InterfaceConstructor<LoginDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            userName: null,
            password: null,
            rememberMe: null,
            devUserName: null,
        },
        initValues);
    }
};

export default LoginDTO;
