import { BaseChartProps } from "./TemperatureCableChart";
import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "antd";
import dayjs from "dayjs";
import { round } from "lodash-es";
import React, { useState, useCallback, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { queryClient } from "src";
import BinApiService from "src/api/BinApiService";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { RangeValue } from "./ChartContainer";
import { ChartDatePicker } from "./Co2Chart";
import { ChartData, ChartDataset } from "chart.js";
import FanHeaterDataPointDTO from "src/models/FanHeaterDataPointDTO";
import { any } from "prop-types";
import { fanStatusColor, heaterStatusColor, tooltipItemRound, useFanHeaterChangeHistory } from "./shared";
import _uniqueId from "lodash/uniqueId";
import { useModeChangeHistory } from "./LayerMCHistoryChart";

enum FanStatusEnum {
  ON = "ON",
  OFF = "OFF",
}

// max 12 fans
const manualColors: Array<string> = ["#cd5051", "#813332", "#51cccc", "#328180", "#8ecc50", "#588133", "#8f51cc", "#583381", "#cdaf51", "#806c33", "#51cd71", "#338144"];


export const PlenumPressureChart = (props: BaseChartProps & { hasHeater: boolean } & {showTitle?: boolean}) => {


  const currentDate = dayjs();
  const priorDate = currentDate.subtract(7, 'days');

  const [legendId] = useState(_uniqueId('legend-'));
  const isControlled = typeof props.value != 'undefined';
  const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);

  // Internally, we need to deal with some value. Depending on whether
  // the component is controlled or not, that value comes from its
  // props or from its internal state.

  const value = isControlled ? props.value : internalValue;

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    if (props.onChange) {
      props.onChange(values);
    }
    else {
      setIntervalValue(values);
    }
  }, [props.onChange]);
  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "Fan&Heater", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, keepPreviousData: true, retry: 0, queryFn: async (q) => {
      const now = value?.[1];
      const priorMonth = value?.[0];
      const results = await BinApiService.getFanHeaterChart(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
      queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });

      return results;
    }, enabled: (props.binId ?? 0) > 0,
  });

  
  // @ts-ignore string | dayJS not possible
  const plenumPressureDatasets: Array<ChartDataset<'line'>> = chartQuery.data?.fans?.flatMap((fan, index) => {

    return [{
      data: fan.data!, label: `Fan ${fan.label!} Pressure`, indexAxis: 'x', yAxisID: 'yPlenum', parsing: {
        yAxisKey: 'plenumPressureInH2O',
      },
      backgroundColor: manualColors[index],
      borderColor: manualColors[index],
    }];
  }) ?? [];



  const datasets: Array<ChartDataset<'line'>> = [];


  datasets.push(...plenumPressureDatasets);
  const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
  const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);


  return <div>

    {!isControlled && <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />}
    <Skeleton loading={chartQuery.data == null}>
      {chartQuery.data != null && <section style={{ width: "100%", minHeight: props.chartHeight ?? "240px"}}>
        <Line options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          layout: {
            padding: {
              top: 16,
              bottom: 16,
            },
          },
          datasets: {
            line: {
              pointRadius: 0 // disable for all `'line'` datasets
            }
          },
          scales: {
            x: {
              type: "time",
              time: {
                minUnit: 'day',
              },
            },

            yPlenum: {
                beginAtZero: true,
                max: 20,
                title: {
                    text: "InH2O",
                    display: true,
                },

            },

          },


          plugins: {
            annotation: {
              annotations: [
                ...modeHistory.annotations,
                ...fanHeaterChangeHistory.annotations,
              ],
            },
            legend: {
              position: 'top' as const,
            },
            title: {
              display: props.showTitle ?? true,
              text: 'Plenum Pressure',
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: tooltipItemRound,
              },
            },
          },
        }} data={{ datasets: datasets }} />
      </section>}
    </Skeleton>
  </div>

}
