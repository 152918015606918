import { Line } from 'react-chartjs-2';
import React, { useCallback, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { binDBKeys } from 'src/pages/binOverview/BinCommander';
import { useBinStateFromAzure } from 'src/queries/useBinStateFromAzure';
import { getUserTimezoneOffset } from '../HomeScreen/BinDetails';
import { Button, DatePicker, Skeleton } from 'antd';
import BinApiService from 'src/api/BinApiService';
import { BaseChartProps } from './TemperatureCableChart';
import { RangePickerProps } from 'antd/es/date-picker';
import { queryClient } from 'src';
import { useModeChangeHistory } from './LayerMCHistoryChart';
import { useFanHeaterChangeHistory } from './shared';

type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>['onChange']>>[0] | null;

export interface ChartDatePickerProps {
  startDate: Dayjs;
  endDate: Dayjs;
  onDateRangeChange: (val: RangeValue, formatString: [string, string]) => void;
}

export const ChartDatePicker = (props: ChartDatePickerProps) => {

  const currentDate = props.endDate ?? dayjs();
  const priorDate = props.startDate ?? currentDate.subtract(60, 'days');

  const [dates, setDates] = useState<RangeValue>([priorDate, currentDate]);
  const [value, setValue] = useState<RangeValue>([priorDate, currentDate]);
  const [open, setOpen] = useState(false);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 366;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 366;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = useCallback((open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
    setOpen(open);
  }, []);

  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
      { label: 'Last 6 Hours', value: [dayjs().subtract(6, 'hours'), dayjs()] },
      { label: 'Last 12 Hours', value: [dayjs().subtract(12, 'hours'), dayjs()] },
      { label: 'Last 3 Days', value: [dayjs().add(-3, 'd'), dayjs()] },
      { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
      { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
      { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
      { label: 'Last 60 Days', value: [dayjs().add(-60, 'd'), dayjs()] },
      { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
      { label: '6 Months', value: [dayjs().add(-30 * 6, 'd'), dayjs()] },
      { label: '12 Months', value: [dayjs().add(-30 * 12, 'd'), dayjs()] },
    ];

  return <DatePicker.RangePicker

    value={dates || value}
    //presets={rangePresets}
    popupClassName='chart-range-picker'
    disabledDate={disabledDate}
    format={"MM/DD/YYYY"}
    onCalendarChange={(val) => {
      setDates(val);
      // Fix for Antd 5.12 so the datepicker closes after start and end date selection
      if (val?.[0] != null && val?.[1] != null) {
        setOpen(false);
      }
    }}
    //inputReadOnly
    allowClear={false}
    open={open}
    onChange={(val, formatString) => {
      const startDay = val?.[0]?.startOf('day') ?? null;
      const endDay = val?.[1]?.endOf('day') ?? null;
      const newRange: RangeValue = [startDay, endDay];
      props.onDateRangeChange(newRange, formatString);
      setValue(val);
    }}
    onOpenChange={onOpenChange}
    renderExtraFooter={(mode) => {
      return <div className='presets' style={{ display: "flex", gap: "8px", padding: "4px", justifyContent: "center", maxWidth: 288}}>
        {rangePresets.map(preset => {
          return <Button size="small" style={{ width: "16ch" }} type="dashed" onClick={() => {
            setDates(preset.value);
            props.onDateRangeChange(preset.value, ['', '']);
            setValue(preset.value);
            setOpen(false);
          }}
          >{preset.label}</Button>
        })}
      </div>
    }}
    changeOnBlur />;
}

export const Co2Chart = (props: BaseChartProps & {height?: number}) => {

  const currentDate = dayjs();
  const priorDate = currentDate.subtract(30, 'days');

  const [value, setValue] = useState<RangeValue>([priorDate, currentDate]);

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    setValue(values);
  }, []);

  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "CO2", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, keepPreviousData: true, retry: 0, queryFn: async (q) => {
      const now = value?.[1];
      const priorMonth = value?.[0];
      const results = await BinApiService.getCO2History(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
      queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });

      return results;
    }, enabled: (props.binId ?? 0) > 0,
  });

  const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
  const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);


  if (chartQuery.data == null) {
    return <Skeleton loading></Skeleton>;
  }

  return <div>

    <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />
    <section style={{ width: "100%", minHeight: props.height ?? "40vh" }}>
      <Line options={{
        responsive: true,
        animation: false,
        datasets: {
          line: {
            pointRadius: 0 // disable for all `'line'` datasets
          }
        },
        aspectRatio:3,
        // maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            time: {
              minUnit: 'day',
            },
          },
          y: {
            beginAtZero: false,
            title: {
              font: {
                size: 16,
              },
              display: true,
              //text: "Temperature °F",
              text: (chartQuery.data?.unitLabel)!,
            }
          }
        },
        // parsing: false,
        plugins: {
          annotation: {
            annotations: [
              ...modeHistory.annotations,
              ...fanHeaterChangeHistory.annotations,
            ],
          },
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'CO2',
          },
          tooltip: {
            mode: 'index',
            intersect: false
          },
        },
      }} data={{ datasets: [{ data: chartQuery.data.data, label: (chartQuery.data?.label)!, indexAxis: 'x' }] }} />
    </section>
  </div>
};
