import { ConfigProvider, Space, Badge } from "antd"
import React from "react"
import { fanOnBackgroundColor, FanPausedBackgroundColor } from "src/pages/dashboard/charts/shared"

export const SystemStatusChangeBackgroundLegend = () => {

    return <ConfigProvider theme={{ components: { Badge: { fontSizeSM: 32} } }}>
        <Space direction="horizontal" size="middle">
            <Badge color="transparent" text="Off" styles={{
                indicator: {
                    outline: "1px solid gray",
                }
            }} />
            <Badge color={fanOnBackgroundColor} text="On" />
        </Space>
    </ConfigProvider>
}