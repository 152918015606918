import AdminApiService from "src/api/AdminApiService";
import { useQuery } from "@tanstack/react-query";
import { QueryTypes } from "./shared";
import { queryClient } from "src";
import  EnterpriseApiService  from "src/api/EnterpriseApiService";
import GrowerDTO from "src/models/GrowerDTO";

export const useGetAllGrowersQuery = (options: QueryTypes = {}) => {
    return useQuery<GrowerDTO[]>(["getAllGrowersQuery"],
        async (q) => {
            return await EnterpriseApiService.getGrowerIDs();
        },
        {
            enabled: options?.enabled,
            refetchInterval: options?.refetchInterval,
            refetchOnWindowFocus: options?.refetchOnWindowFocus,
            initialData: () => {
                const data = queryClient.getQueryData<GrowerDTO[] | undefined>(["getAllGrowersQuery"]);
                if(data != null){
                    return data as GrowerDTO[];
                }
                return [];
            },
            
        });
}