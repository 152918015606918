import AdminApiService from "src/api/AdminApiService";
import { useQuery } from "@tanstack/react-query";
import BinAssemblySubmittalDTO from "src/models/BinAssemblySubmittalDTO";
import { deviceQueryKeys } from "src/pages/dashboard/HomeScreen/BinDetails";
import { QueryTypes } from "./shared";

export const useGetBinAssemblySubmittalQuery = <TData = BinAssemblySubmittalDTO>(deviceId: string, options: QueryTypes = {}) => {
    return useQuery({
        queryKey: [...deviceQueryKeys.device(deviceId), "getBinAssemblySubmittalQuery"],
        queryFn: async (q) => {
            if(deviceId != ""){
                return await AdminApiService.getBinAssemblySubmittal(deviceId, q.signal);
            }
            return {} as BinAssemblySubmittalDTO;
        },
        
        enabled: options?.enabled,
        refetchInterval: options?.refetchInterval,
        refetchOnWindowFocus: options?.refetchOnWindowFocus
    })
}