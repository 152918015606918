// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';
import DesiredDynamicMCDTO from './DesiredDynamicMCDTO';
import DesiredCurrentCalibrationsDataDTO from './DesiredCurrentCalibrationsDataDTO';
import DesiredPressureOffsettingDataDTO from './DesiredPressureOffsettingDataDTO';
import RhSensorEnum from 'src/consts/RhSensorEnum';
import TemperatureSensorEnum from 'src/consts/TemperatureSensorEnum';

interface DesiredOverridesDTO {
    keepFansOff: boolean;
    keepCompressorOff: boolean;
    disableErrorEmails: boolean;
    grainHeightAtPeak: number | null;
    grainHeightAtMiddle: number | null;
    emcOffsetsByLayer: number[];
    dynamicMC: DesiredDynamicMCDTO | null;
    emcOffsetString: string | null;
    weatherConditionsMaxMc: number | null;
    weatherConditionsMinMc: number | null;
    weatherConditionsMaxTemperatureF: number | null;
    weatherConditionsMinTemperatureF: number | null;
    noHeater: boolean | null;
    monitoringBin: boolean | null;
    fansCalibration: DesiredCurrentCalibrationsDataDTO[] | null;
    heatersCalibration: DesiredCurrentCalibrationsDataDTO[] | null;
    compressorCalibration: DesiredCurrentCalibrationsDataDTO | null;
    plenumPressureOffsets: DesiredPressureOffsettingDataDTO[] | null;
    initialGrainTempF: number | null;
    plenumHighTempF: number | null;
    commonCloseTimePeriod: number | null;
    restackExtraExhaust: boolean | null;
    restackExtraVent: boolean | null;
    crossflowExtraVent: boolean | null;
    crossflowExtraExhaust: boolean | null;
    repeatPreDry: boolean | null;
    fanStaggerSeconds: number | null;
    kpLayerTimeAdjustment: number | null;
    kiLayerTimeAdjustment: number | null;
    kdLayerTimeAdjustment: number | null;
    rhSensorsType: RhSensorEnum | null;
    temperatureSensorsType: TemperatureSensorEnum | null;
}
const DesiredOverridesDTO: InterfaceConstructor<DesiredOverridesDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            keepFansOff: false,
            keepCompressorOff: false,
            disableErrorEmails: false,
            grainHeightAtPeak: null,
            grainHeightAtMiddle: null,
            emcOffsetsByLayer: [],
            dynamicMC: null,
            emcOffsetString: null,
            weatherConditionsMaxMc: null,
            weatherConditionsMinMc: null,
            weatherConditionsMaxTemperatureF: null,
            weatherConditionsMinTemperatureF: null,
            noHeater: null,
            monitoringBin: null,
            fansCalibration: [],
            heatersCalibration: [],
            compressorCalibration: null,
            plenumPressureOffsets: [],
            initialGrainTempF: null,
            plenumHighTempF: null,
            commonCloseTimePeriod: null,
            restackExtraExhaust: null,
            restackExtraVent: null,
            crossflowExtraVent: null,
            crossflowExtraExhaust: null,
            repeatPreDry: null,
            fanStaggerSeconds: null,
            kpLayerTimeAdjustment: null,
            kiLayerTimeAdjustment: null,
            kdLayerTimeAdjustment: null,
            rhSensorsType: RhSensorEnum.Opi,
            temperatureSensorsType: TemperatureSensorEnum.Thermocouple,
        },
        initValues);
    }
};

export default DesiredOverridesDTO;
