import { CloseSquareOutlined, FullscreenOutlined, RedoOutlined } from "@ant-design/icons";
import { useIsFetching } from "@tanstack/react-query";
import { Button, Col, Row, Select, SelectProps, Space } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useState } from "react";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { AmbientPlenumChart, LayerDewPointChart } from "./AmbientPlenumChart";
import { RangeValue } from "./ChartContainer";
import { ChartDatePicker } from "./Co2Chart";
import { LayerMCHistoryChart } from "./LayerMCHistoryChart";
import { LayerTemperatureHistoryChart as LayerTemperatureHistoryChart } from "./LayerTemperatureHistoryChart";
import { PlenumPressureChart } from "./PlenumPressureChart";
import { TargetLayerChart } from "./TargetLayerChart";
import { BaseChartProps, OPICableChart, TemperatureCableOpiChart, BinSenseCableChart, TemperatureCableBinSenseChart } from "./TemperatureCableChart";
import BinDTO from "src/models/BinDTO";
import { MoistureRemovalRHChart } from "./MoistureRemovalRHChart";
import { MoistureRemovalAHChart } from "./MoistureRemovalAHChart";
import { RangeFinderChart } from "./RangeFinderChart";
import { getDefaultTemperatureSensorType } from "src/pages/shared/binDTOUtils";

export enum ExtraCharts {
  OPIMCSensors = 0,
  OPITemperatureSensors = 1,
  TemperatureCables = 2,
  BinSenseTemperatureSensors = 3,
  BinSenseMCSensors = 4,
}

export const DryingDashboard = (props: BaseChartProps & {binDTO: BinDTO}) => {
  const currentDate = dayjs();
  const priorDate = currentDate.subtract(7, 'days');


  const isControlled = typeof props.value != 'undefined';
  const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);
  const inprogress = useIsFetching([...binDBKeys.bin(props.binId), "charts"]);

  // Internally, we need to deal with some value. Depending on whether
  // the component is controlled or not, that value comes from its
  // props or from its internal state.

  const value = isControlled ? props.value : internalValue;

  const preferredTemperatureSensorType = props.binDTO?.temperatureSensorsType ?? getDefaultTemperatureSensorType(props.binDTO);

  const onDateChange = useCallback((values: RangeValue, formatter?: any) => {
    if (props.onChange) {
      props.onChange(values);
    }
    else {
      setIntervalValue(values);
    }
  }, [props.onChange]);

  const [extraCharts, setExtraCharts] = useState<Array<ExtraCharts>>([]);


  const extraChartSelectOptions = useMemo(() => {
    const extraChartSelectOptions: SelectProps['options'] = [];

    extraChartSelectOptions.push({
      label: "OPI MC (per sensor)",
      value: ExtraCharts.OPIMCSensors,
    });

    extraChartSelectOptions.push({
      label: "OPI Temp (per sensor)",
      value: ExtraCharts.OPITemperatureSensors,
    });

    extraChartSelectOptions.push({
      label: "BinSense MC (per sensor)",
      value: ExtraCharts.BinSenseMCSensors,
    });

    extraChartSelectOptions.push({
      label: "BinSense Temp (per sensor)",
      value: ExtraCharts.BinSenseTemperatureSensors,
    });
    return extraChartSelectOptions;
  }, []);


  return <>
    <div>
      <Space direction="horizontal" wrap>
        <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />
        <Button type="primary" disabled={inprogress > 0} icon={<RedoOutlined spin={inprogress > 0} />} onClick={() => {
          onDateChange([value?.[0]!, dayjs()], ['', '']);
        }}>
        </Button>

        <Select mode='multiple' value={extraCharts} options={extraChartSelectOptions} onChange={change => {
          setExtraCharts(change);
        }} placeholder="select extra charts" allowClear style={{ minWidth: 240 }} showSearch maxTagTextLength={16} maxTagCount={2} />
      </Space>

      <div style={{ width: "100%" }}>


        <AmbientPlenumChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} min={0} max={25} chartHeight={128} containerMinHeight={128 * 1} showTitle={false} showRecommendation={false} showTemperatureChart={false} />
        <LayerTemperatureHistoryChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} min={0} max={150} chartHeight={180} yTitle="Temp (°F)" preferredSensorType={preferredTemperatureSensorType} />
        {<LayerDewPointChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} chartHeight={168} yTitle="Temp (°F)" />}
        <LayerMCHistoryChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} min={10} max={25} chartHeight={180} yTitle="MC (%)" />
        {extraCharts.includes(ExtraCharts.OPITemperatureSensors) && <TemperatureCableOpiChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} min={0} max={150} chartHeight={144} />}
        {extraCharts.includes(ExtraCharts.OPIMCSensors) && <OPICableChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} min={10} max={25} chartHeight={144} yTitle="MC (%)" />}
        {extraCharts.includes(ExtraCharts.BinSenseTemperatureSensors) && <TemperatureCableBinSenseChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} min={0} max={150} chartHeight={144} />}
        {extraCharts.includes(ExtraCharts.BinSenseMCSensors) && <BinSenseCableChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} min={10} max={25} chartHeight={144} yTitle="MC (%)" />}
        <MoistureRemovalAHChart binId={props.binId} deviceId={props.deviceId} value={value}/>
        <MoistureRemovalRHChart binId={props.binId} deviceId={props.deviceId} value={value}/>
        <TargetLayerChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} chartHeight={168} />
        <RangeFinderChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} chartHeight={168} />
        {props.binDTO?.desiredProperties?.isSeedBin && <PlenumPressureChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} hasHeater={true} value={value} chartHeight={64} showTitle={false} />}
      </div>
    </div>
  </>
}