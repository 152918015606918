import { CameraOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Image, message, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { queryClient } from 'src';
import { ApiError } from 'src/api/ApiResultHandler';
import BinApiService from 'src/api/BinApiService';
import { LiveStreaming } from './LiveStreaming';

export const TakeCameraPicture = (props: { binId: number }) => {
    const [takingImage, setTakingImage] = useState(false);

    return (<Button disabled={takingImage}
        icon={<CameraOutlined spin={takingImage} />}
        onClick={async () => {
            try {
                setTakingImage(true);
                const res = await BinApiService.takeCameraPicture(props.binId);
                console.log("take picture result", res);
                queryClient.invalidateQueries([props.binId, "camera"]);
            } catch (error) {
                if (error instanceof ApiError) {
                    message.error(`Camera: ${error.message}`);
                }
                console.error("take camera picture error:", error);
            } finally {
                setTakingImage(false);
            }
        }}>Take Picture</Button>);
};


export interface CameraImageProps {
    binId: number,
}

export const CameraImage = (props: CameraImageProps) => {
    const imageQuery = useQuery([props.binId, "camera"], {
        queryFn: async (q) => {
            return await BinApiService.getCameraImage(props.binId, q.signal);
        }, enabled: props.binId > 0, staleTime: Infinity, retry: 0
    })

    console.log("the image data", imageQuery.data);


    return (
        <>
            {imageQuery.isInitialLoading && <div>Loading Camera Picture...</div>}
            {imageQuery.error && <>
                <Row justify={"center"} gutter={[16, 8]} style={{ marginBottom: 16 }}>
                    <Col>
                        <span style={{ textAlign: "center" }}><Typography>{(imageQuery.error as ApiError)?.message}</Typography></span>
                    </Col>
                    <Col>
                        <TakeCameraPicture binId={props.binId} />
                        <LiveStreaming />
                    </Col>
                </Row>
            </>}
            {imageQuery.data && <><div>
                <Space direction='vertical'>
                    <Row justify={"center"} gutter={[16, 8]} style={{ marginBottom: 16 }}>
                        <Col>
                            <span style={{ textAlign: "center" }}><Typography>Date: {dayjs(imageQuery.data.lastModifiedUtc).format("MM/DD/YYYY hh:mm:ss A")}</Typography></span>
                        </Col>
                        <Col>
                        <Space wrap direction='horizontal' size="middle">
                            <TakeCameraPicture binId={props.binId} />
                            <LiveStreaming />
                        </Space>
                        </Col>
                    </Row>
                    <Row justify={"center"}>
                        <Col span={24}>
                            <Image src={`data:image/jpeg;base64,${imageQuery.data.base64Image}`} width="100%" />
                        </Col>
                    </Row>
                </Space>
            </div>
            </>}
        </>
    );
};