import { useQuery } from "@tanstack/react-query";
import { QueryTypes } from "./shared";
import { queryClient } from "src";
import  EnterpriseApiService  from "src/api/EnterpriseApiService";
import BinInfoDTO from "src/models/BinInfoDTO";

export const useGetBinsQuery = (desiredGrowerId?: number | null, options: QueryTypes = {}) => {

    const getBinsQueryKey = ["getBinsQuery", desiredGrowerId]

    const getBinsQuery = useQuery<BinInfoDTO[]>(getBinsQueryKey,
        async (q) => {
            if(desiredGrowerId == null){
                return await EnterpriseApiService.getBinIDs();
            }else{
                return await EnterpriseApiService.getBinIDsByGrower(desiredGrowerId);
            }
        },
        {
            enabled: options?.enabled,
            refetchInterval: options?.refetchInterval,
            refetchOnWindowFocus: options?.refetchOnWindowFocus,
            initialData: () => {
                const data = queryClient.getQueryData<BinInfoDTO[] | undefined>([ "getBinsQuery", desiredGrowerId]);
                if(data != null){
                    return data as BinInfoDTO[];
                }
                return [];
            },
        });

    return { getBinsQuery, getBinsQueryKey }
}