import * as queryString from 'query-string';
import * as React from 'react';
import HistoryUtil from 'src/utils/HistoryUtil';
import LoginDTO from 'src/models/LoginDTO';
import Routes from 'src/consts/Routes';
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    Layout,
    Row,
    Typography,
    FormInstance
    } from 'antd';
import { BreadcrumbsItem } from '../shared/GlobalBreadcrumb';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
import { UserSessionActions } from 'src/redux/actions/UserSessionActions';
import { withRouter } from 'react-router-dom';

const FormItem = Form.Item;
const Content = Layout.Content;

interface LoginState {
    model: LoginDTO;
    returnUrl?: string;
}

interface LoginProps {
    userName: string | null;
    loading: boolean;
    error: any;
    loginAction: (dto: LoginDTO) => void;
}

class Login extends React.Component<LoginProps & RouteComponentProps<{}>, LoginState> {
    private readonly _formRef = React.createRef<FormInstance>();
    private _clickedSubmit: boolean;

    constructor(props: LoginProps & RouteComponentProps<{}>) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        let deepLinkState = queryString.parse(props.location.search) as LoginState;
        console.log(props.location.search);
        this.state = {
            model: LoginDTO.create(),
            returnUrl : deepLinkState.returnUrl
        };
    }

    componentDidUpdate(prevProps: LoginProps, prevState: LoginState) {
        // TODO: refacotor, this is a messy way to check that the login was successful
        if (this.props.userName !== null &&
            this.props.error === null &&
            !this.props.loading &&
            this._clickedSubmit) {
                // Replace so that clicking back goes to the return url (or home) and not the login screen
                if (this.state.returnUrl) {
                    HistoryUtil.replace(this.state.returnUrl);
                } else {
                    HistoryUtil.replace(Routes.DASHBOARD);
                }
        }
    }

    handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
        let model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
        let checkBox = false;
        if (model.rememberMe === "on") {
            checkBox = true;
        }
        const newModel = {...model, rememberMe: checkBox};
        this.props.loginAction(newModel);
        this._clickedSubmit = true;
    }

    render() {
        let alert: JSX.Element | null = null;
        if (this.props.error !== null) {
            let message = 'An error occurred while trying to log you in.';
            if (this.props.error.message) {
                message = this.props.error.message;
            }
            alert = (
                <Alert
                    message="Error"
                    description={message}
                    type="error"
                    showIcon={true}
                    style={{marginBottom: '12px'}}
                />);
        }

        let labelCol = { xs: 24, sm: 6, md: 6, lg: 7, xl: 7, xxl: 7 };
        let wrapperCol = { xs: 24, sm: 17, md: 17, lg: 17, xl: 17, xxl: 17 };
        let wrapperColNoLabel = {};
        Object.keys(wrapperCol).forEach((val) => {
            wrapperColNoLabel[val] = { span: wrapperCol[val], offset: (wrapperCol[val] + labelCol[val] > 24 ? 0 : labelCol[val]) };
        });

        return (
            // type="flex"
            <Content className="content-background-login">
                <BreadcrumbsItem name="login">
                    Login
                </BreadcrumbsItem>
                <Row justify="space-between">
                    <Col className="flex-grow" />
                        <Col md={20} sm={24} xs={24}>
                        <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                            <Typography.Title level={2}>
                                Login
                            </Typography.Title>
                            {alert}
                            <Form ref={this._formRef} layout="horizontal" onFinish={this.handleSubmit}>
                                    <FormItem name="userName" label="Username" rules={[{ required: true, message: 'Username is required' }]}
                                        labelCol={labelCol} wrapperCol={wrapperCol}>
                                        <Input placeholder="Username" />
                                    </FormItem>
                                    <FormItem name="password" label="Password" rules={[{ required: true, message: 'Password is required' }]}
                                        labelCol={labelCol} wrapperCol={wrapperCol}>
                                        <Input.Password placeholder="Password" />
                                    </FormItem>
                                    <FormItem name="devUserName" label="Or Pick" labelCol={labelCol} wrapperCol={wrapperCol} className="hidden">
                                        <Input />
                                    </FormItem>
                                    <FormItem name="rememberMe" wrapperCol={wrapperColNoLabel} >
                                        <Row>
                                            <Col xs={12}>
                                                <Checkbox>Remember Me</Checkbox>
                                            </Col>
                                            <Col xs={12} style={{textAlign: 'right'}}>
                                                <Link to={Routes.REQUEST_RESET_PASSWORD}>Forgot Password?</Link>
                                            </Col>
                                        </Row>
                                    </FormItem>
                                <FormItem wrapperCol={wrapperColNoLabel}>
                                    <Button type="primary"
                                            htmlType="submit"
                                            style={{width: '100%'}}
                                            size="large"
                                        disabled={this.props.loading}>
                                        {this.props.loading ? (<span><LoadingOutlined /> Logging in...</span>) : <span>Login</span>}
                                    </Button>
                                </FormItem>
                            </Form>
                        </div>
                    </Col>
                    <Col className="flex-grow" />
                </Row>
            </Content>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        loginAction: (dto: LoginDTO) => dispatch(UserSessionActions.login(dto))
    };
}

function mapStateToProps(state: StateStoreModel) {
    let userName: string | null = null;
    if (state.UserSession.Value !== null) {
        userName = state.UserSession.Value.userName;
    }
    return {
        loading: state.UserSession.Loading,
        error: state.UserSession.Error,
        userName: userName
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
