import { ConfigProvider, Spin, theme } from "antd";
import { SpinProps} from "antd/es/spin";
import React from "react";

interface CustomSpinProps extends  SpinProps {
    invert?: boolean,
}

export const Spinner = (props: CustomSpinProps) => {
    let {invert, ...rest} = props;
    invert = invert ?? false;
    const {token} = theme.useToken();

    return (
    <ConfigProvider theme={{
        components: {
            Spin: {
                colorPrimary: props.invert ? "white" : token.colorPrimary},
            }
        }
    }>
        <Spin {...rest} />
    </ConfigProvider>
    )
}