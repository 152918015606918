import BinDTO from "src/models/BinDTO";

export enum AutomationType {
    AutoBin = "AutoBin",
    AutoFan = "AutoFan",
    DriStack = "DriStack",
    Special = "Special",
}

export const selectAutomationType = (data: BinDTO | null | undefined): AutomationType => {
    if (data == null) {
        console.debug("binDTO data is null, returning DriStack as default");
        return AutomationType.DriStack;
    };
    const automationType = data?.automationType;
    switch (automationType) {
        case null:
        case "DriStack": {
            return AutomationType.DriStack;
        }
        case "AutoBin":
            return AutomationType.AutoBin;
        default: {
            console.debug(`AutomationType \`${automationType}\` is unknown. Using DriStack`)
            return AutomationType.DriStack;
        }
    }
}