import * as React from 'react';
import { Link } from 'react-router-dom';

class UnauthorizedComponent extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
        <div style={{padding: '24px'}}>
            <h2>401 - Unauthorized</h2>
            <p>You are not authoized to go to this page. Please contact an adminstrator if you feel you got here by mistake.</p>
            <Link to="/">Go to Homepage</Link>
        </div>);
    }
}

export default UnauthorizedComponent;
