import { Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import BatchDTO from "src/models/BatchDTO";

export const formatBatchStartDate = (startdate: string): string => {

    return dayjs(startdate).format("MM/DD/YYYY hh:mm a");

}

export const BatchStartTag = (props: {batchdto: BatchDTO | null | undefined}) => {

    if (props.batchdto?.startDate == null) {
        return null;
    }

    return <Tag color="blue">{`Current batch started ${formatBatchStartDate(props.batchdto?.startDate)}`}</Tag>
}