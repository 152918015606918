import { Button, Card, Form, Select, } from "antd"
import React from "react"
import { useForm } from "react-hook-form"
import UserApiService from "src/api/UserApiService"
import RoleDTO from "src/models/RoleDTO"
import UserDetailDTO from "src/models/UserDetailDTO"


interface ExternalUserEditProps {
    user: UserDetailDTO,
    roles: RoleDTO[],
}
export const ExternalUserEdit = (props: ExternalUserEditProps) => {

    console.log("external role props", props);

    const options = props.roles.map(role => {
        return {value: role.id, label: <span>{role.name}</span>}
    })

    const [form] = Form.useForm();
    return <Card title="Edit External User">
        <Form form={form}
        onFinish={async (values) => {
            await UserApiService.updateExternalUserInfo({
                userId: props.user.id,
                roles: values.roles,
            });
        }}
        initialValues={{
            roles: props.user.roles?.map(r => r.id) ?? []
        }}>
            <Form.Item name="roles" label="Roles">
                <Select mode="multiple" options={options} />
            </Form.Item>
            <Button htmlType="submit">Submit</Button>
        </Form>
    </Card>
}