import { Button, DatePicker, Form, Input, InputNumber, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import layout from "antd/lib/layout";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback } from "react";
import { RequiredRule } from "src/consts/FormConstants";

interface UnloadFormProps {
    open: boolean;
    onCancel: () => void;
    onSubmit: (vals: UnloadFormSubmitValues) => void;
    pendingSubmit: boolean;
}

export interface UnloadFormValues {
    unloadTime: Dayjs,
    finalMC: number | null,
    estimatedBu: number | null,
    destination: string | null,
}

export interface UnloadFormSubmitValues {
    unloadTime: Dayjs,
    finalMC: number,
    estimatedBu: number,
    destination: string | null,
}

export const UnloadForm = (props: UnloadFormProps) => {
    const [form] = useForm();

    const initialValues: UnloadFormValues = {
        unloadTime: dayjs(),
        finalMC: null,
        estimatedBu: null,
        destination: null,
    };

    const onFinsh = useCallback(async function onFinish(vals: UnloadFormSubmitValues) {
        try {
            await form.validateFields();
            console.log("Unload form vals: ", vals);
            props.onSubmit(vals);
        } catch (err) {
            console.error("failed to validate form fields", err);
        }
    }, [props.onSubmit, form]);

    return <>
        <Modal
            key="addUnload"
            centered={true}
            open={props.open}
            destroyOnClose={true}
            onCancel={props.onCancel}
            title={"Load out"}
            footer={[
                <Button key="back" onClick={props.onCancel}>
                    Cancel
                </Button>,
                <Button form="form-add-unload" key="submit" type="primary" htmlType="submit" loading={props.pendingSubmit}>
                    Submit Load Out
                </Button>,
            ]
            }>

            <Form onFinish={onFinsh} form={form} id="form-add-unload" initialValues={initialValues}>

                {<FormItem {...layout} label="Unload Time" name="unloadTime">
                    <DatePicker allowClear={true} style={{ width: '100%' }} showTime={true} format="MM/DD/YYYY h:mm a" />
                </FormItem>}

                {<FormItem {...layout} rules={[RequiredRule]} label="Final Moisture Content (%)" name="finalMC">
                    <InputNumber<number>
                        min={0}
                        max={100}
                        step={0.5}
                        formatter={val => `${val}%`.replace(/[!@#$^&*()a-zA-Z]/g, '')}
                        parser={value => Number(value!.replace('%', ''))} />
                </FormItem>}

                {<FormItem {...layout} rules={[RequiredRule]} label="Estimated Bu" name="estimatedBu">
                    <InputNumber<number>
                        step={.5}
                        min={0}
                        formatter={val => `${val} bu`}
                        parser={value => Number(value!.replace(/[ !@#$%^&*()a-zA-Z]/g, ''))} />
                </FormItem>}
                {<FormItem {...layout} rules={[]} label="Destination" name="destination">
                    <Input maxLength={32} />
                </FormItem>}
            </Form>
        </Modal>
    </>;
}