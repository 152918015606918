import { useMutation } from "@tanstack/react-query"
import { Button, Card, Col, Collapse, Grid, message, Row, Select, Space, Typography } from "antd"
import React, { useCallback, useState } from "react"
import { ApiError } from "src/api/ApiResultHandler"
import BinApiService from "src/api/BinApiService"
import BoardGroup from "src/consts/BoardGroup"
import { LogType, viewBinStateLogsByRange, viewGrainStateLogsByRange, viewRunLogsByRange } from "src/pages/shared/downloadLogsByRange"
import { useBinDTOContext } from "src/queries/BinDTOContext"
import { useBinInfoContext } from "src/queries/BinInfoContext"
import { PopconfirmYesNo } from "./BinStatsPage"
import { DeviceLogRangeForm } from "./DeviceLogsRangeForm"
import { LogViewer } from "./LogViewer"
const { useBreakpoint } = Grid;

const usePowerCycleBoardsMutation = (deviceId: string) => {
    return useMutation({
        mutationFn: async (params: { boardGroup: BoardGroup }) => {
            if (deviceId == null) {
                message.error('Failed to powercycle board group. deviceId was empty');
                return;
            }
            return await BinApiService.powerCycleBoard({ deviceId, which: params.boardGroup })
        },
        onSuccess(data, variables, context) {
            message.success(`Powercycled ${variables.boardGroup} group`, 2);
        },
        onError(error, variables, context) {
            message.error(`Failed to powercycle ${variables.boardGroup} group. ${(error as ApiError)?.description!}`, 3);
        },
    });
};

export const AdminControlsCard = (props: {}) => {
    const binInfo = useBinInfoContext();
    const [powerCycleSelection, setPowerCycleSelection] = useState(BoardGroup.All);

    const handlePowerCycleSelectChange = useCallback((value: BoardGroup) => {
        setPowerCycleSelection(value);
    }, []);

    const powercycleBoardsMutation = usePowerCycleBoardsMutation(binInfo.deviceId!);

    const screens = useBreakpoint();
    const isSm = screens.xl === false;

    return <Card title="Admin Controls" styles={{ body: { paddingTop: 0, paddingBottom: 0 } }}>
        <Row >
            <Col span={24}>
                <Typography.Title level={4}>Select board group to powercycle</Typography.Title>
                <Space direction="horizontal" >
                    <Select disabled={powercycleBoardsMutation.isLoading} value={powerCycleSelection} onChange={handlePowerCycleSelectChange} style={{ width: '24ch' }}>
                        <Select.Option value={BoardGroup.All}>ALL</Select.Option>
                        <Select.Option value={BoardGroup.Hub}>Hub</Select.Option>
                        <Select.Option value={BoardGroup.Fans}>Fans</Select.Option>
                        <Select.Option value={BoardGroup.StackBoards}>Stack</Select.Option>
                        <Select.Option value={BoardGroup.SensingBoards}>SensorHub</Select.Option>
                        <Select.Option value={BoardGroup.RHTBoards}>RHTBoards</Select.Option>
                        <Select.Option value={BoardGroup.OPIBoards}>OPI</Select.Option>
                    </Select>
                    <PopconfirmYesNo title={`Powercycle ${powerCycleSelection}?`} onConfirm={() => powercycleBoardsMutation.mutate({boardGroup: powerCycleSelection})}>
                        <Button type="primary" danger loading={powercycleBoardsMutation.isLoading}>Powercycle board group</Button>
                    </PopconfirmYesNo>
                </Space>
            </Col>
        </Row>
        <Row>
            <Col>
                <LogViewer deviceId={binInfo.deviceId!} />
            </Col>
        </Row>
        <br />
        <Row gutter={[0, 4]} style={{ width: isSm ? "100%" : "50%" }}>
            <LogFileDownloads />
        </Row>
    </Card>
}

export const LogFileDownloads = (props: any) => {
    const binInfo = useBinInfoContext();
    const bindtoContext = useBinDTOContext();

    const getHourlyForecast = useCallback(async () => {
        try {
            const forecast = await BinApiService.getHourlyForecast(binInfo.id);
            console.log(forecast);
        }
        catch (error) {
            console.log(error);
        };
    }, [binInfo]);

    const viewBinState = useCallback(async () => {
        var today = new Date();
        var utcToday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
        var dateStart = utcToday.toISOString().substring(0, 10); // yyyy-mm-dd   UTC
        var dateEnd = dateStart;
        await viewBinStateLogsByRange(binInfo.deviceId!, dateStart, dateEnd);
        // this.viewLogFile('BinState');
    }, [binInfo]);

    const handleLogClickSubmit = useCallback((type: LogType) => (dateStart: string, dateEnd: string) => {
        const clientFolder = binInfo.deviceId!;
        switch (type) {
            case "BinState":
                viewBinStateLogsByRange(clientFolder!, dateStart, dateEnd);
                break;
            case "GrainState":
                viewGrainStateLogsByRange(clientFolder!, dateStart, dateEnd);
                break;
            case "Run":
                viewRunLogsByRange(clientFolder!, dateStart, dateEnd);
                break;
        }
    }, [bindtoContext]);



    return <>

        <Col span={24}>
            <Button style={{ width: '100%', overflow: 'hidden' }} onClick={viewBinState}>Get Latest Bin State</Button>
        </Col>

        <Col span={24}>
            <Collapse>
                <Collapse.Panel header="Bin State by Date" key="1">
                    <DeviceLogRangeForm onSubmit={handleLogClickSubmit('BinState')} />
                </Collapse.Panel>
                <Collapse.Panel header="Grain State by Date" key="2">
                    <DeviceLogRangeForm onSubmit={handleLogClickSubmit('GrainState')} />
                </Collapse.Panel>
                <Collapse.Panel header="Run Logs by Date" key="3">
                    <DeviceLogRangeForm onSubmit={handleLogClickSubmit('Run')} />
                </Collapse.Panel>
            </Collapse>
        </Col>

        <Col span={24}>
            <Button style={{ width: '100%', overflow: 'hidden' }} onClick={getHourlyForecast}>Log Hourly Forecast</Button>
        </Col>
    </>
}