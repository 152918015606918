// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';
import BinInfoDTO from './BinInfoDTO';
import EnterpriseCropDataDTO from './EnterpriseCropDataDTO';

interface SiteDTO {
    id: number;
    growerId: number;
    siteName: string | null;
    siteLat: number;
    siteLng: number;
    location: string | null;
    mapsZoomLevel: number | null;
    binList: BinInfoDTO[] | null;
    siteCropData: EnterpriseCropDataDTO[] | null;
}
const SiteDTO: InterfaceConstructor<SiteDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            id: 0,
            growerId: 0,
            siteName: null,
            siteLat: 0,
            siteLng: 0,
            location: null,
            mapsZoomLevel: null,
            binList: [],
            siteCropData: [],
        },
        initValues);
    }
};

export default SiteDTO;
