import { Tag } from "antd"
import React from "react"
import { PRIMARY_BLUE_SHIVVERS } from "src/app/theme"

export const ShivversTag = () => {

    return <Tag color={PRIMARY_BLUE_SHIVVERS}>Shivvers</Tag>
}

export const IpTag = (props: {ip: string | null | undefined}) => {

    return <Tag color={PRIMARY_BLUE_SHIVVERS}>{props.ip}</Tag>;
}