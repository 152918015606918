import { DownloadOutlined } from "@ant-design/icons";
import { Button, InputNumber, message, Space } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import BinApiService from "src/api/BinApiService";
import { RestartDriStackModuleButton } from "src/pages/admin/RestartModuleButton";

interface LogViewerProps {
    deviceId: string;
}

export const LogViewer = (props: LogViewerProps) => {

    const [logContent, setLogContent] = React.useState<string | null>('');
    const [fetchingLogs, setFetchingLogs] = React.useState(false);

    const [downloadLink, setDownloadLink] = React.useState('');
    const [numTail, setNumStail] = React.useState(100);

    const downloadLogs = () => {
        if (logContent == null) {
            return;
        }
        const data = new Blob([logContent], { type: 'text/plain;charset=utf-8' })

        // this part avoids memory leaks
        if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink)
    
        // update the download link state
        setDownloadLink(window.URL.createObjectURL(data))    
    }

    const getModuleLogs = () => {
        setFetchingLogs(true);
        BinApiService.getLogsFromDristackmodule(props.deviceId, numTail)
        .then(res => {
            setLogContent(res.payloadText);
        }).catch(err => {
            message.error("Failed to fetch logs", err);
            console.error(err);
        }).finally(() => {
            setFetchingLogs(false);
        })
    }

    React.useEffect(() => {
        downloadLogs();
    }, [logContent]);

    React.useEffect(() => {
        if (props.deviceId == null) {
            return;
        }
        setLogContent('');
    }, [props.deviceId])

    const handleInputChange = (input: number | null | undefined) => {
        if (input == undefined) {
            return;
        }
        
        try {
            setNumStail(input);
            console.debug(`Set numTails to ${input}`);
        }
        catch {
            console.error("Invalid number entered: ", input);
        }
    }

    const getLogButtonText = (): string => {
        if (logContent?.length === 0) {
            return "Get Logs";
        }
        else {
            return "Refresh";
        }
    }

    return (
        <>
            <Title level={4}>Get dristackmodule Logs</Title>
            
            <Space >            
            <InputNumber<number> min={1} max={9999} defaultValue={numTail} placeholder={"Lines"} onChange={handleInputChange} />
            <Button loading={fetchingLogs} onClick={getModuleLogs}>{getLogButtonText()}</Button>
            <Button disabled={logContent?.length === 0} download={`${props.deviceId}-dristackmodule-logs.txt`} href={downloadLink} icon={<DownloadOutlined />} >Download</Button>
            <RestartDriStackModuleButton deviceId={props.deviceId} />
            </Space>
            <br />
            <br />
            <div id="dristackmodule-log" className={"logViewContent"}>
                {logContent}
            </div>
        </>
    );
}
