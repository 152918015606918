import React, { useMemo } from "react";
import { Tooltip, Typography } from "antd";
import BinDTO from "src/models/BinDTO";
import FanOffReason from "src/consts/FanOffReason";
import { formatNumber } from "../dashboard/BinStatusPage/HeaterControls";
import { InfoCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { AmbientConditionsStatusIndicator } from "./WeatherMonitorAntdOnly";
import { computeWeatherConditionInfo, useBinDTOContext } from "src/queries/BinDTOContext";
import { WeatherConditionRangeStatus } from "src/queries/BinDTOContext";
import OperatingMode from "src/consts/OperatingMode";

export const WeatherConditionsLimitTooltipText = () => {
    const bindtoContext = useBinDTOContext();
    const {binDTO, additionalWeatherInfo} = bindtoContext;

    if (binDTO == null) {
        return null;
    }

    const lastUpdatedDate = dayjs(binDTO?.weatherMonitorState?.currentWeatherConditions?.lastUpdated);

    return <>
    <Typography.Text style={{color: "white"}}>Last known conditions (10-minute average):
<br />EMC: {formatNumber(binDTO?.weatherMonitorState?.currentWeatherConditions?.mc, {decimalPlaces: 1, filler: "", suffix: "%"})}
<br />Temp: {formatNumber(binDTO?.weatherMonitorState?.currentWeatherConditions?.temp, {decimalPlaces: 1, filler: "", suffix: "℉"})}
<br />{lastUpdatedDate.isValid() && lastUpdatedDate?.format('l LT')}</Typography.Text>
        {[WeatherConditionRangeStatus.Entering].includes(additionalWeatherInfo?.weatherConditionRangeStatus!) && <>
            <br />
            <Typography.Text style={{ color: 'white' }}>
                Fan will un-pause in {additionalWeatherInfo?.timeTillPhaseChange?.humanize()} if conditions remain in range.
            </Typography.Text>
        </>}
        {[WeatherConditionRangeStatus.Exitting].includes(additionalWeatherInfo?.weatherConditionRangeStatus!) && <>
            <br />
            <Typography.Text style={{ color: 'white' }}>
                Fan will pause in {additionalWeatherInfo?.timeTillPhaseChange?.humanize()} if conditions do not improve.
            </Typography.Text>
        </>}
    </>

}

export const AcVoltageIsOnTooltipText = (isPlugged: boolean | null) => {
    return <>
        {(isPlugged === null || isPlugged === undefined) ?
         <Typography.Text style={{ color: 'white' }}>
            It is unknown whether the AC voltage is on or not.
        </Typography.Text> : 
        <Typography.Text style={{ color: 'white' }}>
            The AC voltage is {isPlugged ? "on" : "off"}.
        </Typography.Text>}
    </>
}

export const fanStatusText = (bin: BinDTO | null) => {
    if (bin == null) {
        return null;
    }
    //if ([FanOffReason.WeatherConditions].includes(bin.fanOperations?.offReason!)) {
    //    return null;
    //}

    if (bin.isFanOn) {
        return <Typography.Text>Fan(s) are <Typography.Text strong>running</Typography.Text>&nbsp;{!bin.fanOperations?.ignoreWeatherConditions && <AmbientConditionsStatusIndicator binDTO={bin} />}</Typography.Text>
    }
    if (!bin.isFanOn) {
        return <Typography.Text>Fan(s) are <Typography.Text strong>OFF</Typography.Text> </Typography.Text>
    }

    return null;
}

const LastUpdatedHelpText = (props: {bin: BinDTO | null}) => {
    if (props.bin?.weatherMonitorState?.currentWeatherConditions == null) {
        return null;
    }

    const lastUpdated = props.bin?.weatherMonitorState?.currentWeatherConditions?.lastUpdated;
    if (lastUpdated == null) {
        return null;
    }

    const formattedDate = dayjs(lastUpdated).format('MM/DD/YYYY h:mm a');

    return <span>
        The incoming air EMC uses the last minute's average of readings to un-pause the fan. The last known value from {formattedDate} is {formatNumber(props.bin?.weatherMonitorState?.currentWeatherConditions?.mc, {decimalPlaces: 1, filler: "", suffix: "%"})}.
    </span>

}

const WeatherPauseReasonText = (props: {bin: BinDTO | null}) => {
    const bin = props.bin;
    if (bin == null) {
        return null;
    }
    const weatherState = bin.weatherMonitorState;
    if (weatherState == null) {
        return null;
    }
    if (weatherState.currentWeatherConditions == null) {
        return null;
    }

    return <Typography.Text>
        Conditions automatically checked every few minutes.
        <br />Last known value: {formatNumber(weatherState.currentWeatherConditions?.mc, {decimalPlaces: 1, suffix: "%", filler: ""})}&nbsp;
        <Tooltip title={<LastUpdatedHelpText bin={props.bin} />} destroyTooltipOnHide>
            <InfoCircleOutlined />
        </Tooltip>
    </Typography.Text>
}

export const pauseReasonText = (bin: BinDTO | null) => {
    if (bin == null) {
        return null;
    }

    if (bin.fanOperations?.offReason === null || bin.fanOperations?.offReason === undefined) {
        return null
    }

    let text = <Typography.Text>Fan pause reason: <Typography.Text strong >{bin?.fanOperations?.offReason}</Typography.Text></Typography.Text>;

    if (bin.fanOperations?.offReason === FanOffReason.Requested && bin.operatingMode === OperatingMode.Storage) {
        text = <Typography.Text>Fan pause reason: Weather conditions are not good enough to run aeration.</Typography.Text>
    } 
    else if (bin.fanOperations?.offReason === FanOffReason.PlenumTemperatureAboveLimit) {
        text = <Typography.Text>Fan pause reason: Plenum temperature is above the set limit.</Typography.Text>
    } 
    else if (bin.fanOperations?.offReason === FanOffReason.GrainFillHeightBelowLimit) {
        text = <Typography.Text>Fan pause reason: Grain height is not at the minimum height.</Typography.Text>
    }
    else if (bin?.fanOperations?.offReason === FanOffReason.WeatherConditions) {
        text = <section><Typography.Text>Fan pause reason: incoming air conditions are not within the setpoints.</Typography.Text>
        <Typography.Text> The fan will resume when conditions are within proper conditions for 10 minutes.&nbsp;<AmbientConditionsStatusIndicator binDTO={bin} /></Typography.Text>
        <br /></section>
    }
    else if (bin?.fanOperations?.offReason === FanOffReason.FanTimer) {
        text = <Typography.Text>Fan pause reason: Fan timer reached zero.</Typography.Text>
    }
    else if (bin?.fanOperations?.offReason === FanOffReason.PlenumTemperatureCoolDownDelay) {
        text = <Typography.Text>Fan pause reason: the plenum temperature was above allowable limit for more than 5 mins. 
        10-min cooldown in process before attempting to re-start.</Typography.Text>
    }

    return (<>{text}</>);
}

export const hasRangeFinder = (binDTO: BinDTO | null | undefined): boolean => {
    return (binDTO?.innerSonar != null || binDTO?.outerSonar != null)
}