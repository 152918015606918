import { Plugin } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';

// Adapted from https://github.com/chartjs/chartjs-plugin-annotation/discussions/880#discussioncomment-7254496
export const annotationFixOverlapPlugin: Plugin = {
    id: 'annotations-overlap-fix',
    afterUpdate: (chart) => {
        const usingAnnotations = chart.isPluginEnabled(annotationPlugin.id);
        if (!usingAnnotations) {
            return;
        }

        let ann = annotationPlugin as any;
        const elementList = ann
            // private variable. will break when the annotations ever gets around to exposing a public way to get the state
            ?._getState(chart)
            // adjust the line annotations with labels
            ?.elements.filter((i: any) => i?.options?.type == 'line' && i?.label != null);
        const map = new Map();
        let changed = false;

        for (var i = 0; i < elementList.length; i++) {
            const currentElement = elementList[i];

            const currentElementWidth =
                currentElement.label.x2 - currentElement.label.x;
            if (currentElement.label.x <= chart?.chartArea?.left) {
                currentElement.label.x = chart?.chartArea?.left;
                currentElement.label.x2 =
                    currentElement.label.x + currentElementWidth;
                elementList[i] = currentElement;
            }
            if (currentElement?.label.x2 > chart?.chartArea?.right) {
                currentElement.label.x2 = chart?.chartArea?.right;
                currentElement.label.x =
                    currentElement.label.x2 - currentElementWidth;
                elementList[i] = currentElement;
            }
            for (var j = i + 1; j < elementList.length; j++) {
                const nextElement = elementList[j];
                if (
                    currentElement.label.x2 >= nextElement.label.x &&
                    currentElement.label.y === nextElement.label.y
                ) {
                    const elementHeight = nextElement.label.y2 - nextElement.label.y;
                    if (map.has(currentElement.options.id)) {
                        const currentElementList = map.get(
                            currentElement.options.id
                        );
                        nextElement.label.y =
                            currentElementList[
                                currentElementList.length - 1
                            ].label.y2;
                        nextElement.label.y2 = nextElement.label.y + elementHeight;
                        elementList[j] = nextElement;
                        currentElementList.push(nextElement);
                        map.set(
                            currentElement.options.id,
                            currentElementList
                        );
                    } else {
                        nextElement.label.y = currentElement.label.y2;
                        nextElement.label.y2 = nextElement.label.y + elementHeight;
                        elementList[j] = nextElement;
                        map.set(currentElement.options.id, [nextElement]);
                    }
                    elementList[j] = nextElement;
                    changed = true;
                }
            }
        }

        if (changed) {
            chart.draw();
        }
    },
}