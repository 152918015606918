import { Typography } from 'antd';
import * as React from 'react';

interface FormattedAddressProps {
    name?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    addressLine3?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
}

function FormattedAddress(props: FormattedAddressProps) {
    let { name, addressLine1, addressLine2, addressLine3, city, state, zip } = props;
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {name ? (<Typography.Paragraph>{name}</Typography.Paragraph>) : null}
            {addressLine1 ? (<Typography.Paragraph>{addressLine1}</Typography.Paragraph>) : null}
            {addressLine2 ? (<Typography.Paragraph>{addressLine2}</Typography.Paragraph>) : null}
            {addressLine3 ? (<Typography.Paragraph>{addressLine3}</Typography.Paragraph>) : null}
            <Typography.Paragraph>{city}, {state} {zip}</Typography.Paragraph>
        </div>
    );
}

export default FormattedAddress;
