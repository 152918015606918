import * as React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  notification,
  Layout,
  Col,
  Row,
  Select,
  Modal,
  Spin,
  Card,
  Switch,
  Descriptions,
  Typography,
  InputNumber
} from "antd";
import BoardGroup from "src/consts/BoardGroup";
import PCBLookupDTO from "src/models/PCBLookupDTO";
import HardwareYear from "src/consts/HardwareYear";
import AdminApiService from "src/api/AdminApiService";
import { useGetAllPCBsQuery } from "src/queries/useGetAllPCBsQuery";
import { useQueryClient } from "@tanstack/react-query";
import { ApiError } from "src/api/ApiResultHandler";
const Item = Descriptions;


export const AddBoardLookup = () => {

  const queryClient = useQueryClient();
  const [form] = Form.useForm<PCBLookupDTO>();
  const [selectedPCB, setSelectedPCB] = useState<PCBLookupDTO | null>();
  const [loading, setLoading] = useState<boolean>(false)
  const [addNewLookupModalOpen, setAddNewLookupModalOpen] = useState<boolean>(false);
  
  const getAllPCBsQuery = useGetAllPCBsQuery({enabled: true, refetchOnWindowFocus: false});
  const allPCBs = getAllPCBsQuery.data;

  const submitPcbLookupForm = async (values: any) => {
    try{
      setLoading(true);
      let canId = parseInt(values.baseCanID)
      let submittal: PCBLookupDTO = {
        pcbLookupID: 0,
        name: values.name,
        dateEntered: values.dateEntered,
        initials: values.initials,
        baseCanID: canId,
        defaultable: values.defaultable,
        boardGroupId: values.boardGroupId,
        hardwareYearId: values.hardwareYearId,
        maxTempCablePorts: values.maxTempCablePorts,
        maxThermocouplesPerCable: values.maxThermocouplesPerCable,
        maxMCCablePorts: values.maxMCCablePorts,
        maxMCSensorPerCable: values.maxMCSensorPerCable,
        maxWirelessCablePorts: values.maxWirelessCablePorts,
        maxWirelessSensorPerCable: values.maxWirelessSensorPerCable,
        maxNumberOfSolenoidPerBank: values.maxNumberOfSolenoidPerBank,
        maxNumCurrentSensor: values.maxNumCurrentSensor,
        maxNumPressureSensor: values.maxNumPressureSensore,
        description: values.description
      }
      
      const result = await AdminApiService.uploadPCBLookup(submittal)
      form.resetFields();
      notification.success({ message: "PCB lookup uploaded" })

      await queryClient.invalidateQueries({queryKey: ["getAllPCBsQuery"]});
      // setPcbLookups(pcbs)
      setLoading(false);

    }catch(err){
      notification.error({
        message: err.errorDetails.detail,
        description: err.detail,
      })
      setLoading(false)
    }
    setAddNewLookupModalOpen(false);
  }

  const handleSelectPCBLookup = (values: string) => {
    let pcb: PCBLookupDTO = allPCBs.find(b => b.name === values)!
    setSelectedPCB(pcb);
  }

  useEffect(() => {
    if(getAllPCBsQuery.isError){
      let error = getAllPCBsQuery.error as ApiError;
        notification.error({
          message: "Could not fetch PCB lookups",
          description: error?.errorDetails?.detail ?? "Contact support"
        });
    }
  }, [getAllPCBsQuery.error]);

  const getBoardGroupString = (which: BoardGroup) => {
    switch(which){
      case BoardGroup.Hub:
        return "HubBoards"
      case BoardGroup.Fans:
        return "FanBoards"
      case BoardGroup.StackBoards:
        return "StackBoards"
      case BoardGroup.SensingBoards:
        return "SensorHubBoards"
      case BoardGroup.RHTBoards:
        return "RHT Boards"
      case BoardGroup.OPIBoards:
        return "OPI Boards"
      default:
        return "N/A"
    }
  }


  return (
    <Layout.Content className="addBoardLookup" style={{ width: "80%" }}>
      <Row>
        <legend>
          <b>Add Board Lookup</b> -
          <i>
            <small>
              Enter a new PCB lookup for device profiles to pull from
            </small>
          </i>
        </legend>
      </Row>
      <Row style={{paddingBottom: "10px"}}>
        <Select
          style={{ width: "32ch", maxHeight: "300px", overflow: "auto" }}
          placeholder="Select PCB lookup"
          showSearch
          // optionFilterProp="children"
          options={
            allPCBs.map((pcb) => ({
              label: pcb.name,
              value: pcb.name
            }))}
          onSelect={handleSelectPCBLookup}
          
        />
      </Row>
      {selectedPCB &&    
      <div>
        <Row>
          <Card title={selectedPCB?.name}>
            <Descriptions
              bordered={true}
              // column={{ xxl: 2, xl: 1, lg: 1, md: 2, sm: 1, xs: 1 }}
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              size="small"
              >
              <Descriptions.Item label="Initials">
                <Typography.Text>{selectedPCB.initials}</Typography.Text>
              </Descriptions.Item>

              <Descriptions.Item label="Base Can ID">
                <Typography.Text>{`0x${selectedPCB.baseCanID.toString(16)}`}</Typography.Text>
              </Descriptions.Item>

              <Descriptions.Item label="System Year">
                <Typography.Text>{selectedPCB.hardwareYearId}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Board Group">
                {getBoardGroupString(selectedPCB.boardGroupId)}
              </Descriptions.Item>

              {selectedPCB.boardGroupId === BoardGroup.SensingBoards && 
              <>
                <Descriptions.Item label="Max temp. cable ports">
                  <Typography.Text>{selectedPCB.maxTempCablePorts}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="Max thermocouple per cable">
                  {selectedPCB.maxThermocouplesPerCable}
                </Descriptions.Item>

                <Descriptions.Item label="Max wireless cable ports">
                  <Typography.Text>{selectedPCB.maxWirelessCablePorts}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="Max wireless sensor per port">
                  {selectedPCB.maxWirelessSensorPerCable}
                </Descriptions.Item>
              </>
              }

              <Descriptions.Item label="Max MC cable ports">
                <Typography.Text>{selectedPCB.maxMCCablePorts}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Max MC sensor per port">
                {selectedPCB.maxMCSensorPerCable}
              </Descriptions.Item>

              {
                selectedPCB.boardGroupId === BoardGroup.StackBoards &&
                <>
                  <Descriptions.Item label="Max number of solenoids per bank">
                    {selectedPCB.maxNumberOfSolenoidPerBank}
                  </Descriptions.Item>    
                </>
              }

              <Descriptions.Item label="Max number of current sensors">
                <Typography.Text>{selectedPCB.maxNumCurrentSensor}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Max number of pressure sensors">
                {selectedPCB.maxNumPressureSensor}
              </Descriptions.Item>

            </Descriptions>
          </Card>
          </Row>
        </div>  
      }

      <Row>
        <Col style={{paddingRight:"10px"}}>
          <Button onClick={() => setAddNewLookupModalOpen(true)}>Add new PCB lookup</Button>
        </Col>
      </Row>

      <Modal
        open={loading}
        centered
        closable={false}
        footer={null}
        width={100}
        style={{ textAlign: 'center' }}
      >
        <Spin size="large" />
      </Modal>

      <Modal
        open={addNewLookupModalOpen}
        centered
        onCancel={() => setAddNewLookupModalOpen(false)}
        okButtonProps={{style: {display: 'none'}}}
        width={"100%"}
        title={"Add New PCB Lookup"}
      >
        <Form
          scrollToFirstError={true}
          onFinish={submitPcbLookupForm}
          autoComplete="off"
          form={form}
        >
          <div>
            <Row>
              <Col span={4}>
                <Form.Item
                  label="System Year"
                  name={"hardwareYearId"}
                  key={"hardwareYearId"}
                  rules={[{ required: true, message: "Enter the hardware year ID" }]}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Select options={[
                    { label: '2021', value: HardwareYear.HARDWARE_2021 },
                    { label: '2022', value: HardwareYear.HARDWARE_2022 },
                    { label: '2023', value: HardwareYear.HARDWARE_2023 },
                  ]}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Base CAN ID"
                  name={"baseCanID"}
                  key={"baseCanID"}
                  rules={[{ required: true, message: "Enter the base CANID" }]}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={"0x"}/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Initials"
                  name={"initials"}
                  key={"initials"}
                  rules={[{ required: true, message: "Enter the initials" }]}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input maxLength={4}/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item
                  label="PCB Name"
                  name={"name"}
                  key={"name"}
                  rules={[{ required: true, message: "Enter the PCB name" }]}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input style={{ width: "200px" }} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Board Group"
                  name={"boardGroupId"}
                  key={"boardGroupId"}
                  rules={[{ required: true, message: "Enter the board group ID" }]}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Select options={[
                    { label: 'Hub', value: BoardGroup.Hub },
                    { label: 'Fans', value: BoardGroup.Fans },
                    { label: 'StackBoards', value: BoardGroup.StackBoards },
                    { label: 'SensingBoards', value: BoardGroup.SensingBoards },
                    { label: 'RHTBoards', value:BoardGroup.RHTBoards },
                    { label: 'OPIBoards', value: BoardGroup.OPIBoards }]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item
                  label="Max temp. cable ports"
                  name={"maxTempCablePorts"}
                  key={"maxTempCablePorts"}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={0} style={{ width: "75px" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Max num. thermocouple per ports"
                  name={"maxThermocouplesPerCable"}
                  key={"maxThermocouplesPerCable"}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={0} style={{ width: "75px" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item
                  label="Max MC cable ports"
                  name={"maxMCCablePorts"}
                  key={"maxMCCablePorts"}

                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={0} style={{ width: "75px" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Max MC sensor per port"
                  name={"maxMCSensorPerCable"}
                  key={"maxMCSensorPerCable"}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={0} style={{ width: "75px" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item
                  label="Max wireless cable ports"
                  name={"maxWirelessCablePorts"}
                  key={"maxWirelessCablePorts"}

                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={0} style={{ width: "75px" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Max wireless sensor per port"
                  name={"maxWirelessSensorPerCable"}
                  key={"maxWirelessSensorPerCable"}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={0} style={{ width: "75px" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item
                  label="Max number of solenoid banks"
                  name={"maxNumberOfSolenoidBanks"}
                  key={"maxNumberOfSolenoidBanks"}

                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={0} style={{ width: "75px" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Max number of solenoids per port"
                  name={"maxNumberOfSolenoidPerBank"}
                  key={"maxNumberOfSolenoidPerBank"}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={0} style={{ width: "75px" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item
                  label="Max number of current sensors"
                  name={"maxNumCurrentSensor"}
                  key={"maxNumCurrentSensor"}

                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={0} style={{ width: "75px" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Max number of pressure sensors"
                  name={"maxNumPressureSensor"}
                  key={"maxNumPressureSensor"}
                  style={{ marginBottom: "14px", marginTop: "8px" }}
                >
                  <Input defaultValue={0} style={{ width: "75px" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
              label="Is Defaultable?"
              name={"defaultable"}
              key={"defaultable"}
              extra={"Only disable if this is a custom PCB for a custom bin"}
              >
                <Switch defaultChecked={true}/>
              </Form.Item>
            </Row>

            <Row
              style={{ paddingTop: "30px" }}
            >
              <Col
                style={{ paddingLeft: "10px" }}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </Layout.Content>
  );
}