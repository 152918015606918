import React, { useEffect, useState, useRef } from 'react';
import { Switch, Input, InputNumber, Form, Layout, Button, notification, Select, Popconfirm, Row, Col, Typography, Radio, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import BinInfoDTO from 'src/models/BinInfoDTO';
import AdminApiService from 'src/api/AdminApiService';
import ECDeviceDTO from 'src/models/ECDeviceDTO';
import IotModel from 'src/consts/IotModel';
import GrowerDTO from 'src/models/GrowerDTO';
import { useGetAllGrowersQuery } from 'src/queries/useGetAllGrowersQuery';
import { ApiError } from 'src/api/ApiResultHandler';
import { useGetBinsQuery } from 'src/queries/useGetBinsQuery';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const { Text } = Typography;
const { Option } = Select;

export const CreateUpdateBinV2 = () => {

    const queryClient = useQueryClient();
    const getAllGrowersQuery = useGetAllGrowersQuery({ enabled:true, refetchOnWindowFocus: false});
    const growerIdList = getAllGrowersQuery.data;
    const [desiredGrower, setDesiredGrower] = useState<GrowerDTO>();
    
    const [desiredBin, setDesiredBin] = useState<BinInfoDTO | null>();
    const { getBinsQuery, getBinsQueryKey} = useGetBinsQuery( desiredGrower?.growerID, { enabled:true, refetchOnWindowFocus: false });
    const binIDList = getBinsQuery.data;
    const [showForm, setShowForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getAllUnusedAzureIoTDevicesQuery = useQuery({
        queryKey: ["getUnusedAzureIoTDevicesQuery"],
        retry: false,
        enabled: true,
        refetchOnWindowFocus: false,
        queryFn: async (q) : Promise<string[]> => {

            return await EnterpriseApiService.getUnusedDevicesForBin(q.signal);
        }
    });
    const unusedAzureIoTDevices = getAllUnusedAzureIoTDevicesQuery.data;

    const getAllECDevicesQuery = useQuery({
        queryKey: ["getECDevicesQuery"],
        retry: false,
        enabled: true,
        refetchOnWindowFocus: false,
        queryFn: async (q) : Promise<ECDeviceDTO[]> => {

            let devices: ECDeviceDTO[] = [];
            let hasMoreResults = true;
            let offset = 0;
            while(hasMoreResults){
                const result = await AdminApiService.getAllECDevices(offset, q.signal);
                if (result.size == 0 || result.size == null) {
                    return devices;
                }
                offset += result.size;
                hasMoreResults = result.limitExceeded;
                if (result.items == null) {
                    return devices;
                }
                for (let device of result.items) {
                    if (device == null) {
                        console.warn("skipping EC device result due to being null");
                        continue;
                    }
                    devices.push(device);
                }
            }

            devices.sort((a, b) => {
                const result = a.displayName?.localeCompare(b.displayName ?? '');
                if (result === undefined) {
                    return 0;
                }
                return result;
            });

            return devices;
        }
    });
    const unusedECDevices = getAllECDevicesQuery.data;

    const [form] = Form.useForm<BinInfoDTO>();
    const watchedValues = Form.useWatch([], { form, preserve: true });
    const [peakHeight, setPeakHeight] = useState(0);

    useEffect(() => {
        const calculatePeakHeight = () => {
          let eaveHeight = watchedValues?.eaveHeightFt ?? 0;
          let diameter = watchedValues?.diameterFt ?? 0;
          let radius = (diameter/2.0);
          let peakCapDiameter = watchedValues?.peakCapDiameterFt ?? 0;
          let roofPitch = watchedValues?.roofPitchDegrees ?? 0;
          
          const angleRadians = (roofPitch * Math.PI) / 180.0;
          const tanAngleRadians = Math.tan(angleRadians);
          const peakCapRadius = peakCapDiameter/2.0;
          const radialDifference = radius - peakCapRadius; 
          const height = eaveHeight + (radialDifference * tanAngleRadians);

          const heightFixed = parseFloat(height.toFixed(1));
          return heightFixed;
        };
    
        setPeakHeight(calculatePeakHeight());
      }, [watchedValues]);

    useEffect(() => {
        if(getAllGrowersQuery.isError){
            let error = getAllGrowersQuery.error as ApiError;
            notification.error({
                message: "Could not fetch grower IDs",
                description: error?.errorDetails?.detail ?? "Contact support"
            });
        }
    }, [getAllGrowersQuery.error]);

    useEffect(() => {
        if(getAllECDevicesQuery.isError){
            let error = getAllECDevicesQuery.error as ApiError;
            notification.error({
                message: "Could not fetch EC devices",
                description: error?.errorDetails?.detail ?? "Contact support"
            });
        }
    }, [getAllECDevicesQuery.error]);

    useEffect(() => {
        if(getAllUnusedAzureIoTDevicesQuery.isError){
            let error = getAllUnusedAzureIoTDevicesQuery.error as ApiError;
            notification.error({
                message: "Could not fetch unused azure devices",
                description: error?.errorDetails?.detail ?? "Contact support"
            });
        }
    }, [getAllUnusedAzureIoTDevicesQuery.error]);

    useEffect(() => {
        if(getBinsQuery.isError){
            let error = getBinsQuery.error as ApiError;
            notification.error({
                message: "Could not fetch bins",
                description: error?.errorDetails?.detail ?? "Contact support"
            });
        }
    }, [getBinsQuery.error]);
    
    const handleGrowerIdClicked = (id: number) => {
        let grower = growerIdList.find( b=> b.growerID === id);
        setDesiredGrower(grower);
    }

    const handleBinIdClicked = (id: number) => {
        let bin = binIDList.find( b=> b.id === id);
        setDesiredBin(bin);   
        setShowForm(true);
    }

    useEffect( () => {
        if(desiredBin == null){
            form.resetFields();
        }else{
            form.setFieldsValue(desiredBin as any);
        }
    }, [desiredBin])

    const deleteDesiredBin = async () => {

        setLoading(true);
        try{
            if(desiredBin != null){
                const result = await EnterpriseApiService.deleteBin(desiredBin.id);
                queryClient.invalidateQueries(getBinsQueryKey);
                notification.success({message:"Bin successfully deleted"});
                form.resetFields();
                setShowForm(false);
            }
            
        }catch(error){
            notification.error({
                message: "Could not delete bin",
                description: error?.errorDetails?.detail ?? "Contact support"
            });
        }finally{
            setLoading(false);
        }
    }

    const layout = {
        labelCol: { xs: 10, sm: 10, md: 8, lg: 8, xl: 6},
        wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 20 },
    };

    const onFinish = async (values: BinInfoDTO) => 
    {
        try{
            setLoading(true);
            values.peakHeightFt = peakHeight;
            if(values.id == 0 || values.id == null){
                const result = await EnterpriseApiService.addBin(values);
                notification.success({message:"New bin added successfully"})
            }else{
                const result = await EnterpriseApiService.updateBin(values);
                notification.success({message:"Bin updated successfully"})
            }
            queryClient.invalidateQueries(getBinsQueryKey);
            queryClient.invalidateQueries(["getUnusedAzureIoTDevicesQuery"]);
            
        }catch(error){
            notification.error({
                message: "Could not submit bin information",
                description: error?.errorDetails?.detail ?? "Contact support"
            });
        }finally{
            setLoading(false);
        }
    }    

    return (
        <Layout.Content style={{ width:"70%" }}>
            <Row justify="space-between">
                <Col>
                    <Row gutter={16}>
                        <Col style={{ width: 104, lineHeight: '32px' }}>
                            <Text>Organization:</Text>
                        </Col>
                        <Col>
                            <Select
                            style={{ width: "200px", maxHeight: "300px", overflow: "auto" }}
                            placeholder="Select Organization"
                            showSearch
                            optionFilterProp="children" 
                            onSelect={ (val) => {
                                handleGrowerIdClicked(val)
                            }}
                            >   
                                <Select.Option value={null}>All Organizations</Select.Option>
                                    {growerIdList.map((grower) => (
                                        <Select.Option key={grower.growerID} value={grower.growerID}>
                                            {grower.growerName}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col style={{ width: 104, lineHeight: '32px' }}>
                    <Text>Bin:</Text>
                </Col>
                <Col span={8}>
                    <Select 
                    onSelect={(val) => handleBinIdClicked(val) } 
                    placeholder="Select bin" 
                    style={{ width:"200px", marginBottom: '16px', marginRight: '8px'}} 
                    optionFilterProp="children" 
                    showSearch
                    >
                        <Select.Option value={0}> Create New Bin </Select.Option>
                        {
                            binIDList.map((bin) => (
                                <Select.Option key={bin.id} value={bin.id}>
                                    {bin.name}
                                    </Select.Option>
                                ))   
                        }
                    </Select>
                </Col>
                <Col>
                    <Row justify="end">
                        {desiredBin &&
                            <Popconfirm
                            title="Are you sure want to delete this bin?"
                            onConfirm={() => deleteDesiredBin()}
                            okText="Yes"
                            cancelText="No"
                            >
                                <Button style={{ marginBottom: '16px' }} type="primary" danger={true}>Delete Bin</Button>
                            </Popconfirm>
                        }
                        </Row>
                    </Col>
            </Row>
            {
                (showForm) &&
                <div style={{marginLeft:"100px"}}>
                    <Spin spinning={loading}>
                        <Form 
                        onFinish={onFinish} 
                        form={form}>
                            <Form.Item name="id"/>
                            <h4>General</h4>
                            <Form.Item {...layout} label="Bin Name" name="name" rules={[{ required: true, message: 'Enter the name of the Bin' }]}>
                                <Input style={{ width: 300 }} />
                            </Form.Item>
                            <Form.Item {...layout} label="Organization" name="growerId" rules={[{ required: true, message: 'Choose the Grower' }]}>
                                <Select style={{ maxWidth: '300px', overflow: "auto", maxHeight: "300px"} } optionFilterProp="children" showSearch >
                                    {
                                        growerIdList.map((grower) => (
                                            <Select.Option key={grower.growerID} value={grower.growerID}>
                                                {grower.growerName}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name={"isActive"} {...layout} label="Is Active" valuePropName="checked">
                                <Switch defaultValue={true}/>
                            </Form.Item>

                            <h4>Physical characteristics</h4>
                            <Form.Item {...layout} label="Diameter Ft." name="diameterFt"
                                rules={[{ required: true, message: 'Enter the diameter' }]}>
                                <InputNumber style={{ width:200 }} min={0} />
                            </Form.Item>
                            
                            <Form.Item {...layout} label="Roof Pitch" name="roofPitchDegrees"
                                rules={[{ required: true, message: 'Enter the roof pitch' }]}
                                initialValue={32}>
                                <InputNumber style={{ width:200 }} />
                            </Form.Item>

                            <Form.Item {...layout} label="Plenum Height Ft." name="plenumHeightFt"
                                rules={[{ required: true, message: 'Enter the plenum height' }]}
                                initialValue={1}
                                >
                                <InputNumber style={{ width:200 }} min={0} />
                            </Form.Item>
                            
                            <Form.Item {...layout} label="Peak Cap Diameter Ft." name="peakCapDiameterFt"
                                rules={[{ required: true, message: 'Enter the peak cap diameter' }]}
                                initialValue={3}>
                                <InputNumber style={{ width:200 }} min={0} />
                            </Form.Item>

                            <Form.Item {...layout} label="Eave Height Ft." name="eaveHeightFt"
                                rules={[{ required: true, message: 'Enter the eave height' }]}>
                                <InputNumber style={{ width:200 }} />
                            </Form.Item>

                            <Form.Item {...layout} label="Peak Height Ft." name="peakHeightFt">
                                <h4>{peakHeight}</h4>
                            </Form.Item>
                            
                            <Form.Item {...layout} label="Total Estimated Bu Capacity" name="estimatedCapacityBu">
                                <InputNumber style={{ width:200 }} />
                            </Form.Item>

                            <h4>Location</h4>
                            <Form.Item  {...layout} label="Latitude of The Bin" name="binLat"
                                rules={[{ required: true, message: 'Enter the latitude of the Bin' }]}>
                                <InputNumber style={{ width:200 }} max={90} min={-90} />
                            </Form.Item>
                            <Form.Item {...layout} label="Longitude of The Bin" name="binLng"
                                rules={[{ required: true, message: 'Enter the Longitude of the Bin' }]}>
                                <InputNumber style={{ width:200 }} max={180} min={-180} />
                            </Form.Item>
                            
                            <h4>Manufacturer of the bin</h4>
                            <Form.Item {...layout} label="Manufacturer of the Bin" name="manufacturer"
                                rules={[{ required: true, message: 'Enter the Bin Manufacturer' }]}>
                                <Input style={{ width:200 }} />
                            </Form.Item>
                            <Form.Item {...layout} label="Fan Model" name="fanModel"
                                rules={[{ required: true, message: 'Enter the Model of Fan Used' }]}>
                                <Input style={{ width:200 }} />
                            </Form.Item>

                            <h4>Ag Automations connectivity</h4>
                            <Form.Item {...layout} label="Azure IoT Device ID" name="deviceId">
                                <Select style={{ maxWidth: '300px', overflow: "auto", maxHeight: "600px"} } optionFilterProp="children" showSearch >
                                    <Select.Option value={null}>
                                        No Device
                                    </Select.Option>
                                    {
                                        unusedAzureIoTDevices?.map((id) => (
                                            <Select.Option key={id} value={id}>
                                                {id}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item {...layout} label="EC Device ID" name='ecDeviceID'>
                                <Select style={{ maxWidth: '300px', overflow: "auto", maxHeight: "600px"} } optionFilterProp="children" showSearch >
                                    <Select.Option value={null}>
                                        No Device
                                    </Select.Option>
                                    {
                                        unusedECDevices?.map((ecDevice) => (
                                            <Select.Option key={ecDevice.id ?? ''} value={ecDevice.id ?? ''}>
                                                {ecDevice.displayName} - {ecDevice.id}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item {...layout} label="Iot Model" name='iotModel'>
                                <Radio.Group>
                                    <Radio value={IotModel.Reliagate}>Reliagate</Radio>
                                    <Radio value={IotModel.Compulab_IOTG_IMX8PLUS}>Compulab</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item {...layout} label="Additional Notes" name="notes" rules={[{ required: false, message: 'Enter a Description of the Bin' }]}>
                                <TextArea placeholder="Add any additonal notes about the bin here" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                            </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            }
        </Layout.Content>
    );
};
