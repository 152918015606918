import { AnnotationOptions, BoxAnnotationOptions } from "chartjs-plugin-annotation";
import { uniqueId } from "lodash";
import { ReadingsDataType } from "./MoistureDisplay"
import {fanOnBackgroundColor} from "../../../pages/dashboard/charts/shared";

export const useMachineAnnotations = (data: Array<ReadingsDataType>): Array<AnnotationOptions> => {

    if (data == null || data?.length === 0) {
        return [];
    }
    const getChanges = () => {
        return data.filter((item, index) => {
          return index === 0 || item.machine !== data[index - 1].machine;
        });
      };
      
      const changes = getChanges();
      const annotations: AnnotationOptions[] = [];

      for (let [index, change] of changes.entries()) {
        if (change.machine?.includes("ON")) {
            annotations.push({
                type: 'box',
                xScaleID: 'x',
                id: uniqueId('statusChange-'),
                xMin: change.dateinUtc!,
                xMax: changes[index+1]?.dateinUtc ?? undefined,
                backgroundColor: fanOnBackgroundColor,
                borderWidth: 0,
                z: -2,
                drawTime: 'beforeDatasetsDraw',   
            })
        }
      }
      return annotations;

}