import { Rule } from "antd/es/form/index";

export const RequiredRule = {
    required: true
} as Rule;

export const BasicValidateMessages = {
    required: '\'${label}\' is required',
    string: {
        range: '\'${label}\' must be between ${min} and ${max} characters',
    },
};