import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "antd";
import dayjs from "dayjs";
import { round } from "lodash-es";
import React, { useState, useCallback, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { queryClient } from "src";
import BinApiService from "src/api/BinApiService";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { RangeValue } from "./ChartContainer";
import { ChartDatePicker } from "./Co2Chart";
import { compressorColor, tooltipItemRound, useFanHeaterChangeHistory } from "./shared";
import { BaseChartProps } from "./TemperatureCableChart";
import { useModeChangeHistory } from "./LayerMCHistoryChart";

enum CompressorStatus {
    ON = "ON",
    OFF = "OFF",
}

export const CompressorChart = (props: BaseChartProps & {height?: number}) => {

    const currentDate = dayjs();
    const priorDate = currentDate.subtract(7, 'days');
  
    const [value, setValue] = useState<RangeValue>([priorDate, currentDate]);
  
    const onDateChange = useCallback((values: RangeValue, formatter: any) => {
      setValue(values);
    }, []);
  
    const chartQuery = useQuery({
      queryKey: [...binDBKeys.bin(props.binId), "charts", "Compressor", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, keepPreviousData: true, retry: 0, queryFn: async (q) => {
        const now = value?.[1];
        const priorMonth = value?.[0];
        const results = await BinApiService.getCompressorDataset(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
        queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
  
        return results;
      }, enabled: (props.binId ?? 0) > 0,
    });
  
    const fanHeaterChangeyAxes = useMemo(() => ['y', 'y2'], []);
    const modeChangeAxes = useMemo(() => ['y'], []);  

    const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1], {yAxes: modeChangeAxes});
    const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1], {yAxes: fanHeaterChangeyAxes});

    if (chartQuery.data == null) {
      return <Skeleton loading></Skeleton>;
    }  
  
    return <div>
  
      <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />
      <section style={{ width: "100%", minHeight: props.height ?? '50vh' }}>
        <Line options={{
          responsive: true,
          animation: false,
          layout: {
            padding: {
                top: 16,
                bottom: 16,
            },
          },
          datasets: {
            line: {
              pointRadius: 0 // disable for all `'line'` datasets
            }
          },
          aspectRatio:3,
          scales: {
            x: {
              type: "time",
              time: {
                minUnit: 'day',
              },
            },
            y: {
            //type: 'category',
            position: 'left',
            offset: true,
            //labels: ['ON', "OFF"],
            ticks: {
                callback(value, index, ticks) {
                    if (value === 0) {
                        return "OFF";
                    }
                    if (value === 1) {
                        return "ON";
                    }
                    return '';
                },
            },
            stack: 'demo',
              stackWeight: 1,
              title: {
                font: {
                  size: 16,
                },
                display: true,
                text: (chartQuery.data?.[1]?.unitLabel)!,
              },
            },
            y2: {
                beginAtZero: true,
                stack: 'demo',
                stackWeight: 2,
                suggestedMax: 100,
                title: {
                  font: {
                    size: 16,
                  },
                  display: true,
                  text: (chartQuery.data?.[0]?.unitLabel)!,
                }
              }
          },
          // parsing: false,
          plugins: {
            annotation: {
              annotations: [
                ...modeHistory.annotations,
                ...fanHeaterChangeHistory.annotations,
              ],
            },
            legend: {
              position: 'top' as const,
            },
            title: {
              display: true,
              text: 'Compressor',
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: function (tooltipItem) {
                  if (tooltipItem.dataset.label != chartQuery.data?.[1].label) {
                    let label = tooltipItemRound(tooltipItem);
                    return label;
                  }
                  let label = tooltipItem.dataset.label ?? "";
  
                  if (label) {
                      label += ': ';
                  }
                  let compressorLabel = "";
                  if (tooltipItem.parsed.y === 1) {
                    compressorLabel = "ON";
                  }
                  else if (tooltipItem.parsed.y === 0) {
                    compressorLabel = "OFF";
                  }
                  else if (tooltipItem.parsed.y == null) {
                    compressorLabel = "";
                  }
                  label += compressorLabel;
                  return label;
                },
            },
            },
          },
        }} data={{ datasets: [

        {
            data: chartQuery.data[0].data, label: chartQuery.data?.[0]?.label!, indexAxis: 'x', yAxisID: 'y2',

            },
            {
              data: chartQuery.data[1].data, label: (chartQuery.data?.[1]?.label)!, indexAxis: 'x' , yAxisID: 'y', stepped: true,
              backgroundColor: compressorColor, borderColor: compressorColor,
              // segment: {
              //     borderColor: (ctx, value) => ((ctx.p0.parsed.y === 1 || ctx.p1.parsed.y === 1) ? "green": "red")
              //   }
      },
        ] }} />
      </section>
    </div>
  
  };