import { Button, Row, Skeleton } from "antd";
import { StatusCodes } from "http-status-codes/build/cjs/status-codes";
import React, { useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { HistoryState } from "./BinStatsPage";
import { queryClient } from "src";
import { connect } from "react-redux";
import { GrowerSlim, UserSessionActions } from "src/redux/actions/UserSessionActions";
import { BinInfoContext } from "src/queries/BinInfoContext";
import { useQuery } from "@tanstack/react-query";
import EnterpriseApiService from "src/api/EnterpriseApiService";
import { ApiError } from "src/api/ApiResultHandler";
import BinInfoDTO from "src/models/BinInfoDTO";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { PremierStatsPage } from "src/pages/features/Premier/PremierStatsPage";
import { strictParseInt } from "./BinStatsPageParent";

interface Props {
    growerChangeAction: (grower: GrowerSlim) => void;
}

const isValidShivversBinId = (shivversId: string): boolean => {
    try {
        const parsed = strictParseInt(shivversId, 10);
        return true;
    } catch (error) {
        return false;
    }
}

const BinStatsShivversPageParent = (props: Props) => {
    const params = useParams<any>();
    const history = useHistory<HistoryState>();
    const shivversBinIdParam = params.shivversBinId;
    console.log("params", params);

    const validShivversBinId = isValidShivversBinId(shivversBinIdParam);


    if (!validShivversBinId) {
        return <>
            <Row justify="center" >
                <h1><b>{validShivversBinId}</b> is not a valid bin identifer</h1>
            </Row>
            <Row justify="center">
                <Button onClick={() => {
                    history.push("/");
                }}>Go Home</Button>
            </Row>
        </>

    }

    const binInfoQuery = useQuery({
        queryKey: binDBKeys.shivversBinInfo(shivversBinIdParam),
        queryFn: async (q) => {
            return await EnterpriseApiService.getBinByShivversId(shivversBinIdParam);
        },
        enabled: validShivversBinId,
        onError: (err: ApiError) => {

        },
        initialData: () => {
            const data = queryClient.getQueryData<BinInfoDTO[] | undefined>(binDBKeys.allBin());
            return data?.find(bin => bin.externalId === shivversBinIdParam);

        },
    })

    useEffect(() => {
        const growerId = binInfoQuery.data?.growerId;
        if (!(growerId)) {
            return;
        }
        const bininfo = binInfoQuery.data;
        const grower: GrowerSlim = {
            externalId: bininfo?.externalId?.toString() ?? null,
            growerId: bininfo?.growerId!,
            growerName: bininfo?.growerName!,
            isExternal: true,
        };
        props.growerChangeAction(grower);
    }, [binInfoQuery.data?.growerId]);

    const unauthorizedBin = binInfoQuery.error?.status === StatusCodes.FORBIDDEN;
    const binNotFound = binInfoQuery.error?.status === StatusCodes.NOT_FOUND;
    const notFoundOrUnauthorized = unauthorizedBin || binNotFound;

    if (notFoundOrUnauthorized) {
        console.log("Redirecting to homepage due to unauthorized or notfound bin");
        return <Redirect to="/" />;
    }


    if (binInfoQuery.data) {
        return <>
        <BinInfoContext.Provider value={binInfoQuery.data}>
            <PremierStatsPage />
        </BinInfoContext.Provider>
        </>;
    }

    if (binInfoQuery.isLoading) {
        return <>
            <Skeleton />
        </>
    }

    if (binInfoQuery.isError) {
        return <>
            <h2>An unexpected server error occurred loading the bin</h2>
            <Link to="/">Back to Dashboard</Link>
            <h2>Contact Support if this perists.</h2>
        </>;
    }

    return <></>

}




function mapDispatchToProps(dispatch: any) {
    return {
        growerChangeAction: (grower: GrowerSlim) => dispatch(UserSessionActions.changeGrower(grower)),
    };
}

export default connect(null, mapDispatchToProps)(BinStatsShivversPageParent);
