import { userInfo } from 'os';
import PermissionNames from 'src/consts/PermissionNames';
import UserSecurityDTO from './UserSecurityDTO';

export default class UserSecurity {
    public readonly firstName: string | null;
    public readonly lastName: string | null;
    public readonly userId: number;
    public readonly userName: string | null;
    private readonly roles: ReadonlyArray<UserSecurityDTO['roles'][0]>;
    public readonly externalId: string | null;
    public readonly isExternal: boolean | null;
    public readonly permissions: ReadonlyArray<UserSecurityDTO['permissions'][0]>;

    constructor(userinfo: UserSecurityDTO) {
        this.firstName = userinfo.firstName;
        this.lastName = userinfo.lastName;
        this.userId = userinfo.userId;
        this.userName = userinfo.userName;
        this.roles = Object.freeze(userinfo.roles);
        this.externalId = userinfo.externalId;
        this.isExternal = userinfo.isExternal;
        this.permissions = userinfo.permissions;
    }

    isInRole(role: UserSecurity['roles'][0]) {
        return this.roles.indexOf(role) >= 0;
    }

    canViewControls() {
        return this.permissions.includes(PermissionNames.CONTROLSREAD) || this.permissions.includes(PermissionNames.CONTROLSWRITE);
    }
    
    canModifyControls() {
        return this.permissions.includes(PermissionNames.CONTROLSWRITE);
    }

    getRoles() {
        return this.roles.slice(0);
    }
}
