// Constants TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
enum FanOffReason {
    Requested = 'Requested',
    KeepFansOffOverride = 'KeepFansOffOverride',
    PlenumTemperatureAboveLimit = 'PlenumTemperatureAboveLimit',
    WeatherConditions = 'WeatherConditions',
    FanTimer = 'FanTimer',
    PlenumTemperatureCoolDownDelay = 'PlenumTemperatureCoolDownDelay',
    GrainFillHeightBelowLimit = 'GrainFillHeightBelowLimit',
    Unknown = 'Unknown',
}
export default FanOffReason;
