import React from 'react';
import { Layout, Tabs } from 'antd';
import GrowerAdministration from '../admin/GrowerAdminstration';
import { CreateUpdateBinV2 } from '../admin/CreateUpdateBinV2';
import History from 'src/utils/HistoryUtil';
import { adminHistoryState } from '../admin/AdminTabs';
import { useLocation } from 'react-router-dom';

interface SettingsTabsProps {
    tab?: number;
}

const SettingsTabs = (props: SettingsTabsProps) => {
    const location = useLocation<adminHistoryState>();

    return (
        <Layout.Content className="admin" key={props.tab || 'default'}>
        <Tabs defaultActiveKey={props.tab ? props.tab.toString() : '1'}
            onChange={(key) => {
                History.push({
                    pathname: `/settings/${key}`,
                    state: { passedGrower: location?.state?.passedGrower }
                });
            }}
        >
            <Tabs.TabPane tab="Growers" key="1">
                <GrowerAdministration grower={location?.state?.passedGrower} isSettings={true}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bins" key="2">
                <CreateUpdateBinV2/>
            </Tabs.TabPane>
        </Tabs>
    </Layout.Content>
    );
};

export default SettingsTabs;
