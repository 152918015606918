
export type LogType = 'BinState' | 'GrainState' | 'Run';

export const viewBinStateLogsByRange = async (clientFolder: string, startDate: string, endDate: string) => {
    const url = `${window.location.protocol}${window.location.host}/api/grainbin/GetBinStateLogsByRange`;
    console.debug(`device log url = ${url}`);
    const binStateRangeUrl = new URL(url);
    binStateRangeUrl.searchParams.set('clientDirectory', clientFolder);
    binStateRangeUrl.searchParams.set('startRange', startDate);
    binStateRangeUrl.searchParams.set('endRange', endDate);
    window.open(binStateRangeUrl.toString(), '_blank');
};

export const viewGrainStateLogsByRange = async (clientFolder: string, startDate: string, endDate: string) => {
    const url = `${window.location.protocol}${window.location.host}/api/grainbin/GetGrainStateLogsByRange`;
    console.debug(`device grainState url = ${url}`);
    var rangeURL = new URL(url);
    rangeURL.searchParams.set('clientDirectory', clientFolder);
    rangeURL.searchParams.set('startRange', startDate);
    rangeURL.searchParams.set('endRange', endDate);
    window.open(rangeURL.toString(), '_blank');
};

export const viewRunLogsByRange = async (clientFolder: string, startDate: string, endDate: string) => {
    const url = `${window.location.protocol}${window.location.host}/api/grainbin/GetRunLogsByRange`;
    console.debug(`device RunLog url = ${url}`);
    var rangeURL = new URL(url);
    rangeURL.searchParams.set('clientDirectory', clientFolder);
    rangeURL.searchParams.set('startRange', startDate);
    rangeURL.searchParams.set('endRange', endDate);
    window.open(rangeURL.toString(), '_blank');
};