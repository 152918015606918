import * as React from 'react';
// import BinApiService from 'src/api/BinApiService';

import { Layout } from 'antd';
import BinInfoDTO from 'src/models/BinInfoDTO';
// import { BinVisualThree } from './BinVisualThree';
// import BinVisual from './BinVisual';

interface State {
    // bin?: BinDTO;
    selectedBinId?: number | null;
    loading: boolean;
    bins: BinInfoDTO[];
}

export default class BinTestPage extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            loading: true,
            bins: []
        };
    }

    componentDidMount() {
        this.fetchData();

    }

    fetchData = () => {
        this.setState({ loading: true });

        /*BinApiService.getBinsSummary()
            .then((bins) => {
                console.log(bins);
                this.setState({
                    bins,
                    selectedBinId: bins[0]!.id
                });

            })
            .catch((error) => {
                this.setState({ loading: false });
                notification.error({
                    message: error.message,
                    description: error.description,
                });
            });*/
        // BinApiService.getBinsSummary()
        //     .then((bins) => {
        //         console.log(bins);
        //         this.setState({
        //             bins,
        //             selectedBinId: bins[0]!.id
        //         });

        //     })
        //     .catch((error) => {
        //         this.setState({ loading: false });
        //         notification.error({
        //             message: error.message,
        //             description: error.description,
        //         });
        //     });
    }

    render() {
        // let { bins, selectedBinId} = this.state;
        return(
            <Layout.Content className="dashboard">
            {/* {selectedBinId ? <BinVisualThree bins={bins} /> : <></>} */}
            {/* {selectedBinId ? <BinVisual bins={bins} /> : <></>} */}
            </Layout.Content>
        );
    }
}
