import React from "react";
import BinInfoDTO from "src/models/BinInfoDTO";

export const BinInfoContext = React.createContext<BinInfoDTO | null>(null);

export const useBinInfoContext = () => {
    const binInfo = React.useContext(BinInfoContext); 
    if (!binInfo) {
        throw new Error('BinInfoContext: No value provided')
      }
    return binInfo;
  }