import { UserSessionStoreState } from '../state/StateStoreModel';
import { UserSessionActionTypes, UserSessionAction, GrowerSlim } from '../actions/UserSessionActions';

export const InitialUserSessionStoreState: UserSessionStoreState = {
    Loading: false,
    Checking: true,
    Value: null,
    Error: null,
    GrowerID: null,
    GrowerName: null,
    grower: null,
};

export function UserSession(state: UserSessionStoreState = InitialUserSessionStoreState, action: UserSessionAction): UserSessionStoreState {
    let newState: UserSessionStoreState = state;
    switch (action.type) {
        case UserSessionActionTypes.USER_SESSION_CHANGED:
            let us = action.data ? Object.freeze(action.data) : action.data;

            // likely a logout since null is passed after the logout endpoint returns success
            // todo: this should really be its own action type...
            const isloggingOut = us == null;

            if (isloggingOut) {
                newState = { ...state, Value: us, Error: null, grower: null, GrowerID: null, GrowerName: null };
            }
            else {
                // just changing grower / reloading the homepage
                newState = { ...state, Value: us, Error: null };
            }
            break;
        case UserSessionActionTypes.USER_SESSION_LOADING:
            let error = state.Error;
            if (action.data) {
                error = null;
            }
            newState = { ...state, Loading: action.data, Error: error };
            break;
        case UserSessionActionTypes.USER_SESSION_CHECKING:
            newState = { ...state, Checking: action.data };
            break;
        case UserSessionActionTypes.USER_SESSION_LOGIN_ERROR:
            newState = { ...state, Error: action.data };
            break;
        case UserSessionActionTypes.USER_GROWER_CHANGED:
            console.log("user session changed", action.data);
            const data = action.data as GrowerSlim;
            newState = { ...state, GrowerID: action.data.growerId, GrowerName: action.data.growerName, grower: {...data} };
            break;
        default:
            return newState;
    }
    return Object.freeze(newState);
}
